import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';

import Buttons from '@components/Buttons';
import { JobStatus } from '@providers/Jobs/Context';
import { SIZES } from '@styles/media';
import { useIsMobile } from '@hooks';
import { m } from 'framer-motion';
import { mixpanelTrack } from '@providers/Mixpanel';
import Activity from '@components/Icons/Activity';
import ArrowsClockwise from '@components/Icons/ArrowsClockwise';
import CheckCircle from '@components/Icons/CheckCircle';
import WarningCircle from '@components/Icons/WarningCircle';
import CaretDown from '@components/Icons/CaretDown';
import CaretUp from '@components/Icons/CaretUp';
import useJobContext from '../useJobContext';
import isDependency from '../isDependency';
import AsyncJob from './AsyncJob';
import BeforeUnload from './BeforeUnload';
import s from './JobsContainer.module.scss';

export default function JobContainer(): JSX.Element | null {
  const { jobs } = useJobContext();

  const [isExpanded, setIsExpanded] = useState(false);
  const [onlineStatus, setOnlineStatus] = useState(true);
  const showActivityDot = !jobs.length;

  const isMobile = useIsMobile(SIZES.lg);
  const failedJobs = jobs.filter(j => j.status === JobStatus.FAILED);
  const pendingJobs = jobs.filter(j => j.status === JobStatus.IN_PROGRESS);
  const completedJobs = jobs.filter(j => j.status === JobStatus.COMPLETED);
  const jobsToShow = jobs.filter(job => !isDependency(job));

  useEffect(() => {
    const handleStatusChange = (): void => {
      setOnlineStatus(navigator.onLine);
    };

    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [onlineStatus]);

  useEffect(() => {
    if (isExpanded) {
      mixpanelTrack('Async Job Activity Log Opened');
    }
  }, [isExpanded]);

  const handleToggleExpanded = (): void => setIsExpanded(!isExpanded);

  const getJobText = (): string => {
    if (failedJobs.length > 0) {
      return `${failedJobs.length} failed job${failedJobs.length === 1 ? '' : 's'}`;
    }
    if (pendingJobs.length > 0) {
      return `${pendingJobs.length} item${pendingJobs.length === 1 ? ' is being published' : 's are being published'}`;
    }
    return `${completedJobs.length} published item${completedJobs.length === 1 ? '' : 's'}`;
  };

  const getIconBefore = (): ReactElement => {
    if (showActivityDot) {
      return <Activity color="var(--color-gray-100)" />;
    }
    if (failedJobs.length > 0) {
      return <WarningCircle weight='fill' color="var(--color-error)" />;
    }
    if (pendingJobs.length > 0) {
      return <ArrowsClockwise weight='fill' color="var(--color-gray-100)" />;
    }
    return <CheckCircle weight='fill' color="var(--color-success)" />;
  };

  if (!jobs.length && onlineStatus) return null;

  return (
    <m.div initial={{ y: -40 }} animate={{ y: 0 }} className={classNames(s.wrapper, { [s['wrapper--expanded']]: isExpanded, [s['wrapper--relative']]: showActivityDot })}>
      {pendingJobs.length > 0 && <BeforeUnload />}
      <Buttons
        // isThird={!isMobile}
        isFlat={isMobile}
        isSmall
        isFullWidth
        className={classNames(s.jobsButton, {
          [s.expandButton]: isMobile,
          [s.activityLogButton]: !jobsToShow.length,
        })}
        iconAfter={jobsToShow.length ? <>{isExpanded ? <CaretUp /> : <CaretDown />}</> : undefined}
        iconBefore={
          <m.div
            style={{ marginBlockStart: 'auto' }}
            animate={pendingJobs.length > 0 ? { rotate: 360 } : {}}
            transition={pendingJobs.length > 0 ? { duration: 1, ease: 'linear', repeat: Infinity, repeatType: 'loop' } : {}}
          >
            {getIconBefore()}
          </m.div>
        }
        onClick={handleToggleExpanded}
      >
        {showActivityDot && (
          <div
            className={s.activityDot}
            style={{
              backgroundColor: onlineStatus ? 'var(--color-success)' : 'var(--color-error)',
            }}
          />
        )}
        <span className={classNames('body-sm', s.jobsButtonText)}>
          {!!jobs.length && getJobText()}
          {!jobs.length && (
            <span
              className={classNames(s['connection-status'], {
                [s['connection-status--unstable']]: !onlineStatus,
              })}
            >
              {onlineStatus ? 'Connection stable' : 'Connection unstable'}
            </span>
          )}
        </span>
      </Buttons>
      {isExpanded && (
        <div className={s.jobContent}>
          <div className={s.jobList}>
            {!!jobsToShow.length && <p className={classNames('caption', s.jobsSectionText)}>Ongoing uploads</p>}
            {jobsToShow.map(job => (
              <AsyncJob key={job.id} job={job} />
            ))}
            {/* <p className={classNames('caption', s['jobsSectionText'])}>Recent events</p> */}
          </div>
        </div>
      )}
    </m.div>
  );
}
