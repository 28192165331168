import { useContext, useState, useRef, useCallback, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { useParams } from 'next/navigation';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import * as ApiModels from '@typings/api-models';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useNoodleApi from '@hooks/useNoodleApi';
import TeamsContext from '@providers/Teams/TeamsContext';
import * as tsClient from '@tsClient';
import { JobStatus, JobType, useJobContext } from '@providers/Jobs';
import Plus from '@components/Icons/Plus';

import { useFeatureFlag } from '@/providers/FeatureFlags';
import type { HookResponse, ResourceType } from './common';
import { PAGE_SIZE } from './common';

type Broadcast =
  & Pick<ApiModels.Message, | 'id' | 'text' | 'title'>
  & Partial<Pick<ApiModels.Message, 'viewCount'>>;

type Item = HookResponse['items'][number];

const broadcastToItem = ({
  broadcast,
}: {
  broadcast: Broadcast,
}): Item => ({
  bottomLine: `${broadcast.viewCount ?? 0} views`,
  href: getUrl(IDENTIFIERS.DASHBOARD_BROADCAST_DETAILS, { broadcastId:  broadcast.id }),
  id: broadcast.id,
  label: broadcast.title || 'Broadcast',
});

const getDetailPagePath: Parameters<typeof ListAndDetailsSubLayout>[0]['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_BROADCAST_DETAILS, { broadcastId: id });

const useBroadcasts = ({
  resourceType,
}: {
  resourceType: ResourceType;
}): HookResponse => {
  const { jobs } = useJobContext();
  const numPendingBroadcastJobs = jobs.filter(j => j.type === JobType.MESSAGE_PUBLISH && j.status !== JobStatus.COMPLETED).length;
  const pathParams = useParams<{ broadcastId?: string }>();
  const { currentTeamOwner } = useContext(TeamsContext);
  const mostRecentRequestId = useRef<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const [items, setItems] = useState<Parameters<typeof ListAndDetailsSubLayout>[0]['items']>([]);

  const { getData: getBroadcasts } = useNoodleApi(tsClient.getBroadcasts);
  const { getData : getNoodleBroadcasts } = useNoodleApi(tsClient.getNoodleBroadcasts);

  const isNoodleCreator = useFeatureFlag('is-noodle-creator');

  const creatorId = currentTeamOwner?.id;
  const selectedId = pathParams?.broadcastId;

  const loadPage = useCallback(async ({
    page,
  }: {
    page: number;
  }): Promise<void> => {
    setCurrentPage(page);

    if (creatorId && resourceType === 'broadcasts') {
      setIsFetching(true);
      const thisRequestId = nanoid();
      mostRecentRequestId.current = thisRequestId;
      let getResponse;

      if(isNoodleCreator) {
        getResponse = await getNoodleBroadcasts({
          page,
          perPage: PAGE_SIZE,
        });
      } else {
        getResponse = await getBroadcasts({
          creatorId,
          page,
          perPage: PAGE_SIZE,
        });
      }

      if (thisRequestId === mostRecentRequestId.current) {
        if (getResponse.data) {
          setItems(getResponse.data.items.map(broadcast => broadcastToItem({ broadcast })));
          setNumPages(getResponse.data.numPages);
        }
        setIsFetching(false);
      }
    } else {
      setItems([]);
    }
  }, [creatorId, resourceType, isNoodleCreator, getBroadcasts]);

  const reloadListView = async (): Promise<void> => {
    await Promise.all([
      loadPage({ page: currentPage }),
    ]);
  };

  const handlePageChange = (newPage: number): void => {
    loadPage({
      page: newPage,
    });
  };

  useEffect(() => {
    if (creatorId) {
      loadPage({
        page: currentPage,
      });
    }
  // only rerun this useEffect when completedCommentJobsCount changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numPendingBroadcastJobs]);

  useEffect(() => {
    if (creatorId) {
      loadPage({
        page: 1,
      });
    }
  }, [loadPage, creatorId]);

  const pagination = {
    numPages,
    onChangePage: handlePageChange,
    page: currentPage,
  };

  const actions: NonNullable<HookResponse['actions']> = [
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_BROADCAST_CREATE),
      icon: Plus,
      label: 'Create broadcast',
    },
  ];

  return {
    actions,
    getDetailPagePath,
    ifNoItemsText: 'No Broadcasts',
    isFetchingList: isFetching,
    items,
    pagination,
    reloadListView,
    selectedId,
  };
};

export default useBroadcasts;
