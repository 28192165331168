import { uploadFileAsset } from '@tsClient';

const uploadLogo = async (asset?: string | File | null): Promise<string | null> => {
  if (asset) {
    const formData = new FormData();
    if (typeof asset === 'string') {
      const r = await fetch(asset);
      const file = await r.blob();
      formData.append('fileUpload', file);
    }
    if (asset instanceof File) {
      formData.append('fileUpload', asset, asset.name);
      formData.set('Content-Type', asset.type);
    }
    const { id } = await uploadFileAsset(formData);
    return id;
  }
  return null;
};

export default uploadLogo;
