import * as ApiModels from '@typings/api-models';
import MimeTypes from '@typings/MimeTypes';
import RadioInput from '@components/FormFields/RadioInputCards';
import { pickProductIcon } from '@components/ProductIcon';
import { AllowedNoodleProductTypes, isCreatable } from './constants';

type ServiceCard = Omit<Parameters<typeof RadioInput>[0]['inputs'][number], 'id'> & { id: AllowedNoodleProductTypes };

type ThisCreatorTier = Pick<ApiModels.CreatorTier,
  | 'applicationFeeHandbook'
  | 'applicationFeeInPersonSession'
  | 'applicationFeeLite'
  | 'applicationFeeOnlineSession'
  | 'applicationFeeSubscription'
  | 'canCreateHandbook'
  | 'canCreateInPersonSession'
  | 'canCreateLite'
  | 'canCreateOnlineSession'
  | 'canCreateSubscription'
  | 'applicationFeeConsultations'
  | 'canCreateConsultation'
>;

const pickDefaultService = (creatorTier?: ThisCreatorTier): AllowedNoodleProductTypes | null => {
  if (!creatorTier) {
    return null;
  }

  if (creatorTier.canCreateLite) {
    return ApiModels.NoodleProductTypes.Lite;
  }

  if (creatorTier.canCreateHandbook) {
    return ApiModels.NoodleProductTypes.Handbook;
  }

  if (creatorTier.canCreateOnlineSession) {
    return ApiModels.NoodleProductTypes.OnlineSession;
  }

  if (creatorTier.canCreateInPersonSession) {
    return ApiModels.NoodleProductTypes.InPersonSession;
  }

  return null;
};

const serviceCardProps = (
  applicationFee: number | undefined,
  canCreate: boolean | undefined,
  thisService: AllowedNoodleProductTypes,
  defaultService: AllowedNoodleProductTypes | null,
): {
  description: string;
  isDisabled: boolean;
  defaultChecked: boolean;
  id: AllowedNoodleProductTypes;
  icon: ReturnType<typeof pickProductIcon>;
} => {
  if (!isCreatable(thisService)) {
    return {
      defaultChecked: false,
      description: 'Coming soon',
      icon: pickProductIcon(thisService),
      id: thisService,
      isDisabled: true,
    };
  }

  const isDisabled = applicationFee === undefined || canCreate === undefined || canCreate === false;

  const description = isDisabled
    ? 'Upgrade'
    : `${applicationFee}% fee`;

  const isDefaultService = defaultService === thisService;
  const defaultChecked = !isDisabled && isDefaultService;

  return {
    defaultChecked,
    description,
    icon: pickProductIcon(thisService, thisService === ApiModels.NoodleProductTypes.Handbook ? MimeTypes.AVI : undefined),
    id: thisService,
    isDisabled,
  };
};

const createServiceCardData = (creatorTier?: ThisCreatorTier): Array<ServiceCard> => {
  const defaultService = pickDefaultService(creatorTier);
  return [
    {
      ...serviceCardProps(
        creatorTier?.applicationFeeInPersonSession,
        creatorTier?.canCreateInPersonSession,
        ApiModels.NoodleProductTypes.InPersonSession,
        defaultService,
      ),
      extraDescription: 'Great for appointments, lessons, training sessions, consultations, services, and anything else that takes place in person. Connect your calendar and bookings will get automatically added.',
      label: 'In Person',
    },
    {
      ...serviceCardProps(
        creatorTier?.applicationFeeOnlineSession,
        creatorTier?.canCreateOnlineSession,
        ApiModels.NoodleProductTypes.OnlineSession,
        defaultService,
      ),
      extraDescription: 'Great for services you provide remotely and deliverables are sent digitally. Optionally connect your calendar for calls and bookings will get automatically added. Or, simply sell a remote service with no bookings included.',
      label: 'Remotely',
    },
    {
      ...serviceCardProps(
        creatorTier?.applicationFeeLite,
        creatorTier?.canCreateLite,
        ApiModels.NoodleProductTypes.Lite,
        defaultService,
      ),
      extraDescription: 'Great for video analysis, personalized responses, or services you provide asynchronously via chat messaging.',
      label: 'Private Chat',
    },
    {
      ...serviceCardProps(
        creatorTier?.applicationFeeHandbook,
        creatorTier?.canCreateHandbook,
        ApiModels.NoodleProductTypes.Handbook,
        defaultService,
      ),
      extraDescription: 'Sell your digital products on Noodle, with the help of your AI sidekick! Import your PDF, or create a Noodle Byte to sell.',
      label: 'Byte',
    },
    {
      ...serviceCardProps(
        creatorTier?.applicationFeeHandbook,
        creatorTier?.canCreateHandbook,
        ApiModels.NoodleProductTypes.Collection,
        defaultService,
      ),
      extraDescription: 'A collection of Bytes that you can group or categorize as you see fit. Great for more in depth programs and guides on broader topics.',
      label: 'Collection',
    },
    {
      ...serviceCardProps(
        creatorTier?.applicationFeeSubscription,
        creatorTier?.canCreateSubscription,
        ApiModels.NoodleProductTypes.Subscription,
        defaultService,
      ),
      extraDescription: 'Start a community membership and give subscribers access to your Broadcast messages, community posts, and Byte Collections.',
      label: 'Membership',
    },
    {
      ...serviceCardProps(
        creatorTier?.applicationFeeConsultations,
        creatorTier?.canCreateConsultation,
        ApiModels.NoodleProductTypes.Consultation,
        defaultService,
      ),
      extraDescription: 'Sell scheduled consultations with your clients. These can be in person, over video call, or phone call.',
      label: 'Consultation',
    },
  ];
};

export default createServiceCardData;
