import { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import { useParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';
import { formatDistanceToNow } from 'date-fns';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import X from '@components/Icons/X';
import { CommonUserInboxItem, UserInboxUpdatedMessageData } from '@typings/api-models';
import TeamsContext from '@/providers/Teams/TeamsContext';
import useSocketContext from '@/providers/Socket/useSocketContext';
import { SocketMessageType } from '@/providers/Socket/SocketContext';
import { HookResponse, PAGE_SIZE } from './common';
import DashboardContext from '../../DashboardContext';

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_INBOX_ITEM, { id });
type Item = HookResponse['items'][number];

const inboxItemToListItem = (
  { item, onDismiss, onRead }: {
    item: CommonUserInboxItem;
    onDismiss: (inboxItemId: string) => void;
    onRead: (inboxItemId: string) => void;
  },
): Item => {
  let bottomLine;
  let topLine;
  let label;
  let inboxType: 'noodle-broadcast' |'chat' | 'comment' | 'task' | undefined;
  let href;
  switch (item.type) {
  case 'chat':
    inboxType = 'chat';
    topLine = "Private Message";
    label = 'Respond to message';
    bottomLine = `New message from ${item.sender?.name ?? ''}`;
    href = getUrl(IDENTIFIERS.DASHBOARD_INBOX_ITEM, { id: item.id });
    break;
  case 'workflow-comment':
    inboxType = 'comment';
    label = 'Respond to comment';
    bottomLine = `New comment from ${item.sender?.name ?? ''}`;
    topLine = item.task?.userWorkflow?.workflow.name ?? item.meta?.workflowName ?? null;
    href = getUrl(IDENTIFIERS.DASHBOARD_INBOX_ITEM, { commentId: item.task?.referenceId, id: item.id });
    break;
  case 'task':
    inboxType = 'task';
    label = item.task?.title ?? 'A new task';
    bottomLine = `For ${item.task?.userWorkflow?.person.name ?? ''}`;
    topLine = item.task?.userWorkflow?.workflow.name ?? null;
    href = getUrl(IDENTIFIERS.DASHBOARD_INBOX_ITEM, { id: item.id });
    break;
  case 'noodle-broadcast':
    inboxType = 'noodle-broadcast';
    label = item.meta?.title ?? null;
    bottomLine = item.meta?.message ?? null;
    topLine = 'Product Announcement';
    href = getUrl(IDENTIFIERS.DASHBOARD_INBOX_ITEM, { id: item.id });
    break;
  default:
    label = 'A new item';
    break;
  }

  return {
    avatar: undefined,
    bottomLine,
    href,
    id: item.id,
    inboxType,
    isAi: false,
    isMuted: item.isRead,
    label,
    onClick: () => onRead(item.id),
    secondaryActions: {
      ariaLabel: 'Actions',
      compact: true,
      hideTooltip: true,
      onSelect: () => onDismiss(item.id),
      options: [{
        destructive: true,
        icon: X,
        value: 'Dismiss',
      }]},
    time: formatDistanceToNow(new Date(item.createdAt), { addSuffix: true }),
    topLine,
  };
};

const useInboxRequest = ({
  search,
  types,
}: {
  search: string | null;
  types?: string[];
}): HookResponse => {
  const router = useRouter();

  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const pathParams = useParams<{ id?: string }>();
  const [items, setItems] = useState<HookResponse['items']>([]);
  const mostRecentRequestRef = useRef<string | null>(null);
  const { creatorId } = useContext(TeamsContext);
  const { refetchUnreadInboxItemCount } = useContext(DashboardContext);

  const { getData: getCreatorInboxItemsFn } = useNoodleApi(tsClient.creators.getCreatorInboxItems);
  const { getData: dismissInboxItemFn } = useNoodleApi(tsClient.inbox.dismissInboxItem);
  const { getData: changeReadStatusFn } = useNoodleApi(tsClient.inbox.changeReadStatus);

  const { addListener, removeListener } = useSocketContext();

  const selectedId = pathParams?.id;

  const loadPage = useCallback(async ({
    page, search: newSearch, types: newTypes,
  }: { search:string | null, page: number, types?: string[] }): Promise<void> => {
    setCurrentPage(page);
    const thisRequestId = nanoid();
    mostRecentRequestRef.current = thisRequestId;

    setIsFetching(true);
    const response = await getCreatorInboxItemsFn({
      creatorId: creatorId || '',
      page,
      perPage: PAGE_SIZE,
      search: newSearch ?? undefined,
      types: newTypes && newTypes.length > 0 ? newTypes.join(',') : undefined,
    });

    if (mostRecentRequestRef.current === thisRequestId) {
      if (response.data) {
        setItems(response.data.items.map((item: CommonUserInboxItem) => inboxItemToListItem({
          item,
          // eslint-disable-next-line no-use-before-define
          onDismiss: handleDismissItem,
          // eslint-disable-next-line no-use-before-define
          onRead: handleReadItem,
        })));
        setNumPages(response.data.numPages);
      }
      setIsFetching(false);
    }
  }, [
    getCreatorInboxItemsFn,
    dismissInboxItemFn,
  ]);

  const handleDismissItem = async (inboxItemId: string): Promise<void> => {
    await dismissInboxItemFn({ inboxItemId });
  };

  const handleReadItem = async (inboxItemId: string): Promise<void> => {
    const inboxItem = items.find(({ id }) => id === inboxItemId);

    if (inboxItem && !inboxItem.isMuted) {
      // Do optimistic update for read status
      setItems((prev) => prev.map((item) => item.id === inboxItemId
        ? ({
          ...item,
          isMuted: true,
        })
        : item));
      await changeReadStatusFn({ inboxItemId, isRead: true });

      refetchUnreadInboxItemCount();
    }
  };

  useEffect(() => {
    loadPage({ page: 1, search, types });
  }, [loadPage, search, types]);

  const pagination: HookResponse['pagination'] = {
    numPages,
    onChangePage: (newPage) => {
      loadPage({ page: newPage, search, types });
    },
    page: currentPage,
  };

  useEffect(() => {
    const handleInboxStatusChange = async (data: unknown): Promise<void> => {
      const { inboxItemId } = data as UserInboxUpdatedMessageData;
      const inboxItemIndexInList = items.findIndex(({ id }) => id === inboxItemId);

      loadPage({
        page: currentPage,
        search,
        types,
      });

      if (inboxItemIndexInList > -1) {
        if (selectedId === inboxItemId) {
          if (inboxItemIndexInList === items.length - 1) {
            router.push(getUrl(IDENTIFIERS.DASHBOARD_INBOX_ITEM, { id: items[inboxItemIndexInList - 1].id }));
          } else {
            router.push(getUrl(IDENTIFIERS.DASHBOARD_INBOX_ITEM, { id: items[inboxItemIndexInList + 1].id }));
          }
        }
      }
    };

    const listenerId = addListener({
      fn: handleInboxStatusChange,
      messageType: SocketMessageType.USER_INBOX_UPDATED,
    });

    return () => {
      removeListener(listenerId);
    };
  }, [addListener, removeListener, items, loadPage, currentPage, search, types, router, selectedId]);

  return useMemo(() => ({
    getDetailPagePath,
    headerActions: [],
    ifNoItemsText: 'No inbox items yet',
    isFetchingList: isFetching,
    items,
    pagination,
    reloadList: () => loadPage({ page: 1, search, types }),
    selectedId,
  }), [
    getDetailPagePath,
    isFetching,
    items,
    pagination,
    selectedId,
    search,
    types,
  ]);
};

export default useInboxRequest;
