import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import * as format from '@format';
import { HookResponse } from './common';
import DashboardContext from '../../DashboardContext';

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_BOOKINGS_ITEM, { id });

const useBookings = ({
  arePast,
  creatorId,
  memberId,
}: {
  arePast: boolean;
  creatorId: string | undefined;
  memberId: string | undefined;
}): HookResponse => {
  const { isInitiateBookingModalOpen } = useContext(DashboardContext);

  const [isFetching, setIsFetching] = useState(false);
  const pathParams = useParams<{ id?: string }>();
  const [items, setItems] = useState<HookResponse['items']>([]);
  const mostRecentRequestRef = useRef<string | null>(null);

  const { getData: getBookingsForCreatorFn } = useNoodleApi(tsClient.getBookingsForCreator, { toastOnError: true });
  const { getData: getCreatorTeamFn } = useNoodleApi(tsClient.creators.getCreatorTeam);

  const selectedId = pathParams?.id;

  const loadPage = useCallback(async (): Promise<void> => {
    if (creatorId) {
      const thisRequestId = nanoid();
      mostRecentRequestRef.current = thisRequestId;

      setIsFetching(true);
      const response = await getBookingsForCreatorFn({
        creatorId,
        endDate: arePast ? new Date().toISOString() : new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString(),
        startDate: arePast
          ? new Date(0).toISOString()
          : new Date().toISOString(),
        teamMemberPersonId: memberId === 'all' ? undefined : memberId,
      });

      const teamData = await getCreatorTeamFn({ creatorId, page: 1, perPage: 1000 });

      if (mostRecentRequestRef.current === thisRequestId) {
        if (response.data && teamData.data) {
          const bookings = arePast ? response.data.fullBookings.reverse() : response.data.fullBookings;
          const teamMembers = teamData.data.items;
          setItems(bookings.map(booking => ({
            bottomLine: booking.booking.teamMemberPersonId ? `with ${teamMembers.find(member => member.id === booking.booking.teamMemberPersonId)?.name}` : 'No one assigned',
            href: getUrl(IDENTIFIERS.DASHBOARD_BOOKINGS_ITEM, { id: booking.booking.id }),
            id: booking.booking.id,
            label: booking.booking.person.name || 'No name',
            topLine: booking.booking.startAt ? `${format.datetime.upcoming(booking.booking.startAt)}` : '',
          })));
        }
        setIsFetching(false);
      }
    } else {
      setItems([]);
    }
  }, [
    arePast,
    creatorId,
    getBookingsForCreatorFn,
    getCreatorTeamFn,
    memberId,
  ]);

  useEffect(() => {
    if (!isInitiateBookingModalOpen) {
      loadPage();
    }
  }, [isInitiateBookingModalOpen, loadPage]);

  return useMemo(() => ({
    getDetailPagePath,
    ifNoItemsText: 'No bookings yet',
    isFetchingList: isFetching,
    items,
    reloadList: () => loadPage(),
    selectedId,
  }), [
    isFetching,
    items,
    selectedId,
    loadPage,
  ]);
};

export default useBookings;
