import { useContext, useEffect, useMemo, useState } from 'react';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import { CalendarPlus } from '@phosphor-icons/react';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import TeamsContext from '@/providers/Teams/TeamsContext';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import useNoodleApi from '@/hooks/useNoodleApi';
import * as tsClient from '@tsClient';

import useBookings from './useBookings';

const Bookings: React.FC = ({ children }) => {
  const [arePast, setArePast] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('all');

  const { currentTeamOwner } = useContext(TeamsContext);
  const { getData: getTeamMembersFn, data: teamMembers } = useNoodleApi(tsClient.creators.getCreatorTeam);

  useEffect(() => {
    if (currentTeamOwner) {
      getTeamMembersFn({ creatorId: currentTeamOwner.id });
    }
  }, [currentTeamOwner, getTeamMembersFn]);

  const { setInitiateBookingModalOpen, setBrowserTabTitle } = useContext(DashboardContext);

  const isOneColumn = useIsMobile(SIZES.md);
  const bookingsData = useBookings({ arePast, creatorId: currentTeamOwner?.id, memberId: selectedFilter });
  const header = useMemo(
    () => ({
      actions: arePast
        ? []
        : [
          {
            icon: CalendarPlus,
            isPrimary: true,
            label: 'Initiate Booking',
            onClick: () => setInitiateBookingModalOpen(true),
          },
        ],
      tabs: [
        {
          label: 'Upcoming',
          onClick: () => setArePast(false),
          selected: !arePast,
        },
        {
          label: 'Past',
          onClick: () => setArePast(true),
          selected: arePast,
        },
      ],
      title: 'Bookings',
    }),
    [setInitiateBookingModalOpen, arePast],
  );

  const teamMemberFilter = useMemo(() => {
    if (!teamMembers) {
      return [];
    }

    return [
      {
        isChecked: selectedFilter === 'all',
        key: 'all',
        label: 'All',
      },
      ...teamMembers.items.map(member => ({
        isChecked: selectedFilter === member.id,
        key: member.id,
        label: member.name || member.email || member.id,
      })),
    ];
  }, [teamMembers, selectedFilter]);

  useEffect(() => {
    setBrowserTabTitle("Bookings – Dashboard");
  }, [setBrowserTabTitle]);

  if (!bookingsData) {
    return null;
  }

  return (
    <ListAndDetailsSubLayout
      header={header}
      filters={{
        items: [
          {
            isMultiSelect: false,
            items: teamMemberFilter,
            label: 'Team member',
            onChange: setSelectedFilter,
            selected: selectedFilter,
          },
        ],
      }}
      isOneColumn={isOneColumn}
      resourceType="bookings"
      {...bookingsData}
    >
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Bookings;
