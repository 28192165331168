import React, {
  ChangeEvent,
  forwardRef,
  LegacyRef,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import CropImage from '@components/CropImage';
import s from './MediaPreview.module.scss';

type Props = {
  setPhotoFile?: (url: string) => void;
  setIsPhotoChanged?: (isPhotoChanged: boolean) => void;
  children?: ReactNode;
  accept?: string;
  setFile?: (file: File) => void;
  photoFile?: string | null;
  isCropImage?: boolean;
  isFullWidth?: boolean;
};

const MediaPreview = ({
  setPhotoFile,
  setIsPhotoChanged,
  children,
  accept="image/*",
  photoFile,
  setFile,
  isCropImage = false,
  isFullWidth = false,
}: Props, ref: LegacyRef<HTMLInputElement>): ReactElement => {
  const [isShowImageCrop, setIsShowImageCrop] = useState(false);
  const [photoBlob, setPhotoBlob] = useState(photoFile);

  useEffect(() => {
    setPhotoBlob(photoFile);
  }, [photoFile]);

  const handleUpdateFile = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const files = Array.from(e.target.files || []);
    if (setFile) {
      const blobUrl = URL.createObjectURL(files[0]);
      setPhotoBlob(blobUrl);
      if (!isCropImage) {
        setFile(files[0]);
      }
    }

    if (setPhotoFile) {
      const blobUrl = URL.createObjectURL(files[0]);
      setPhotoBlob(blobUrl);
      if (!isCropImage) {
        setPhotoFile(blobUrl);
      }
    }
    if (setIsPhotoChanged) {
      setIsPhotoChanged(true);
    }
    if (isCropImage) {
      setIsShowImageCrop(true);
    }
  };

  const clearValueInput = (e: React.MouseEvent<HTMLElement>): void => {
    (e.target as HTMLButtonElement).value = '';
  };

  return (
    <div className={s['media-preview']} style={{ width: isFullWidth ? '100%' : 'auto' }}>
      {children}
      <input
        id='userPhoto'
        type='file'
        ref={ref}
        accept={accept}
        onChange={e => handleUpdateFile(e)}
        onClick={(e) => clearValueInput(e)}
        style={{ display: 'none' }}
      />
      {isShowImageCrop && photoBlob && isCropImage && setPhotoFile && (
        <CropImage
          image={photoBlob}
          setIsShowImageCrop={setIsShowImageCrop}
          setPhotoFile={setPhotoFile}
        />
      )}
      {isShowImageCrop && photoBlob && isCropImage && setFile && (
        <CropImage
          image={photoBlob}
          setIsShowImageCrop={setIsShowImageCrop}
          setFile={setFile}
        />
      )}
    </div>
  );
};

export default forwardRef(MediaPreview);
