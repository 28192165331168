import { useRouter } from 'next/router';
import { FC, ReactElement, useContext, useMemo, useState } from 'react';

import Modal from '@components/Modal';
import UserImage from '@components/UserImage';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import TeamsContext from '@providers/Teams/TeamsContext';
import SearchInput from '@/components/FormFields/SearchInput';

import s from './TeamsModal.module.scss';

type Props = {
  onClose: () => void;
  onChange: () => void;
};

const RECENTS_KEY = 'recent-teams';

let memoizedRecents: string[] = [];

const getRecents = (): string[] => {
  if (memoizedRecents === null) {
    const recents = localStorage.getItem(RECENTS_KEY);
    memoizedRecents = recents ? JSON.parse(recents) : [];
  }
  return memoizedRecents;
};

const saveRecents = (teamId: string): void => {
  const recents = getRecents();
  if (recents.includes(teamId)) return;
  recents.unshift(teamId);
  localStorage.setItem(RECENTS_KEY, JSON.stringify(recents));
};

const TeamsModal: FC<Props> = ({ onClose, onChange }): ReactElement => {

  const router = useRouter();
  const { teams, setCurrentTeam, currentTeam } = useContext(TeamsContext);
  const [search, setSearch] = useState('');

  const onTeamChange = (teamId: string): void => {
    const selectedTeam = teams.find(team => team.id === teamId);
    if (selectedTeam) {
      setCurrentTeam(selectedTeam);
      saveRecents(teamId);
      router.push(getUrl(IDENTIFIERS.DASHBOARD));
      onChange();
    }
  };

  const sortedAndFilteredTeams = useMemo(() => {
    const recents = getRecents();
    const searchLC = search.toLowerCase();
    return teams
      .sort((a, b) => {
        const aIndex = recents.indexOf(a.id);
        const bIndex = recents.indexOf(b.id);
        if (currentTeam?.id === a.id) return -1;
        if (currentTeam?.id === b.id) return 1;
        if (aIndex === -1 && bIndex === -1) return 0;
        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;
        return aIndex - bIndex;
      })
      .filter(t => {
        const ownerNameLC = t.teamOwner.name?.toLowerCase() || "";
        const ownerSlugLC = t.teamOwner.slug?.toLowerCase() || "";
        return ownerNameLC.includes(searchLC) || ownerSlugLC.includes(searchLC);
      });
  }, [teams, currentTeam, search]);

  return (
    <Modal title={'Select Team'} onClose={onClose} >
      <SearchInput
        placeholder='Type name or slug...'
        label="Search"
        setSearch={setSearch}
        autoFocus
        className={s.search}
      />
      <ul className={s.list}>
        {sortedAndFilteredTeams.map(t => (
          <li key={t.id}>
            <button onClick={() => onTeamChange(t.id)} className={currentTeam?.id === t.id ? s.currentTeam : undefined}>
              <UserImage url={t.teamOwner.person.image?.url} name={t.teamOwner.name} color={t.teamOwner.primaryColour?.hex} size={40} />
              <div>
                <p>{t.teamOwner.name}</p>
                <small>{t.teamOwner.slug}</small>
              </div>
            </button>
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default TeamsModal;
