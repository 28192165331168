import SUPPORTED_CREATOR_COUNTRIES from '@helpers/supported-creator-countries';
import CreatorFlag from '@lib/CreatorBySlug/CreatorFlag';
import s from './DashboardLayout.module.scss';

const DashboardIsDisabled: React.FC = () => (
  <main className={s.notSupported}>
    <h1 className='heading-sm'>
      Unfortunately, your country is not supported.
    </h1>
    <p className='body-sm'>List of supported countries:</p>
    <ul>
      {SUPPORTED_CREATOR_COUNTRIES.map(i => <li key={i.code} className='caption'><CreatorFlag countryCode={i.code} />{i.name}</li>)}
    </ul>
  </main>
);

export default DashboardIsDisabled;