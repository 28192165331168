import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Plus from '@components/Icons/Plus';
import { HookResponse, PAGE_SIZE, ResourceType } from './common';

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_INVOICE_REQUESTS_EDIT, { id });

const useInvoiceRequests = ({
  creatorId,
  search,
  resourceType,
}: {
  creatorId: string | undefined;
  search: string | null;
  resourceType: ResourceType;
}): HookResponse & {
  createInvoiceRequestsModalOpen: boolean;
  setCreateInvoiceRequestsModalOpen: (v: boolean) => void;
} => {
  const [createInvoiceRequestsModalOpen, setCreateInvoiceRequestsModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const pathParams = useParams<{ id?: string }>();
  const [items, setItems] = useState<HookResponse['items']>([]);
  const mostRecentRequestRef = useRef<string | null>(null);

  const { getData: getCreatorInvoiceRequestsFn } = useNoodleApi(tsClient.invoices.getInvoiceRequestTemplates);

  const selectedId = pathParams?.id;

  const loadPage = useCallback(async ({ page, search: newSearch }: {search: string | null, page: number }): Promise<void> => {
    if (creatorId && resourceType === 'invoices') {
      setCurrentPage(page);
      const thisRequestId = nanoid();
      mostRecentRequestRef.current = thisRequestId;

      setIsFetching(true);
      const response = await getCreatorInvoiceRequestsFn({
        creatorId,
        page,
        perPage: PAGE_SIZE,
        search: newSearch ?? undefined,
      });

      if (mostRecentRequestRef.current === thisRequestId) {
        if (response.data) {
          setItems(response.data.items.map(request => ({
            href: getUrl(IDENTIFIERS.DASHBOARD_INVOICE_REQUESTS_EDIT, { id: request.id }),
            id: request.id,
            label: request.title,
          })));
          setNumPages(response.data.numPages);
        }
        setCreateInvoiceRequestsModalOpen(false);
        setIsFetching(false);
      }
    } else {
      setItems([]);
    }
  }, [
    creatorId,
    resourceType,
    getCreatorInvoiceRequestsFn,
  ]);

  useEffect(() => {
    loadPage({ page: 1, search });
  }, [loadPage, search]);

  const headerActions = [{
    icon: Plus,
    label: 'Create Invoice Request',
    onClick: () => setCreateInvoiceRequestsModalOpen(true),
  }];

  const pagination: HookResponse['pagination'] = {
    numPages,
    onChangePage: (newPage) => {
      loadPage({ page: newPage, search });
    },
    page: currentPage,
  };

  return {
    createInvoiceRequestsModalOpen,
    getDetailPagePath,
    headerActions,
    ifNoItemsText: 'No invoice requests yet',
    isFetchingList: isFetching,
    items,
    pagination,
    reloadList: () => loadPage({ page: 1, search }),
    selectedId,
    setCreateInvoiceRequestsModalOpen,
  };
};

export default useInvoiceRequests;
