import { useContext } from 'react';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import type { ListViewNavigationItem } from '@layouts/ListAndDetailsSubLayout';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import TeamsContext from '@providers/Teams/TeamsContext';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import Header from '@components/DesignLibrary/Header';
import UserCircle from '@components/Icons/UserCircle';
import Buildings from '@components/Icons/Buildings';
import CurrencyCircleDollar from '@components/Icons/CurrencyCircleDollar';
import ArrowBendDownRight from '@components/Icons/ArrowBendDownRight';
import { PWAContext } from '@providers/PWA';
import { useFeatureFlag } from '@providers/FeatureFlags';
import removeNullish from '@/helpers/removeNullish';
import { isAttorney } from '@/helpers/businessCategories';

type Page =
  | 'ai-agent'
  | 'billing'
  | 'team'
  | 'edit-profile'
  | 'attorney-information'
  | 'edit-business-details'
  | 'sms-follow-ups'
  | 'edit-logo'
  | 'stripe'
  | 'add-noodle-ai'
  | 'integrations'
  | 'log-out'
  | 'business-faq';

type Props = {
  pageId: Page | null;
  useDefaultHeader?: boolean;
};

const Account: React.FC<Props> = ({ children, pageId, useDefaultHeader = true }) => {
  const { setWidgetSnippetModalOpen, title, setAppModalOpen, creator } = useContext(DashboardContext);
  const { isInApp, installType } = useContext(PWAContext);
  const canShowIntegrations = useFeatureFlag("integrations");

  const isOneColumn = useIsMobile(SIZES.md);

  const showInstallNoodleApp = !isInApp && installType !== 'unsupported' && isOneColumn;

  const { currentTeamOwner } = useContext(TeamsContext);
  const creatorSlug = currentTeamOwner?.slug;
  const showAttorneyInformation = isAttorney(creator?.businessCategory);

  const SECTIONS: Parameters<typeof ListAndDetailsSubLayout>[number]['sections'][number] = [
    { icon: Buildings, label: 'Business Details' },
    { icon: ArrowBendDownRight, label: 'Follow-ups'},
    { icon: CurrencyCircleDollar, label: 'Payments and Billing'},
    { icon: UserCircle, label: 'Your Account Details' },
  ];

  const ITEMS: Array<ListViewNavigationItem> = [
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT_AI_AGENT),
      id: 'ai-agent',
      indented: true,
      isAi: true,
      label: 'Your Ai Agent',
      type: 'navigation',
    },
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT_EDIT_BUSINESS_DETAILS),
      id: 'edit-business-details',
      indented: true,
      label: 'Edit Business Details',
      section: 'Business Details',
      type: 'navigation',
    },
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT_SMS_FOLLOW_UPS),
      id: 'sms-follow-ups',
      indented: true,
      label: 'SMS',
      section: 'Follow-ups',
      type: 'navigation',
    },
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_TEAM),
      id: 'team',
      indented: true,
      label: 'Team',
      section: 'Business Details',
      type: 'navigation',
    },
    showInstallNoodleApp
      ? {
        id: 'install-noodle-app',
        indented: true,
        label: 'Install Noodle app',
        onClick: () => setAppModalOpen(true),
        section: 'Business Details',
        type: 'navigation',
      }
      : null,
    {
      id: 'add-noodle-ai',
      indented: true,
      label: 'Add Noodle AI to my website',
      onClick: () => setWidgetSnippetModalOpen(true),
      section: 'Business Details',
      type: 'navigation',
    },
    {
      href: 'https://dashboard.stripe.com/',
      id: 'stripe',
      indented: true,
      label: 'Open Stripe',
      newTab: true,
      section: 'Payments and Billing',
      type: 'navigation',
    },
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT_BILLING),
      id: 'billing',
      indented: true,
      label: 'Plans and Billing',
      section: 'Payments and Billing',
      type: 'navigation',
    },
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT_EDIT_PROFILE),
      id: 'edit-profile',
      indented: true,
      label: 'Edit Profile',
      section: 'Your Account Details',
      type: 'navigation',
    },
    canShowIntegrations
      ? {
        href: getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT_INTEGRATIONS),
        id: 'integrations',
        indented: true,
        label: 'Integrations',
        section: 'Business Details',
        type: 'navigation',
      }
      : null,
    showAttorneyInformation
      ? {
        href: getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT_ATTORNEY_INFO),
        id: 'attorney-information',
        indented: true,
        label: 'Attorney Information',
        section: 'Your Account Details',
        type: 'navigation',
      }
      : null,
    {
      href: getUrl(IDENTIFIERS.LOGOUT),
      id: 'log-out',
      indented: true,
      label: 'Log out',
      section: 'Your Account Details',
      type: 'navigation',
    },
  ].filter(removeNullish) as Array<ListViewNavigationItem>;

  return (
    <ListAndDetailsSubLayout
      header={{
        description: creatorSlug ? `@${creatorSlug}` : null,
        title: 'Your Account',
      }}
      items={ITEMS}
      sections={SECTIONS}
      isOneColumn={isOneColumn}
      selectedId={pageId}
      getDetailPagePath={() => getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT_EDIT_PROFILE)}
      resourceType={'account'}
    >
      {useDefaultHeader && <Header title={title} />}
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Account;
