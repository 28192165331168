import React, { useEffect, useMemo, useState } from 'react';

import Spacer from '@components/Spacer';
import { NoodleProductTypes } from '@typings/api-models';
import Button from '@components/DesignLibrary/Button';
import CalendarBlank from '@components/Icons/CalendarBlank';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import RadioInput from '@components/FormFields/RadioInputCards';
import { useToast } from '@hooks';
import GenerateFieldWithAI from '@components/FormFields/GenerateFieldWithAI';
import Tooltip from '@components/Tooltip';
import { ErrorFlow } from '@providers/ErrorTracking';
import WhatIs from '@components/WhatIs';
import Tabs from '@components/DesignLibrary/Tabs';
import s from './CreateServiceForm.module.scss';
import createServiceCardData from './createServiceCardData';
import { AllowedNoodleProductTypes } from './constants';

type ServiceCreationData = {
  productTitle: string;
  productTitleAIRequestId?: string | null;
  productType: AllowedNoodleProductTypes;
};

type CreateServiceProps = {
  creatorTier: Parameters<typeof createServiceCardData>[0];
  businessCategory?: string | null;
  isSubmitting: boolean;
  onSubmit: (data: ServiceCreationData) => Promise<void>;
  errorFlow?: ErrorFlow;
  hideOnboardingCollection?: boolean;
  areFeesHidden?: boolean;
  initialProductType?: AllowedNoodleProductTypes;
  hideTabs?: boolean;
};

const CreateServiceForm: React.FC<CreateServiceProps> = ({
  businessCategory,
  creatorTier,
  isSubmitting: isSubmittingExternal,
  onSubmit,
  errorFlow,
  hideOnboardingCollection,
  areFeesHidden,
  initialProductType,
  hideTabs,
}) => {
  const addToast = useToast();
  const [selectedProductType, setSelectedProductType] = useState<AllowedNoodleProductTypes>(initialProductType || NoodleProductTypes.OnlineSession);
  const [productCategory, setProductCategory] = useState<'product' | 'service'>('product');
  const { getData: generateProductTitle } = useNoodleApi(tsClient.ai.generateProductTitle, { errorFlow });
  const { getData: acceptGeneratedText } = useNoodleApi(tsClient.ai.acceptGeneratedText, { errorFlow });

  const [productTitle, setProductTitle] = useState<string>('');
  const [productTitleAIRequestId, setProductTitleAIRequestId] = useState<string | null>(null);
  const [isSubmittingInternal, setIsSubmittingInternal] = useState(false);
  const [titleError, setTitleError] = useState<string | null>(null);
  const [markAsTouched, setMarkAsTouched] = useState(false);

  const isValid = !titleError;
  const isSubmitting = isSubmittingInternal || isSubmittingExternal;

  const cardData = useMemo(() => {
    if (hideOnboardingCollection) {
      return createServiceCardData(creatorTier).filter(e => e.label !== NoodleProductTypes.Collection);
    }
    return createServiceCardData(creatorTier);
  }, [creatorTier, hideOnboardingCollection]);

  const handleSubmit = async (): Promise<void> => {
    if (!isValid) {
      setMarkAsTouched(true);
    } else {
      setIsSubmittingInternal(true);
      await Promise.all([
        onSubmit({
          productTitle,
          productType: selectedProductType,
        }),
        productTitleAIRequestId
          ? acceptGeneratedText({
            requestId: productTitleAIRequestId,
            usedText: productTitle,
          })
          : null,
      ]);
      setIsSubmittingInternal(false);
    }
  };

  const handleChooseService = (type: AllowedNoodleProductTypes): void => {
    setSelectedProductType(type);
  };

  const handleProductTitleChange = (title: string, requestId: string | null): void => {
    if (requestId) {
      setProductTitleAIRequestId(requestId);
    }
    setProductTitle(title);
  };

  const handleGenerateProductTitle = async (): Promise<void> => {
    const response = await generateProductTitle({
      businessCategory: businessCategory || 'Other',
      productType: selectedProductType,
    });

    if (response.data) {
      handleProductTitleChange(response.data.generatedText || '', response.data.id);
    } else if (response.error) {
      addToast(useToast.ToastTypeVariants.ERROR, response.error?.message || 'Failed to generate description');
    } else {
      handleProductTitleChange('', null);
    }
  };

  const getPlaceHolder = (type: AllowedNoodleProductTypes): string => {
    switch (type) {
    case NoodleProductTypes.InPersonSession:
      return 'i.e. In Person Service';
    case NoodleProductTypes.OnlineSession:
      return 'i.e. Video Call Consult';
    case NoodleProductTypes.Lite:
      return 'i.e. Ask Me Anything!';
    case NoodleProductTypes.Handbook:
      return 'i.e. Digital eBook, or Video Guide';
    case NoodleProductTypes.Subscription:
      return 'i.e. My Community Membership';
    case NoodleProductTypes.Collection:
      return 'i.e. Bytes Collection';
    case NoodleProductTypes.Consultation:
      return 'i.e. Consultation';
    default:
      return 'Service Title';
    }
  };

  const cardDataHandleHiddenFees = areFeesHidden
    ? cardData.map(({ ...all }) => ({
      ...all,
      description: '',
    }))
    : cardData;

  const servicesCardData = cardDataHandleHiddenFees
    .filter(
      ({ id }) =>
        id === NoodleProductTypes.InPersonSession
        || id === NoodleProductTypes.OnlineSession
        || id === NoodleProductTypes.Lite
        || id === NoodleProductTypes.Consultation,
    )
    .map(({ ...all }) => ({
      ...all,
      defaultChecked: all.id === selectedProductType,
    }));

  const productsCardData = cardDataHandleHiddenFees
    .filter(({ id }) => id === NoodleProductTypes.Handbook || id === NoodleProductTypes.Subscription || id === NoodleProductTypes.Collection)
    .map(({ ...all }) => ({
      ...all,
      defaultChecked: all.id === selectedProductType,
    }));

  useEffect(() => {
    if (
      selectedProductType === NoodleProductTypes.InPersonSession
      || selectedProductType === NoodleProductTypes.OnlineSession
      || selectedProductType === NoodleProductTypes.Lite
      || selectedProductType === NoodleProductTypes.Consultation
    ) {
      setProductCategory('service');
    } else {
      setProductCategory('product');
    }
  }, [selectedProductType]);

  return (
    <div className={s.wrapper}>
      {!hideTabs && (
        <Tabs
          inputName="serviceOrProduct"
          inputs={[
            { id: 'service', label: 'Service' },
            { id: 'product', label: 'Content' },
          ]}
          checked={productCategory}
          onChange={setProductCategory}
        />
      )}
      <Spacer size={24} />
      <div className={s.services}>
        {productCategory === 'service' && (
          <RadioInput inputName="product" legend={`How do you provide your service?`} inputs={servicesCardData} onChange={handleChooseService} />
        )}
        {productCategory === 'product' && (
          <RadioInput
            inputName="product"
            legend={`How would you like to sell your content?`}
            inputs={productsCardData}
            onChange={handleChooseService}
          />
        )}
      </div>
      <Spacer size={16} />
      {(selectedProductType === NoodleProductTypes.InPersonSession
        || selectedProductType === NoodleProductTypes.OnlineSession
        || selectedProductType === NoodleProductTypes.Consultation) && (
        <p className={s.scheduling}>
          <CalendarBlank weight="fill" size={16} />
          {selectedProductType === NoodleProductTypes.InPersonSession || selectedProductType === NoodleProductTypes.Consultation
            ? 'Requires'
            : 'Supports'}{' '}
          scheduling
        </p>
      )}
      <Spacer size={24} />
      <WhatIs
        showQuestionIcon
        title="See video on how it works"
        loom={
          (selectedProductType === NoodleProductTypes.InPersonSession && 'https://www.loom.com/share/3897c6f258904f6086bf969431630a0f')
          || (selectedProductType === NoodleProductTypes.OnlineSession && 'https://www.loom.com/share/3196a8c330634e31affcb8a588c7a907')
          || (selectedProductType === NoodleProductTypes.Lite && 'https://www.loom.com/share/b177868b0e6744889704ea5c47f19860')
          || (selectedProductType === NoodleProductTypes.Subscription && 'https://www.loom.com/share/4c19203e3d744b5cb6efb992c1949152')
          || undefined
        }
        className={s.loom}
      />
      <div className={s.withTooltip}>
        <Tooltip alignment="right" id="productTitle" text="Your service can be anything from a Free Consultation to an Ebook" />
        <GenerateFieldWithAI
          id="productTitle"
          value={productTitle}
          name={`${productCategory === 'service' ? 'Service' : 'Product'} Title`}
          label={`Enter ${productCategory === 'service' ? 'Service' : 'Product'} Title`}
          placeholder={getPlaceHolder(selectedProductType)}
          isLargeField={false}
          onGenerateText={handleGenerateProductTitle}
          onChange={handleProductTitleChange}
          onError={setTitleError}
          required
          isTouched={markAsTouched}
        />
      </div>
      <Spacer size={24} />
      <Button variant='primary' size='lg' fullWidth onClick={handleSubmit} loading={isSubmitting} className={s.button}>
        Create {productCategory === 'service' ? 'Service' : 'Product'}
      </Button>
    </div>
  );
};

export default CreateServiceForm;
