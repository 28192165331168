import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useActivity from './useActivity';

const WorkflowTracker: React.FC = ({ children }) => {
  const activityData = useActivity({ resourceType: 'activity' });
  const header = {
    title: 'Workflow Tracker',
  };

  if (!activityData) {
    return null;
  }

  return (
    <div>
      <ListAndDetailsSubLayout
        header={header}
        isOneColumn={false}
        isTableViewOnly={true}
        resourceType='workflow-tracker'
        {...activityData}
      >
        {children}
      </ListAndDetailsSubLayout>
    </div>
  );
};

export default WorkflowTracker;
