import { FC, useCallback, useState } from 'react';
import Modal from '@components/Modal';
import { useUser } from '@hooks';
import Spacer from '@components/Spacer';
import PasscodeInput from '@components/FormFields/PasscodeInput';
import Buttons from '@components/Buttons';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { ToastTypeVariants } from '@context/ToastContext';

type Props = {
  setIsVisible: (isOpen: boolean) => void;
  creatorId: string;
};

const DocumentRequestsPasscodeModal: FC<Props> = ({ setIsVisible, creatorId }) => {
  const [password, setPassword] = useState('');
  const [user] = useUser();

  const { fetchingState, getData: saveDocumentAccessPassword } = useNoodleApi(tsClient.documentAccess.saveDocumentAccessPassword, {
    toastOnError: true,
    toastSuccessMessage: () => [ToastTypeVariants.INFO, 'Passcode saved'],
  });

  const handleChangePassword = useCallback((str: string): void => {
    setPassword(str);
  }, []);

  const handleSavePasscode = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (creatorId && user) {
      const response = await saveDocumentAccessPassword({
        creatorId,
        password,
      });
      if (!response.error) {
        setIsVisible(false);
      }
    }
  };

  return (
    <Modal

      title='Passcode'
      onClose={() => setIsVisible(false)}

    >
      <form style={{ padding: 24 }} onSubmit={handleSavePasscode}>
        <PasscodeInput
          id={'document-access-password'}
          onChange={handleChangePassword}
          label={'Provide a 4-digit passcode that your team members will use to access documents.'}
        />
        <Spacer />
        <Buttons title="Save" isSecondary isFullWidth isFetching={fetchingState.isFetching}>
          Save
        </Buttons>
      </form>
    </Modal>
  );
};

export default DocumentRequestsPasscodeModal;
