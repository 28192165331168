import { useContext, useState, useMemo } from 'react';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import CreateEditTermsTemplateModal from '@modals/CreateEditTermsTemplateModal';
import CreateEditDocumentRequestModal from '@modals/CreateEditDocumentRequestModal';
import TeamsContext from '@providers/Teams/TeamsContext';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import FormRequestModal from '@modals/FormRequestModal';
import InvoiceRequestModal from '@modals/InvoiceRequestModal';
import { ResourceType } from './common';
import useDocumentRequests from './useDocumentRequests';
import useFormRequest from './useFormRequests';
import useInvoiceRequests from './useInvoiceRequests';
import useTermsTemplates from './useTermsTemplates';

type Props = {
  resourceType: ResourceType;
};

const TABS: Array<{
  href: string;
  label: string;
  resourceType: ResourceType;
}> = [
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_TERMS_TEMPLATES),
    label: 'Custom Terms Templates',
    resourceType: 'custom-terms',
  },
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_DOCUMENT_REQUEST_TEMPLATES),
    label: 'Document Requests',
    resourceType: 'document-requests',
  },
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_FORM_REQUESTS),
    label: 'Form Requests',
    resourceType: 'form-requests',
  },
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_INVOICE_REQUESTS),
    label: 'Invoice Requests',
    resourceType: 'invoices',
  },
];

const BuildingBlocks: React.FC<Props> = ({ children, resourceType }) => {
  const isOneColumn = useIsMobile(SIZES.md);
  const { currentTeamOwner } = useContext(TeamsContext);
  const [search, setSearch] = useState<string | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const creatorId = currentTeamOwner?.id;

  const documentRequestData = useDocumentRequests({
    creatorId,
    resourceType,
    search,
  });

  const formRequestData = useFormRequest({
    creatorId,
    resourceType,
    search,
    tags: selectedTags,
  });

  const invoiceRequestData = useInvoiceRequests({
    creatorId,
    resourceType,
    search,
  });

  const termsTemplatesData = useTermsTemplates({
    creatorId,
    resourceType,
    search,
  });

  const tabs = useMemo(() => {
    const newTabs = TABS.map(({ resourceType: thisResourceType, ...tabProps }) => ({
      ...tabProps,
      selected: thisResourceType === resourceType,
    }));

    return newTabs;
  }, [resourceType]);

  const handleSearch = (newSearch: string): void => {
    setSearch(newSearch ? newSearch.toLowerCase() : null);
  };

  const reloadListView = async (): Promise<void> => {
    switch (resourceType) {
    case 'document-requests':
      await documentRequestData.reloadList();
      break;
    case 'form-requests':
      await formRequestData.reloadList();
      break;
    case 'invoices':
      await invoiceRequestData.reloadList();
      break;
    case 'custom-terms':
      await termsTemplatesData.reloadList();
      break;
    default:
      break;
    }
  };

  const selectedData = (resourceType === 'document-requests' && documentRequestData)
    || (resourceType === 'form-requests' && formRequestData)
    || (resourceType === 'invoices' && invoiceRequestData)
    || (resourceType === 'custom-terms' && termsTemplatesData)
    || {
      getDetailPagePath: () => '#',
      headerActions: [],
      ifNoItemsText: 'No data',
      items: [],
      pagination: undefined,
      selectedId: undefined,
    };

  return (
    <ListAndDetailsSubLayout
      header={{
        actions: selectedData.headerActions,
        tabs,
        title: 'Building Blocks',
      }}
      items={selectedData.items}
      selectedId={selectedData.selectedId}
      ifNoItemsText={selectedData.ifNoItemsText}
      isOneColumn={isOneColumn}
      getDetailPagePath={selectedData.getDetailPagePath}
      onSearch={handleSearch}
      pagination={selectedData.pagination}
      reloadListView={reloadListView}
      resourceType={resourceType}
      filters={resourceType === 'form-requests' && formRequestData.formRequestTemplateTags.length > 0
        ? {
          items: [
            {
              isMultiSelect: true,
              items: formRequestData.formRequestTemplateTags.map((tag) => ({
                isChecked: selectedTags.includes(tag.slug),
                key: tag.slug,
                label: tag.label,
              })),
              label: 'Tag',
              onChange: setSelectedTags,
            },
          ],
        }
        : undefined}
    >
      {children}
      {termsTemplatesData.createTermsModalOpen && creatorId && (
        <CreateEditTermsTemplateModal
          termsTemplate={null}
          onClose={() => termsTemplatesData.setCreateTermsModalOpen(false)}
          onSubmit={termsTemplatesData.reloadList}
        />
      )}
      {documentRequestData.createDocumentRequestsModalOpen && creatorId && (
        <CreateEditDocumentRequestModal
          documentRequest={null}
          onClose={() => documentRequestData.setCreateDocumentRequestsModalOpen(false)}
          onSubmit={documentRequestData.reloadList}
        />
      )}
      {formRequestData.createFormRequestsModalOpen && creatorId && (
        <FormRequestModal
          beforeClose={formRequestData.reloadList}
          onClose={() => formRequestData.setCreateFormRequestsModalOpen(false)}
          formRequestTemplate={null}
          creatorId={creatorId}
        />
      )}
      {invoiceRequestData.createInvoiceRequestsModalOpen && creatorId && (
        <InvoiceRequestModal
          beforeClose={invoiceRequestData.reloadList}
          onClose={() => invoiceRequestData.setCreateInvoiceRequestsModalOpen(false)}
          invoiceTemplate={null}
        />
      )}
    </ListAndDetailsSubLayout>
  );
};

export default BuildingBlocks;
