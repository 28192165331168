import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import { NoOptionalProperties } from '@typings/utility';

export type ResourceType = 'document-requests' | 'form-requests' | 'invoices' | 'custom-terms';

export type HookResponse =
  & NoOptionalProperties<Pick<Parameters<typeof ListAndDetailsSubLayout>[0],
    | 'items'
    | 'pagination'
    | 'isFetchingList'
    | 'ifNoItemsText'
    | 'getDetailPagePath'
  >>
  & Pick<Parameters<typeof ListAndDetailsSubLayout>[0],
    | 'selectedId'
  >
  & {
      headerActions: Array<{
        label: string;
        onClick: () => void;
      }>
      reloadList: () => Promise<void>;
    };

export const PAGE_SIZE = 25;
