import { ReactElement, useEffect } from 'react';

import Modal from '@components/Modal';
import CreateBroadcast from '@lib/Creators/Dashboard/Broadcasts/CreateEditBroadcast';
import { mixpanelTrack } from '@providers/Mixpanel';
import s from './createBroadcastModal.module.scss';

type Props = {
  setIsVisible: (isOpen: boolean) => void;
  onPublish: () => Promise<unknown>;
  broadcastTiers: Parameters<typeof CreateBroadcast>[0]['tiers'];
  creatorSlug: string;
  productSlug?: string | null;
  creatorName?: string | null;
  broadcast?: Parameters<typeof CreateBroadcast>[0]['broadcast'];
  isCommunityPost: boolean;
};

const CreateEditBroadcastModal = ({
  broadcastTiers,
  creatorSlug,
  creatorName,
  productSlug,
  setIsVisible,
  onPublish,
  broadcast,
  isCommunityPost,
}: Props): ReactElement => {

  useEffect(() => {
    mixpanelTrack(`Edit mode - ${broadcast ? 'edit' : 'create'} ${isCommunityPost ? 'post' : 'broadcast'}`);
  }, [broadcast, isCommunityPost]);

  return (
    <Modal

      title={`${broadcast ? 'Edit' : 'Create'} ${isCommunityPost ? 'Community Post' : 'Broadcast'}`}
      onClose={() => setIsVisible(false)}

    >
      {productSlug && (
        <div className={s.broadcastModalPage}>
          <CreateBroadcast
            creatorName={creatorName}
            creatorSlug={creatorSlug}
            productSlug={productSlug}
            tiers={broadcastTiers}
            onPublish={onPublish}
            broadcast={broadcast}
            isCommunityPost={isCommunityPost}
          />
        </div>
      )}
      {!productSlug && (
        <div className={s.noProductStyle}>
          {'You need a membership product to create this broadcast.'}
        </div>
      )}
    </Modal>
  );
};

export default CreateEditBroadcastModal;
