import classNames from 'classnames';
import { IBM_Plex_Mono as Mono } from 'next/font/google';
import { FC, useCallback, useEffect, useState } from 'react';
import * as IntegrationsApiModels from '@typings/integrations-api-models';
import { useToast } from '@/hooks';
import CircularProgress from '@/components/CircularProgress';
import Buttons from '@/components/Buttons';
import s from './TFACode.module.scss';

const font = Mono({
  display: 'swap',
  subsets: ['latin'],
  weight: ['400'],
});

const TFACode: FC<{
  topLine: string;
  bottomLine: string;
  className?: string;
  provider: string;
  getCode: (provider: string) => Promise<IntegrationsApiModels.MFARequest | null>;
}> = ({ getCode, topLine, bottomLine, className, provider }) => {
  const addToast = useToast();
  const [code, setCode] = useState('');
  const [progress, setProgress] = useState(0);

  const copyToClipboard = async (text: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(text);
      addToast(useToast.ToastTypeVariants.SUCCESS, 'Copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const getCodeFn = useCallback(async (): Promise<void> => {
    const data = await getCode(provider);
    if (data) {
      setCode(data.mfaCode);
      setProgress(Math.round(data.timeUsed / (data.timeRemaining + data.timeUsed) * 100));
      setTimeout(() => getCodeFn(), data.timeRemaining * 1000);
    }
  }, [getCode, provider]);

  useEffect(() => {
    getCodeFn();
  }, [getCodeFn]);

  useEffect(() => {
    const updateProgressInterval = setInterval(() => {
      setProgress(prevProgress => (prevProgress >= 100 ? 100 : prevProgress + (1 / 3)));
    }, 100);

    return () => clearInterval(updateProgressInterval);
  }, []);

  return (
    <Buttons disabled={!code} isWrapper className={classNames(s.code, className)} onClick={() => copyToClipboard(code)}>
      <div className={s.codeText}>
        <p className="caption">{topLine}</p>
        <p className="caption">{bottomLine}</p>
      </div>
      <div className={s.codeCurrent}>
        <span className={classNames('body-lg', font.className)}>{code}</span>
        <CircularProgress size={20} progress={progress} noTransition />
      </div>
    </Buttons>
  );
};

export default TFACode;
