import { ReactElement, useState } from 'react';
import { m } from 'framer-motion';
import { CheckCircle, Circle, Spinner } from '@phosphor-icons/react';
import s from './CreatorDashboardShimmer.module.scss';

const STEP = -24;
const ITEMS = [
  'Fetching data...',
  'Prioritizing...',
  'Building interface...',
];

const CreatorDashboardShimmer = ({
  items = ITEMS,
}): ReactElement<{
  items?: Array<string>;
}> => {
  const [indexDone, setIndexDone] = useState<number>(0);

  const animationPositions = [...new Array(items.length)].map((_, i) => i * STEP).flatMap(y => [y, y, y]);

  return (
    <div className={s['creator-dashboard-shimmer']}>
      <m.ul
        animate={{
          transition: {
            duration: items.length,
          },
          y: animationPositions,
        }}
      >
        {items.map((i, index) => (
          <li key={i}>
            {indexDone && indexDone > index
              ? (
                <m.div initial={{ scale: 0 }} animate={{ scale: 1 }}>
                  <CheckCircle weight="fill" color="var(--color-success)" size={16} />
                </m.div>
              )
              : (
                <m.div
                  animate={{
                    rotate: 180,
                    transition: {
                      duration: 1,
                      repeat: index === items.length - 1 ? Infinity : index,
                    },
                  }}
                  onAnimationComplete={() => {
                    setIndexDone(index + 1);
                  }}
                >
                  {index === indexDone ? <Spinner color="var(--color-gray-75)" size={16} /> : <Circle color="var(--color-gray-75)" size={16} />}
                </m.div>
              )}
            <m.span animate={{ opacity: index === indexDone - 1 ? 0.5 : 1 }}>{i}</m.span>
          </li>
        ))}
      </m.ul>
    </div>
  );
};

export default CreatorDashboardShimmer;
