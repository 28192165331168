import { ReactElement, useEffect, useState } from 'react';

import Modal from '@components/Modal';
import { mixpanelTrack } from '@providers/Mixpanel';
import CreateServiceForm from '@components/CreateServiceForm';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { NoodleProductTypes } from '@typings/api-models';
import { AllowedNoodleProductTypes } from '@components/CreateServiceForm/constants';
import s from './CreateServiceModal.module.scss';

type Props = {
  businessCategory?: string | null;
  creator: {
    id: string;
    creatorTier: Parameters<typeof CreateServiceForm>[0]['creatorTier'];
  };
  setIsVisible: (isOpen: boolean) => void;
  onPublish: (productSlug: string, creatorSlug: string, productType: string) => Promise<void>;
  handleModals: (type: string, title?: string) => void;
  initialProductType?: AllowedNoodleProductTypes;
};

const CreateServiceModal = ({ businessCategory, creator, setIsVisible, onPublish, handleModals, initialProductType }: Props): ReactElement => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { getData: createProductFn } = useNoodleApi(tsClient.createProduct, { toastOnError: true });

  const creatorId = creator.id;

  useEffect(() => {
    mixpanelTrack('Edit mode - create service');
  }, []);

  const handleSubmit: Parameters<typeof CreateServiceForm>[0]['onSubmit'] = async serviceData => {
    const { productType, productTitle } = serviceData;

    if (productType === NoodleProductTypes.Collection || productType === NoodleProductTypes.Subscription) {
      handleModals(productType, productTitle);
    } else {
      setIsSubmitting(true);

      const response = await createProductFn({
        creatorId,
        productTitle: serviceData.productTitle,
        productType,
      });

      if (response.data) {
        await onPublish(response.data.slug, response.data.creator.slug, productType);
        setIsSubmitting(false);
        setIsVisible(false);
      }
    }
  };

  return (
    <Modal

      title={'Create Product or Service'}
      onClose={() => setIsVisible(false)}

    >
      <div className={s.createServiceModalPage}>
        <CreateServiceForm
          businessCategory={businessCategory}
          creatorTier={creator.creatorTier}
          isSubmitting={isSubmitting}
          onSubmit={handleSubmit}
          areFeesHidden
          hideTabs
          initialProductType={initialProductType}
        />
      </div>
    </Modal>
  );
};

export default CreateServiceModal;
