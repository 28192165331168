import React, {ReactElement} from "react";
import Divider from "@components/Divider";
import ByteItem from "@components/CreateCollectionForm/ByteItem/ByteItem";
import getCreatorCollections from "@tsClient/getCreatorCollections";

type Collection = Awaited<ReturnType<typeof getCreatorCollections>>[0]['productTypes'][0];
type ProductContainerProps = {
  collection?: Collection;
};
const CollectionProductsContainer = ({collection}: ProductContainerProps): ReactElement => {
  const selectedProducts = collection?.products;

  if (!selectedProducts) return <></>;
  return (
    <>
      {selectedProducts
        .map((product, i): ReactElement | void => (
          <div key={`collection-product-${i}`}>
            <ByteItem
              id={product.id}
              key={`byte-item-${i}`}
              title={product.title ?? ''}
              stepAmount={product.handbookSteps.length}
            />
            <Divider dashed />
          </div>
        ))}
    </>
  );
};

export default CollectionProductsContainer;