import React, { ReactElement, useContext, useState } from 'react';
import Button from '@components/Buttons/Buttons';
import Spacer from '@components/Spacer';
import Modal from '@components/Modal';
import PushNotificationContext from '@providers/PushNotifications/Context';

type Props = {
  closeModal: () => void;
};

const NotificationPermissionModal = ({ closeModal }: Props): ReactElement => {
  const [isSettingPermissions, setIsSettingPermissions] = useState(false);
  const { registerPushNotifications } = useContext(PushNotificationContext);

  return (
    <Modal hasPadding onClose={closeModal} title='Push notifications' >
      <div>
        <h2 className='body-md-bold'>Push Notifications</h2>
        <Spacer size='8px' />
        <p className='caption'>To receive push notifications, please click the button and accept the permission request.</p>
        <Spacer size='24px' />
        <Button
          isFullWidth
          isSecondary
          disabled={false}
          isFetching={isSettingPermissions}
          onClick={async () => {
            setIsSettingPermissions(true);
            await registerPushNotifications();
            closeModal();
          }}
        >
          Enable push notifications
        </Button>
      </div>
    </Modal>
  );
};

export default NotificationPermissionModal;
