import { useContext, useEffect } from 'react';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import ListAndDetailsSubLayout, { ListViewItem } from '@layouts/ListAndDetailsSubLayout';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import TeamsContext from '@providers/Teams/TeamsContext';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import SquaresFour from '@components/Icons/SquaresFour';
import ArrowLineUpRight from '@components/Icons/ArrowLineUpRight';
import ArrowLineDown from '@components/Icons/ArrowLineDown';
import HandCoins from '@components/Icons/HandCoins';
import { NOODLE_STRIPE_ACCOUNT_ID } from '@configuration/client';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { useRouter } from 'next/router';
import removeNullish from '@helpers/removeNullish';
import { logError } from '@providers/ErrorTracking';

type Page = 'payments' | 'payouts' | 'invoices' | 'overview' | null;

type Props = {
  resourceType: Page;
};

const Transactions: React.FC<Props> = ({ children, resourceType }) => {
  const isOneColumn = useIsMobile(SIZES.md);
  const { creatorId, currentTeamOwner } = useContext(TeamsContext);
  const creatorSlug = currentTeamOwner?.slug;

  const router = useRouter();

  const { creator, routableCompany } = useContext(DashboardContext);

  const { getData: getDashboardLinkFn } = useNoodleApi(tsClient.creators.getExpressDashboardLink);
  const { data: secondaryStripeAccounts, getData: getSecondaryStripeAccountsFn } = useNoodleApi(tsClient.creators.getSecondaryStripeAccounts);
  const { getData: updateRoutableFn } = useNoodleApi(tsClient.creators.connectCreatorToRoutable);

  const updateRoutable = async (): Promise<void> => {
    if (creatorSlug) {
      const { data } = await updateRoutableFn({ creatorSlug });
      if (data?.invitationUrl) {
        await router.push(data.invitationUrl);
      } else {
        logError(new Error('Failed to update routable'));
      }
    }
  };
  useEffect(() => {
    if (creatorId) {
      getSecondaryStripeAccountsFn({ creatorId });
    }
  }, [getSecondaryStripeAccountsFn, creatorId]);

  const redirectToStripeDashboard = async (stripeAccountId: string): Promise<void> => {
    if (creatorId) {
      const { data } = await getDashboardLinkFn({ creatorId, stripeAccountId });
      if (data?.url) {
        await router.push(data.url);
      } else {
        logError(new Error('Failed to get stripe dashboard link'));
      }
    }
  };

  const stripeAccounts = [
    currentTeamOwner?.stripe_account_id ? { name: 'Primary', stripeAccountId: currentTeamOwner?.stripe_account_id } : null,
    ...(secondaryStripeAccounts || []),
  ].filter(removeNullish);

  const LIST_ITEMS: Array<ListViewItem> = [
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_TRANSACTIONS_PAYMENTS),
      icon: ArrowLineDown,
      id: 'payments',
      label: 'Payments',
      type: 'navigation',
    },
    !creator?.usesConfido
      ? {
        href: getUrl(IDENTIFIERS.DASHBOARD_TRANSACTIONS_PAYOUTS),
        icon: ArrowLineUpRight,
        id: 'payouts',
        label: 'Payouts',
        type: 'navigation',
      }
      : null,
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_TRANSACTIONS_INVOICES),
      icon: HandCoins,
      id: 'invoices',
      label: 'Invoices',
      type: 'navigation',
    },
    {
      href: getUrl(IDENTIFIERS.DASHBOARD_TRANSACTIONS_OVERVIEW),
      icon: SquaresFour,
      id: 'overview',
      label: 'Overview',
      type: 'navigation',
    },
  ].filter(removeNullish) as Array<ListViewItem>;

  const isNoodleStripeAccountId = creator?.stripe_account_id === NOODLE_STRIPE_ACCOUNT_ID;
  const hasRoutable = routableCompany?.business_name && routableCompany?.id && isNoodleStripeAccountId;

  const showAddRoutable = routableCompany?.business_name
    && routableCompany?.id
    && isNoodleStripeAccountId
    && !routableCompany?.isBankAccountConnected
    && creatorSlug;

  return (
    <ListAndDetailsSubLayout
      header={{
        actions: [
          ...(!hasRoutable && !isNoodleStripeAccountId && creator?.isBuyEnabled
            ? stripeAccounts.map(stripeAccount => ({
              label: `Open Stripe Payouts${stripeAccount.name !== 'Primary' ? ` (${stripeAccount.name})` : ' (Primary)'}`,
              onClick: () => redirectToStripeDashboard(stripeAccount.stripeAccountId),
            }))
            : []),
          ...(showAddRoutable
            ? [
              {
                label: 'Add your bank account',
                onClick: updateRoutable,
              },
            ]
            : []),
        ],
        description: routableCompany?.business_name ? `Routable: ${routableCompany?.business_name}` : null,
        title: 'Transactions',
      }}
      items={LIST_ITEMS}
      isOneColumn={isOneColumn}
      selectedId={resourceType}
      getDetailPagePath={() => getUrl(IDENTIFIERS.DASHBOARD_TRANSACTIONS_PAYMENTS)}
      resourceType='transactions'
    >
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Transactions;
