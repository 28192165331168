import { Fragment, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { m, useViewportScroll, useTransform } from 'framer-motion';
import LogRocket from '@providers/LogRocket';
import { useIsMobile, useUser } from '@hooks';
import { useIsUserInitialized, useUserProfile } from '@providers/Auth';
import useNoodleApi, { ToastErrorMessage } from '@hooks/useNoodleApi';
import { getCreatorProducts, getRoutableCompany, getProductTiers, updateCreator, getCreatorCollections } from '@tsClient';
import getCreatorProfile from '@tsClient/creators/getCreatorProfile';
import { useRouter } from 'next/router';
import { ToastTypeVariants } from '@hooks/useToast';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import CreatorDashboardShimmer from '@components/Dashboards/CreatorDashboardShimmer';
import { mixpanelTrack } from '@providers/Mixpanel';
import * as tsClient from '@tsClient';
import DashboardFTUEModal from '@components/Dashboards/DashboardFTUEModal/DashboardFTUEModal';
import useDismissedMessages from '@providers/Messages/useDismissedMessages';
import JobsContainer from '@providers/Jobs/JobsContainer';
import { FEATURE_FLAG_PUSH_NOTIFICATIONS_ENABLED, SELF_ORIGIN } from '@configuration/client';
import { Layout } from '@typings/NextJS';
import Spacer from '@components/Spacer';
import CreateEditBroadcastModal from '@modals/CreateEditBroadcastModal';
import PaymentLinkModal from '@modals/PaymentLinkModal';
import DashboardUserModal from '@layouts/DashboardLayout/components/DashboardUserModal';
import LoggedInUserGate from '@components/Auth/LoggedInUserGate';
import GetNoodleAppModal from '@modals/GetNoodleAppModal';
import DocumentRequestsPasscodeModal from '@modals/DocumentRequestsPasscodeModal';
import getRecentlyUsedPrices from '@tsClient/creators/getRecentlyUsedPrices';
import deserializeQueryStringItem from '@helpers/deserializeQueryStringItem';
import Head from 'next/head';
import teamsContext from '@providers/Teams/TeamsContext';
import ProgressIndicator from '@components/ProgressIndicator';
import { PWAContext } from '@providers/PWA';

import AgencyContext from '@providers/Agency/AgencyContext';
import SupportModal from '@modals/SupportModal';
import NotificationPermissionModal from '@modals/NotificationPermissionModal';
import WidgetSnippetModal from '@modals/WidgetSnippetModal';
import { isCreatorCountrySupported } from '@helpers/supported-creator-countries';
import ShareProductModal from '@modals/ShareProductModal';
import { SIZES } from '@styles/media';
import classNames from 'classnames';
import CreateServiceModal from '@modals/CreateServiceModal';
import { NoodleProductTypes } from '@typings/graphql-models';
import CreateCollectionModal from '@modals/CreateCollectionModal';
import CreateMembershipModal from '@components/CreateMembershipModal';
import GetNoodleApp, { APP_DISMISS_KEY } from '@lib/Creators/Dashboard/GetNoodleApp';
import InitiateCaseModal from '@modals/InitiateCaseModal';
import Panes from '@panes';

import TeamsModal from '@modals/TeamsModal';
import InitiateBookingModal from '@modals/InitiateBookingModal';
import TFAModal from '@/modals/TFAModal';
import useSocketContext from '@/providers/Socket/useSocketContext';
import { SocketMessageType } from '@/providers/Socket/SocketContext';
import { UserInboxUpdatedMessageData } from '@/typings/api-models';
import DashboardContext from './DashboardContext';
import DashboardIsDisabled from './DashboardIsDisabled';
import DashboardV3Navigation from './components/DashboardV3Navigation';
import s from './DashboardLayout.module.scss';

type RoutableCompany = Awaited<ReturnType<typeof getRoutableCompany>>;
type DashboardCreator = Awaited<ReturnType<typeof getCreatorProfile>>;
type Product = Awaited<ReturnType<typeof getCreatorProducts>>['products'][0];
type Collections = Awaited<ReturnType<typeof getCreatorCollections>>[0]['productTypes'];
type Price = Awaited<ReturnType<typeof getRecentlyUsedPrices>>[0];

const makeGetCreatorErrorToast: ToastErrorMessage = () => [ToastTypeVariants.ERROR, 'Failed to get creator profile'];
const makeGetCollectionErrorToast: ToastErrorMessage = () => [ToastTypeVariants.ERROR, 'Failed to get collections info'];
const makeGetProductsErrorToast: ToastErrorMessage = () => [ToastTypeVariants.ERROR, 'Failed to get product info'];
const makeGetPricesErrorToast: ToastErrorMessage = () => [ToastTypeVariants.ERROR, 'Failed to get price info'];

type Props = {
  tab: Parameters<typeof DashboardV3Navigation>[0]['currentTab'];
};

const DashboardLayout: Layout<Props> = ({ hasSubLayout = false, children, tab: currentTab }) => {
  const { currentTeamOwner, creatorId, teamUserId } = useContext(teamsContext);
  const { currentAgencyId } = useContext(AgencyContext);
  const { isInApp, installType } = useContext(PWAContext);

  const [user] = useUser();
  const profile = useUserProfile();
  const isUserInitialized = useIsUserInitialized();
  const [title, setTitle] = useState<string | null>(null);
  const [browserTabTitle, setBrowserTabTitle] = useState<string | null>(null);
  const [topContent, setTopContent] = useState<ReactNode>(null);
  const [titleContent, setTitleContent] = useState<ReactNode>(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState<boolean>(false);
  const [collections, setCollections] = useState<Collections | null>(null);
  const [creator, setCreator] = useState<DashboardCreator | null>(null);
  const [products, setProducts] = useState<Product[] | null>(null);
  const [prices, setPrices] = useState<Price[] | null>(null);
  const [backLink, setBackLink] = useState<string | null>(null);
  const [backFallbackLink, setBackFallbackLink] = useState<string | null>(null);
  const [routableCompany, setRoutableCompany] = useState<RoutableCompany | null>(null);
  const [isBroadcastModalOpen, setBroadcastModalOpen] = useState(false);
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [isTeamsModalOpen, setTeamsModalOpen] = useState(false);
  const [isDocumentRequestsPasscodeModalOpen, setDocumentRequestsPasscodeModalOpen] = useState(false);
  const [isWidgetSnippetModalOpen, setWidgetSnippetModalOpen] = useState(false);
  const [isSupportModalOpen, setIsSupportModalOpen] = useState(false);
  const [isTFAModalOpen, setIsTFAModalOpen] = useState(false);
  const [hasCreatedPrice, setHasCreatedPrice] = useState<boolean>(false);
  const [hasFinishedOnboardingStored, setHasFinishedOnboardingStored] = useState(false);
  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [isCreateServiceModalOpen, setIsCreateServiceModalOpen] = useState(false);
  const [isShareProductModalOpen, setIsShareProductModalOpen] = useState('');
  const [isCreateCollection, setCreateCollection] = useState(false);
  const [isMembershipCreation, setIsMembershipCreation] = useState(false);
  const [selectedCollectionId, setCollectionId] = useState<string>();
  const [isAddByteOpen, setIsAddByteOpen] = useState(false);

  const [isInitiateCaseModalOpen, setInitiateCaseModalOpen] = useState(false);
  const [isInitiateBookingModalOpen, setInitiateBookingModalOpen] = useState(false);
  const router = useRouter();

  const modalDismissedKey = 'creator_FTUE_modal';
  const notificationModalDismissedKey = 'creator_notifications_modal';
  const { dismissMessage, isMessageDismissed, isInitialized: isDismissedMessageInitialized } = useDismissedMessages();
  const [isFTUEModalOpen, setIsFTUEModalOpen] = useState(false);
  const [isAppModalOpen, setAppModalOpen] = useState(false);
  const modalDismissed = isMessageDismissed(modalDismissedKey);
  const isNotificationModalDismissed = isMessageDismissed(notificationModalDismissedKey);
  const hasFinishedOnboardingForced = deserializeQueryStringItem(router.query.hasFinishedOnboarding, {
    defaultValue: false,
    type: 'boolean',
  });

  const userId = user?.id;
  const creatorSlug = creator?.slug;
  const routableCompanyId = creator?.routableCompanyId;
  const isFetching = !creator;
  const isUserNotLoggedIn = isUserInitialized && !userId;
  const showShimmer = !isUserInitialized || isFetching;
  const showContents = !isUserNotLoggedIn && !showShimmer;
  const showTopContent = topContent && showContents;
  const isMobile = useIsMobile(SIZES.xl);
  const showV3 = !isMobile;
  const isDashboardPreview = router.route === IDENTIFIERS.DASHBOARD_PREVIEW;
  const isDashboardDisabled = !isCreatorCountrySupported(creator?.countryCode || null);
  const hasLeftNav = !isMobile;

  const { addListener, removeListener, isInitialized: isSocketInitialized, joinChannels } = useSocketContext();

  useEffect(() => {
    if (isSocketInitialized && userId && creatorId) {
      joinChannels([`inbox/${creatorId}-${userId}`]);
    }
  }, [joinChannels, userId, creatorId, isSocketInitialized]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `${SELF_ORIGIN}/widget.js`;
    script.defer = true;
    document.head.appendChild(script);
    return (): void => {
      document.head.removeChild(script);
    };
  }, []);

  const { data: fetchedCreator, getData: getCreatorProfileFn } = useNoodleApi(getCreatorProfile, {
    toastErrorMessage: makeGetCreatorErrorToast,
    toastOnError: true,
  });

  const { getData: getRoutableCompanyFn } = useNoodleApi(getRoutableCompany);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.setProperty('--width-container', '100%');
      body.style.setProperty('--color-primary', 'var(--color-noodle)');
      return () => {
        body.style.removeProperty('--width-container');
        body.style.removeProperty('--color-primary');
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    const getCompany = async (): Promise<void> => {
      if (creatorSlug && routableCompanyId && !routableCompany) {
        const { data: company } = await getRoutableCompanyFn({ companyId: routableCompanyId, creatorSlug });
        setRoutableCompany(company);
      }
    };
    getCompany();
  }, [creatorSlug, getRoutableCompanyFn, creator, routableCompany, routableCompanyId]);

  const { data: fetchedCollections, getData: getCollectionsFn } = useNoodleApi(getCreatorCollections, {
    toastErrorMessage: makeGetCollectionErrorToast,
    toastOnError: true,
  });

  const { data: fetchedProducts, getData: getCreatorProductsFn } = useNoodleApi(getCreatorProducts, {
    toastErrorMessage: makeGetProductsErrorToast,
    toastOnError: true,
  });

  const { data: fetchedPrices, getData: getRecentlyUsedPricesFn } = useNoodleApi(getRecentlyUsedPrices, {
    toastErrorMessage: makeGetPricesErrorToast,
    toastOnError: true,
  });

  const { getData: updateCreatorFn } = useNoodleApi(updateCreator);

  const broadcastProducts = useMemo(() => products?.filter(p => p.includesBroadcasts), [products]);

  const { data: tiers, getData: getTiersFn } = useNoodleApi(getProductTiers);

  const {
    getData: getAccounts,
    data: accounts,
  } = useNoodleApi(tsClient.integrations.listCreatorAccounts);

  const {
    getData: getCreatorInboxItemsUnreadCountFn,
    data: unreadInboxItemCount,
  } = useNoodleApi(tsClient.creators.getCreatorInboxItemsUnreadCount);

  useEffect(() => {
    if (creator) {
      getAccounts({ creatorId: creator.id, page: 1, perPage: 1000 });
    }
  }, [creator]);

  useEffect(() => {
    if (broadcastProducts?.[0]?.id) {
      getTiersFn({ id: broadcastProducts?.[0]?.id });
    }
  }, [broadcastProducts, getTiersFn]);

  useEffect(() => {
    if (modalDismissed) {
      setIsFTUEModalOpen(false);
    }
  }, [modalDismissed, setIsFTUEModalOpen]);

  useEffect(() => {
    if (!isNotificationModalDismissed && isDismissedMessageInitialized && FEATURE_FLAG_PUSH_NOTIFICATIONS_ENABLED && isInApp) {
      setNotificationModalOpen(true);
    }
  }, [isNotificationModalDismissed, isDismissedMessageInitialized, isInApp]);

  useEffect(() => {
    if (creatorId && user?.id && !user.isAnonymous) {
      getCreatorProfileFn({ creatorId });
      getCreatorInboxItemsUnreadCountFn({ creatorId });
    } else {
      setCreator(null);
    }
  }, [creatorId, user, getCreatorProfileFn, getCreatorInboxItemsUnreadCountFn]);

  useEffect(() => {
    setCreator(fetchedCreator);
  }, [fetchedCreator]);

  useEffect(() => {
    const isWindows = /Win/.test(navigator.userAgent);
    if (isWindows) {
      document.body.classList.add('hide-scrollbar');
    }
    return () => {
      document.body.classList.remove('hide-scrollbar');
    };
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
      event.preventDefault();
      setTeamsModalOpen(true);
    }
  }, [setTeamsModalOpen]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const refetchCreator = async (): Promise<Awaited<ReturnType<typeof tsClient.creators.getCreatorProfile>> | null> => {
    if (creatorId) {
      const { data } = await getCreatorProfileFn({ creatorId });
      return data;
    }
    return null;
  };

  const refetchUnreadInboxItemCount = useCallback(async (): Promise<void> => {
    if (creatorId) {
      await getCreatorInboxItemsUnreadCountFn({ creatorId });
    }
  }, [creatorId, getCreatorInboxItemsUnreadCountFn]);

  // Prevent folks that have completed onboarding, but hygraph read hasn't caught up to the write yet, from going back to onboarding.
  // Need to hold hasFinishedOnboarding in state.
  // This is so that some component can remove hasFinishedOnboarding from the query string without then triggering redirecting to /verify-noodle
  // Can't just use state though becuase there is a render between the qs being parsed and hasFinishedOnboardingStored being set.
  const hasFinishedOnboarding = !creator || creator.hasFinishedOnboarding || hasFinishedOnboardingForced || hasFinishedOnboardingStored;
  useEffect(() => {
    if (hasFinishedOnboardingForced) {
      setHasFinishedOnboardingStored(true);
    }
  }, [hasFinishedOnboardingForced]);
  useEffect(() => {
    if (!hasFinishedOnboarding) {
      mixpanelTrack('Visited dashboard without completing onboarding');
      router.push(getUrl(IDENTIFIERS.CREATOR_ONBOARDING_START_INITIAL_PRODUCT_CREATION));
    }
  }, [hasFinishedOnboarding]);

  const fetchCollections = useCallback(async () => {
    if (creatorSlug) {
      await getCollectionsFn({ creatorSlug });
    } else {
      setCollections(null);
    }
  }, [creatorSlug, getCollectionsFn]);

  const fetchProducts = useCallback(async () => {
    if (creatorSlug) {
      await getCreatorProductsFn({ creatorSlug, isOmitFilterByActive: true });
    } else {
      setProducts(null);
    }
  }, [creatorSlug, getCreatorProductsFn]);

  const fetchPrices = useCallback(async () => {
    if (user && creatorId) {
      await getRecentlyUsedPricesFn({ creatorId });
    } else {
      setPrices(null);
    }
  }, [user, creatorId, getRecentlyUsedPricesFn]);

  useEffect(() => {
    fetchCollections();
  }, [fetchCollections]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    fetchPrices();
  }, [fetchPrices]);

  useEffect(() => {
    if (fetchedCollections) {
      setCollections(fetchedCollections[0].productTypes);
    } else {
      setCollections(null);
    }
  }, [fetchedCollections]);

  useEffect(() => {
    if (fetchedProducts) {
      setProducts(fetchedProducts.products);
    } else {
      setProducts(null);
    }
  }, [fetchedProducts]);

  useEffect(() => {
    if (fetchedPrices) {
      setPrices(fetchedPrices);
    } else {
      setPrices(null);
    }
  }, [fetchedPrices]);

  useEffect(() => {
    if (creator && user && !user.isOnBehalfOf) {
      updateCreatorFn({
        creatorId: creator.id,
        lastViewedDashboardAt: new Date().toISOString(),
      });
    }
  }, [user, creator, updateCreatorFn]);

  const dismissModal = async (): Promise<void> => {
    await dismissMessage(modalDismissedKey);
  };

  const handleClose = (isOpen: boolean): void => {
    setIsFTUEModalOpen(isOpen);
    dismissModal();
  };

  const onCreateService = async (pSlug: string, cSlug: string, productType: string): Promise<void> => {
    const productQuery = productType === NoodleProductTypes.Handbook
      ? { byteModal: true, creatorSlug: cSlug, productSlug: pSlug }
      : { creatorSlug: cSlug, productSlug: pSlug };
    if (productType === NoodleProductTypes.Handbook
      || productType === NoodleProductTypes.Collection
      || productType === NoodleProductTypes.Subscription
    ) {
      router.push(getUrl(IDENTIFIERS.PRODUCT, productQuery));
    } else {
      router.push(getUrl(IDENTIFIERS.DASHBOARD_SERVICES_DETAILS, { productSlug: pSlug }));
    }
  };

  const handleModals = (type: string, passedTitle?: string): void => {
    setIsCreateServiceModalOpen(false);
    if (type === NoodleProductTypes.Collection && passedTitle) {
      setCreateCollection(true);
      setTitle(passedTitle);
    } else if (type === NoodleProductTypes.Subscription && passedTitle) {
      setIsMembershipCreation(true);
      setTitle(passedTitle);
    }
  };

  const handleCloseNotificationModal = (): void => {
    setNotificationModalOpen(false);
    dismissMessage(notificationModalDismissedKey);
  };

  const handlePaymentLinkModalClose = (): void => {
    setPaymentModalOpen(false);
    if (hasCreatedPrice) {
      fetchPrices();
    }
    setHasCreatedPrice(false);
  };

  const currentPath = useRef('');
  currentPath.current = router.asPath;

  useEffect(() => {
    const handleRouteChange = (url: string): void => {
      const newPathName = url.replace(/\?.*/, '');
      const oldPathName = currentPath.current.replace(/\?.*/, '');
      if (newPathName !== oldPathName) {
        setTopContent(null);
        setTitleContent(null);
        setTitle(null);
        setBrowserTabTitle(null);
        setBackLink(null);
        setBackFallbackLink(null);
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [router.events]);

  const creatorAvatarData = teamUserId === currentTeamOwner?.person?.id
    ? {
      color: creator?.primaryColour?.hex,
      image: creator?.person?.image?.url,
      name: creator?.person?.name,
    }
    : {
      color: profile?.primaryColour?.hex,
      image: profile?.image?.url,
      name: profile?.name,
    };

  useEffect(() => {
    if (user && !user.isCreator) {
      router.push(getUrl(IDENTIFIERS.CREATOR_ONBOARDING_CLAIM_SLUG));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [isPullToRefresh, setIsPullToRefresh] = useState(false);
  const { scrollY } = useViewportScroll();

  useEffect(() => {
    if (isPullToRefresh) {
      refetchCreator();
      setTimeout(() => {
        setIsPullToRefresh(false);
      }, 2000);
    }
  }, [isPullToRefresh]);

  const triggerScrollPosition = useTransform(scrollY, [0, -100], [-100, 0]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body && isInApp) {
      body.className = s.appMimicry;
      body.style.overflowY = 'auto';
    }
  }, [isInApp]);

  const Wrapper = showV3 ? 'div' : Fragment;

  const showGetTheApp = !isInApp && installType !== 'unsupported' && isDismissedMessageInitialized && !isMessageDismissed(APP_DISMISS_KEY) && isMobile;

  const [refetchBookingsRefreshKey, setRefetchBookingsRefreshKey] = useState(0);
  const incrementRefetchBookingsRefreshKey = useCallback(() => {
    setRefetchBookingsRefreshKey(currVal => currVal + 1);
  }, [setRefetchBookingsRefreshKey]);

  useEffect(() => {
    const handleInboxStatusChange = async (data: unknown): Promise<void> => {
      if (!(data as UserInboxUpdatedMessageData).isInboxItemRead) {
        refetchUnreadInboxItemCount();
      }
    };

    const listenerId = addListener({
      fn: handleInboxStatusChange,
      messageType: SocketMessageType.USER_INBOX_UPDATED,
    });

    return () => {
      removeListener(listenerId);
    };
  }, [addListener, removeListener, refetchUnreadInboxItemCount]);

  const contextValue = useMemo(
    () => ({
      backFallbackLink,
      backLink,
      collections,
      creator,
      fetchCollections,
      fetchPrices,
      fetchProducts,
      isCreateServiceModalOpen,
      isInDashboard: true,
      isInitiateBookingModalOpen,
      isPaymentModalOpen,
      isShareProductModalOpen,
      isTeamsModalOpen,
      isUserModalOpen,
      prices,
      products,
      refetchBookingsRefreshKey,
      refetchCreator,
      refetchUnreadInboxItemCount,
      routableCompany,
      setAppModalOpen,
      setBackFallbackLink,
      setBackLink,
      setBrowserTabTitle,
      setDocumentRequestsPasscodeModalOpen,
      setInitiateBookingModalOpen,
      setInitiateCaseModalOpen,
      setIsCreateServiceModalOpen,
      setIsShareProductModalOpen,
      setIsTFAModalOpen,
      setIsUserModalOpen,
      setNotificationModalOpen,
      setPaymentModalOpen,
      setTeamsModalOpen,
      setTitle,
      setTitleContent,
      setTopContent,
      setWidgetSnippetModalOpen,
      title,
      topContent,
      triggerRefetchBooking: incrementRefetchBookingsRefreshKey,
    }),
    [
      backFallbackLink,
      backLink,
      collections,
      creator,
      fetchCollections,
      fetchPrices,
      fetchProducts,
      incrementRefetchBookingsRefreshKey,
      isCreateServiceModalOpen,
      isPaymentModalOpen,
      isTeamsModalOpen,
      isInitiateBookingModalOpen,
      isShareProductModalOpen,
      isUserModalOpen,
      prices,
      products,
      refetchBookingsRefreshKey,
      refetchCreator,
      refetchUnreadInboxItemCount,
      routableCompany,
      setIsTFAModalOpen,
      setAppModalOpen,
      setBackFallbackLink,
      setBackLink,
      setBrowserTabTitle,
      setDocumentRequestsPasscodeModalOpen,
      setIsCreateServiceModalOpen,
      setIsShareProductModalOpen,
      setIsUserModalOpen,
      setNotificationModalOpen,
      setPaymentModalOpen,
      setTeamsModalOpen,
      setTitle,
      setTitleContent,
      setTopContent,
      setWidgetSnippetModalOpen,
      title,
      topContent,
    ],
  );
  return (
    <LoggedInUserGate isAnonymousAllowed={false}>
      {isDashboardDisabled
        ? (
          <DashboardIsDisabled />
        )
        : (
          <DashboardContext.Provider value={contextValue}>
            <LogRocket />
            <Head>
              <title>{browserTabTitle || 'Dashboard'}</title>
            </Head>
            {isInApp && (
              <>
                <m.div
                  className={s.pullToRefreshTrigger}
                  style={{ y: triggerScrollPosition }}
                  onViewportEnter={() => {
                    setIsPullToRefresh(true);
                  }}
                />
                <m.div className={s.pullToRefresh} initial={{ y: -80 }} animate={{ y: isPullToRefresh ? 60 : -80 }}>
                  <ProgressIndicator />

                </m.div>
              </>
            )}
            <JobsContainer />
            <Wrapper {...(showV3 ? { className: (hasSubLayout && s.wrapperV3WithSubLayout) || s.wrapperV3 } : {})}>
              {showGetTheApp && <GetNoodleApp onClick={() => setAppModalOpen(!isUserModalOpen)} />}
              <DashboardV3Navigation
                hasLeftNav={hasLeftNav}
                setIsSupportModalOpen={setIsSupportModalOpen}
                avatar={creatorAvatarData}
                currentTab={currentTab}
                unreadInboxItemCount={unreadInboxItemCount?.count ?? 0}
              />

              <div className={classNames(s.layout, !hasSubLayout && s.layout__noodlePrimaryColor)}>
                <Panes hasLeftNav={hasLeftNav} />
                <div
                  className={s[(isDashboardPreview && `layout__main-wrap-preview`) || `layout__main-wrap`]}
                  style={(hasSubLayout && { padding: 0 }) || {}}
                >
                  {(showShimmer || showTopContent || titleContent) && (
                    <div className={s.layout__top}>
                      <div className={s['layout__top-wrapper']}>
                        {titleContent && <div className={s['layout__top-wrapper-heading']}>{titleContent}</div>}
                        {showTopContent && <div className={s['layout__top-content']}>{topContent}</div>}
                      </div>
                    </div>
                  )}
                  <div className={s['layout__content-wrapper']}>
                    {showShimmer && (
                      <CreatorDashboardShimmer
                        items={
                          (router.pathname === IDENTIFIERS.DASHBOARD && [
                            'Fetching products...',
                            'Calculating earnings...',
                            'Receiving messages...',
                            'Fetching members...',
                            'Building interface...',
                          ])
                        || (router.pathname === IDENTIFIERS.DASHBOARD_CONVERSATIONS && [
                          'Fetching messages...',
                          'Receiving members data...',
                          'Prioritizing...',
                          'Building interface...',
                        ])
                        || undefined
                        }
                      />
                    )}
                    {showContents && <>{children}</>}
                    {isMobile && <Spacer size="96px" />}
                  </div>
                  {isFTUEModalOpen && !modalDismissed && creator && (
                    <DashboardFTUEModal setModal={handleClose} isArtist={creator.businessCategory === 'musician-artist'} />
                  )}
                  {isNotificationModalOpen && <NotificationPermissionModal closeModal={handleCloseNotificationModal} />}
                  {isUserModalOpen && <DashboardUserModal
                    has2FA={accounts?.items ? accounts.items.length > 0 : false}
                    closeModal={() => setIsUserModalOpen(false)}
                    hasAgency={Boolean(currentAgencyId)}
                  />}
                  {creator?.slug && isBroadcastModalOpen && (
                    <CreateEditBroadcastModal
                      setIsVisible={setBroadcastModalOpen}
                      onPublish={async () => {
                        setBroadcastModalOpen(false);
                      }}
                      creatorSlug={creator?.slug}
                      productSlug={broadcastProducts?.[0]?.slug}
                      broadcastTiers={tiers}
                      isCommunityPost={false}
                    />
                  )}
                  {isPaymentModalOpen && creator?.slug && prices && products && (
                    <PaymentLinkModal
                      creator={creator}
                      creatorSlug={creator.slug}
                      product={products[0]}
                      onClose={handlePaymentLinkModalClose}
                      handleCreatedPrice={() => setHasCreatedPrice(true)}
                      recentlyUsedPrices={prices}
                      creatorProducts={products}
                      onCreate={setIsCreateServiceModalOpen}
                      onShare={setIsShareProductModalOpen}
                    />
                  )}
                  {isTeamsModalOpen && creator?.slug && prices && products && (
                    <TeamsModal
                      onClose={() => setTeamsModalOpen(false)}
                      onChange={() => setTeamsModalOpen(false)}
                    />
                  )}
                  {isWidgetSnippetModalOpen && creator?.slug && (
                    <WidgetSnippetModal
                      creatorSlug={creator.slug}
                      creatorColor={creator.primaryColour?.hex || ''}
                      creatorImage={creator.person.image?.url}
                      onClose={() => setWidgetSnippetModalOpen(false)}
                    />
                  )}
                  {isSupportModalOpen && <SupportModal onClose={() => setIsSupportModalOpen(false)} />}
                  {isTFAModalOpen && <TFAModal onClose={() => setIsTFAModalOpen(false)} />}
                  {isAppModalOpen && <GetNoodleAppModal setIsVisible={setAppModalOpen} />}
                  {isDocumentRequestsPasscodeModalOpen && (
                    <DocumentRequestsPasscodeModal creatorId={creator?.id || ''} setIsVisible={setDocumentRequestsPasscodeModalOpen} />
                  )}
                  {isShareProductModalOpen && (
                    <ShareProductModal
                      creator={{
                        image: creator?.person.image?.url || '',
                        name: creator?.name || '',
                        primaryColour: {
                          hex: creator?.primaryColour?.hex || '',
                        },
                      }}
                      creatorSlug={creator?.slug || ''}
                      productSlug={isShareProductModalOpen}
                      onClose={() => setIsShareProductModalOpen('')}
                    />
                  )}

                  {isCreateServiceModalOpen && creator?.id && (
                    <CreateServiceModal
                      businessCategory={creator?.businessCategory}
                      creator={creator}
                      setIsVisible={setIsCreateServiceModalOpen}
                      onPublish={onCreateService}
                      handleModals={(type: string, passedTitle?: string) => handleModals(type, passedTitle)}
                      initialProductType={NoodleProductTypes.Consultation}
                    />
                  )}
                  {isCreateCollection && (
                    <CreateCollectionModal
                      collectionId={selectedCollectionId}
                      onClose={() => {
                        setCollectionId(undefined);
                        setCreateCollection(false);
                        setIsAddByteOpen(false);
                      }}
                      isAddByteOpen={isAddByteOpen}
                      refetchCollection={fetchCollections}
                      title={title || 'Create a collection'}
                    />
                  )}
                  {isMembershipCreation && <CreateMembershipModal setIsVisible={setIsMembershipCreation} title={title || 'Create a membership'} />}
                  {isInitiateCaseModalOpen && creatorId && creator?.slug && (
                    <InitiateCaseModal
                      creatorId={creatorId}
                      creatorSlug={creator.slug}
                      onClose={() => setInitiateCaseModalOpen(false)}
                      products={products}
                    />
                  )}
                  {isInitiateBookingModalOpen && creatorId && creator?.slug && (
                    <InitiateBookingModal
                      creatorSlug={creator.slug}
                      creatorTimezone={creator.timezone}
                      onClose={() => setInitiateBookingModalOpen(false)}
                      products={products}
                    />
                  )}
                </div>
              </div>
            </Wrapper>
          </DashboardContext.Provider>
        )}
    </LoggedInUserGate>
  );
};

export default DashboardLayout;
