import Question from '@components/Icons/Question';
import UserCircle from '@components/Icons/UserCircle';
import Buildings from '@components/Icons/Buildings';
import Fingerprint from '@components/Icons/Fingerprint';
import { FC, useContext } from 'react';
import { AuthContext } from '@/providers/Auth';
import Buttons from '@/components/Buttons';
import Modal from '@components/Modal';
import { useIsMobile } from '@hooks';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import CustomLink from '@components/CustomLink';
import { NOODLE_CREATOR_SLUG , VERSION } from '@configuration/client';
import { PWAContext } from '@providers/PWA';
import RoleSwitcher from '@components/RoleSwitcher';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import { useFeatureFlag } from '@/providers/FeatureFlags';
import s from './DashboardUserModal.module.scss';

type Props = {
  closeModal: () => void;
  hasAgency: boolean;
  has2FA: boolean;
};

const PAGES = [
  {
    icon: <UserCircle size={24} weight="fill" />,
    isNewTab: false,
    label: 'Your Account',
    to: getUrl(IDENTIFIERS.DASHBOARD_ACCOUNT),
  },
  {
    icon: <Question size={24} weight="fill" />,
    isNewTab: true,
    label: 'Need Help?',
    to: getUrl(IDENTIFIERS.DASHBOARD_CHAT_WITH_CREATOR, { creatorSlug: NOODLE_CREATOR_SLUG }),
  },
];

const DashboardUserModal: FC<Props> = ({ closeModal, hasAgency, has2FA }) => {
  const isMobile = useIsMobile();
  const { isInApp } = useContext(PWAContext);
  const { profile } = useContext(AuthContext);
  const { setIsTFAModalOpen } = useContext(DashboardContext);
  const canShowIntegrations = useFeatureFlag("integrations");

  return (
    <Modal onClose={closeModal} zIndex={20} hideBottomInset>
      <div className={s.roleSwitcher}>
        <RoleSwitcher closeModal={closeModal} />
      </div>
      <ul className={s.wrapper} style={(isInApp && isMobile) ? { marginBottom: 24 } : undefined}>
        {PAGES.map(p => (
          <li key={p.label}>
            <CustomLink to={p.to} onClick={closeModal} newTab={p.isNewTab}>
              {p.icon}
              {p.label}
            </CustomLink>
          </li>
        ))}
        {canShowIntegrations && has2FA && <li>
          <Buttons isWrapper onClick={() => {
            setIsTFAModalOpen(true);
            closeModal();
          }}>
            <Fingerprint size={24} weight="fill" />
            2FA Codes
          </Buttons>
        </li>}
        {hasAgency && (
          <li key={'Your Agency'}>
            <CustomLink to={getUrl(IDENTIFIERS.AGENCY_DASHBOARD)} onClick={closeModal} newTab={false}>
              <Buildings size={24} weight="fill" />
              Your Agency
            </CustomLink>
          </li>
        )}
      </ul>
      {(profile || VERSION) && <div className={s.loggedIn}>
        {profile && <p className='caption'><span>Logged in as</span> {profile.email}</p>}
        {VERSION && <p className='caption'>Version: {VERSION}</p>}
      </div>}
    </Modal>
  );
};

export default DashboardUserModal;
