import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Plus from '@components/Icons/Plus';
import { HookResponse, PAGE_SIZE, ResourceType } from './common';

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_TERMS_TEMPLATES_EDIT, { id });

const useTermsTemplates = ({
  creatorId,
  search,
  resourceType,
}: {
  creatorId: string | undefined;
  search: string | null;
  resourceType: ResourceType;
}): HookResponse & {
  createTermsModalOpen: boolean;
  setCreateTermsModalOpen: (v: boolean) => void;
} => {
  const [createTermsModalOpen, setCreateTermsModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const pathParams = useParams<{ id?: string }>();
  const [items, setItems] = useState<HookResponse['items']>([]);
  const mostRecentRequestRef = useRef<string | null>(null);

  const { getData: getCreatorTermsTemplatesFn } = useNoodleApi(tsClient.customTerms.getCreatorTermsTemplates);

  const selectedId = pathParams?.id;

  const loadPage = useCallback(async ({ page, search: newSearch }: {search: string | null, page: number }): Promise<void> => {
    if (creatorId && resourceType === 'custom-terms') {
      setCurrentPage(page);
      const thisRequestId = nanoid();
      mostRecentRequestRef.current = thisRequestId;

      setIsFetching(true);
      const response = await getCreatorTermsTemplatesFn({
        creatorId,
        page,
        perPage: PAGE_SIZE,
        search: newSearch ?? undefined,
      });

      if (mostRecentRequestRef.current === thisRequestId) {
        if (response.data) {
          setItems(response.data.items.map(template => ({
            href: getUrl(IDENTIFIERS.DASHBOARD_TERMS_TEMPLATES_EDIT, { id: template.id }),
            id: template.id,
            label: template.title || template.id,
          })));
          setNumPages(response.data.numPages);
        }
        setCreateTermsModalOpen(false);
        setIsFetching(false);
      }
    } else {
      setItems([]);
    }
  }, [
    creatorId,
    resourceType,
    getCreatorTermsTemplatesFn,
  ]);

  useEffect(() => {
    loadPage({ page: 1, search });
  }, [loadPage, search]);

  const headerActions = [{
    icon: Plus,
    label: 'Create Custom Terms Template',
    onClick: () => setCreateTermsModalOpen(true),
  }];

  const pagination: HookResponse['pagination'] = {
    numPages,
    onChangePage: (newPage) => {
      loadPage({ page: newPage , search});
    },
    page: currentPage,
  };

  return {
    createTermsModalOpen,
    getDetailPagePath,
    headerActions,
    ifNoItemsText: 'No custom terms requests yet',
    isFetchingList: isFetching,
    items,
    pagination,
    reloadList: () => loadPage({ page: 1, search }),
    selectedId,
    setCreateTermsModalOpen,
  };
};

export default useTermsTemplates;
