import { useParams } from 'next/navigation';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import Plus from '@components/Icons/Plus';

const Scripts: React.FC = ({ children }) => {
  const pathParams = useParams<{ id?: string }>();
  const isOneColumn = useIsMobile(SIZES.md);
  const selectedId = pathParams?.id;

  return (
    <ListAndDetailsSubLayout
      header={{
        actions: [
          {
            href: "#",
            icon: Plus,
            label: 'Create new script',
          },
        ],
        title: "Scripts",
      }}
      items={[
        {
          href: getUrl(IDENTIFIERS.DASHBOARD_SCRIPT, { id:'family-based' }),
          id: 'family-based',
          label: 'Family Based Green Card',
        },
        {
          href: getUrl(IDENTIFIERS.DASHBOARD_SCRIPT, { id:'h1b-petition' }),
          id: 'h1b-petition',
          label: 'H1B Petition',
        },
      ]}
      selectedId={selectedId}
      ifNoItemsText={'No Scripts yet'}
      isOneColumn={isOneColumn}
      getDetailPagePath={({ id }) => getUrl(IDENTIFIERS.DASHBOARD_SCRIPT, { id })}
      resourceType={'scripts'}
    >
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Scripts;
