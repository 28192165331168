import { FC } from 'react';
import AssignCollaboratorsToAttachmentForm from '@components/AssignCollaboratorsToAttachmentForm';
import * as ApiModels from '@typings/api-models';
import ProgressIndicator from '@components/ProgressIndicator';
import Spacer from '@components/Spacer';

type Props = {
  ownerIds: string[];
  onOwnerSelect: (newIds: string[]) => void;
  teamMembers?: Pick<ApiModels.TeamMember, 'id' | 'name' | 'email' | 'image' | 'primaryColour'>[];
};

const AssignOwners: FC<Props> = ({ teamMembers, ownerIds, onOwnerSelect }) => {

  if (!teamMembers) {
    return (
      <>
        <Spacer size={48} />
        <ProgressIndicator isCentered />
      </>
    );
  }

  return (
    <AssignCollaboratorsToAttachmentForm
      collaborators={teamMembers?.map((m) => ({
        color: m.primaryColour?.hex || null,
        email: m.email || null,
        id: m.id,
        logo: m.image?.url || null,
        name: m.name || null,
      }))}
      isSubmitting={false}
      onSubmit={onOwnerSelect}
      initialCollaboratorIds={ownerIds}
    />
  );
};

export default AssignOwners;
