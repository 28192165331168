import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'next/navigation';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import TeamsContext from '@providers/Teams/TeamsContext';
import useNoodleApi from '@hooks/useNoodleApi';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import * as tsClient from '@tsClient';

type Tabs = 'all' | 'subscribed_members' | 'free_members' | 'unverified_contacts';

const PAGE_SIZE = 25;
const TABS: Array<{ key: Tabs; label: string }> = [
  {
    key: 'all',
    label: 'All',
  },
  {
    key: 'subscribed_members',
    label: 'Subscribed',
  },
  {
    key: 'free_members',
    label: 'Free',
  },
  {
    key: 'unverified_contacts',
    label: 'Unverified',
  },
];

const Members: React.FC = ({ children }) => {
  const pathParams = useParams<{ id?: string }>();
  const isOneColumn = useIsMobile(SIZES.md);
  const { currentTeamOwner } = useContext(TeamsContext);
  const [searchTerms, setSearchTerms] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<Tabs>('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [members, setMembers] = useState<Awaited<ReturnType<typeof tsClient.getMembers>>['items']>([]);
  const [numMembers, setNumMembers] = useState<number | undefined>(undefined);
  const { getData: getMembersFn } = useNoodleApi(tsClient.getMembers);

  const creatorSlug = currentTeamOwner?.slug;
  const selectedId = pathParams?.id;

  const loadPage = useCallback(async ({
    page,
    search,
    filter,
  }: {
    page: number;
    search?: string;
    filter: Tabs;
  }): Promise<void> => {
    setCurrentPage(page);
    if (creatorSlug) {
      setIsFetching(true);
      const getResponse = await getMembersFn({
        creatorSlug,
        filter,
        page,
        perPage: PAGE_SIZE,
        search,
      });
      if (getResponse.data) {
        setMembers(getResponse.data.items);
        setNumPages(getResponse.data.numPages);
        setNumMembers(getResponse.data.numItems);
      }
      setIsFetching(false);
    }
  }, [creatorSlug, getMembersFn]);

  const handlePageChange = (newPage: number): void => {
    loadPage({
      filter: selectedTab,
      page: newPage,
      search: searchTerms ?? undefined,
    });
  };
  useEffect(() => {
    if (creatorSlug) {
      loadPage({
        filter: selectedTab,
        page: 1,
        search: searchTerms ?? undefined,
      });
    }
  }, [loadPage, creatorSlug, searchTerms, selectedTab]);

  const items = useMemo<Parameters<typeof ListAndDetailsSubLayout>[0]['items']>(() => members.map(member => ({
    avatar: {
      color: member.primaryColour?.hex || undefined,
      name: member.name || '',
      url: member.image?.url || undefined,
    },
    bottomLine: member.email,
    href: getUrl(IDENTIFIERS.DASHBOARD_MEMBER, { id:  member.id }),
    id: member.id,

    isSelected: member.id === selectedId,
    label: member.name || 'No name',
  })), [members, selectedId]);

  return (
    <ListAndDetailsSubLayout
      header={{
        tabs: TABS.map(t => ({
          label: t.label,
          onClick: () => setSelectedTab(t.key),
          selected: selectedTab === t.key,
        })),
        title: "Contacts",
      }}
      items={items}
      itemsCount={numMembers && numMembers > 0 ? numMembers : undefined}
      selectedId={selectedId}
      ifNoItemsText={'No Contacts yet'}
      isFetchingList={isFetching}
      isOneColumn={isOneColumn}
      getDetailPagePath={({ id }) => getUrl(IDENTIFIERS.DASHBOARD_MEMBER, { id })}
      onSearch={setSearchTerms}
      resourceType={'members'}
      pagination={{
        numPages,
        onChangePage: handlePageChange,
        page: currentPage,
      }}
    >
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Members;
