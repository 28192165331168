import { FC } from 'react';
import Buttons from '@components/Buttons';
import { m } from 'framer-motion';
import XCircle from '@components/Icons/XCircle';
import useDismissedMessages from '@providers/Messages/useDismissedMessages';
import s from './GetNoodleApp.module.scss';

interface Props {
  onClick: () => void;
}

export const APP_DISMISS_KEY = 'creator-dashboard-web-app';

export const NoodleIconWithBadge: FC<{ isInline?: boolean, showBadge?: boolean }> = ({ isInline, showBadge = true }) => (
  <div className={isInline ? s.logoInline : s.logo}>
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.21875 0C1.8888 0 0 1.8888 0 4.21875V49.7812C0 52.1112 1.8888 54 4.21875 54H49.7812C52.1112 54 54 52.1112 54 49.7812V4.21875C54 1.8888 52.1112 0 49.7812 0H4.21875ZM17.6528 10.27C20.8722 10.27 23.4832 12.8778 23.4832 16.0972C23.4832 19.3166 20.8722 21.9276 17.6528 21.9276C14.4334 21.9276 11.8224 19.3166 11.8224 16.0972C11.8224 12.8778 14.4334 10.27 17.6528 10.27ZM35.8231 10.27C39.0425 10.27 41.6502 12.8778 41.6502 16.0972C41.6502 19.3166 39.0425 21.9276 35.8231 21.9276C32.6037 21.9276 29.9927 19.3166 29.9927 16.0972C29.9927 12.8778 32.6037 10.27 35.8231 10.27ZM8.23644 28.6249H45.7636C45.7636 38.9877 37.3629 47.3917 27 47.3917C16.6371 47.3917 8.23644 38.9878 8.23644 28.6249Z"
        fill="url(#paint0_linear_2360_1361)"
      />
      <defs>
        <linearGradient id="paint0_linear_2360_1361" x1="27" y1="-1.87755e-06" x2="90" y2="193.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
    {showBadge && <m.div
      initial={{ scale: 0 }}
      animate={{
        scale: 1,
        transition: {
          delay: 2,
        },
      }}
      className={s.badge}
    >
      1
    </m.div>}
  </div>
);

const GetNoodleApp: FC<Props> = ({ onClick }) => {
  const { dismissMessage, isMessageDismissed, isInitialized } = useDismissedMessages();

  if (isMessageDismissed(APP_DISMISS_KEY) || !isInitialized) return null;
  return (
    <m.div
      className={s.getNoodleApp}
      initial={{ opacity: 0, y: -40 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 1,
          type: 'spring',
        },
        y: 0,
      }}
    >
      <Buttons
        isWrapper
        ariaLabel="Dismiss"
        onClick={() => {
          dismissMessage(APP_DISMISS_KEY);
        }}
      >
        <XCircle weight="fill" size={24} />
      </Buttons>
      <NoodleIconWithBadge isInline />
      <h1>Install the Noodle app</h1>
      <Buttons isFullWidth onClick={onClick}>
        Get the Noodle app
      </Buttons>
    </m.div>
  );
};

export default GetNoodleApp;
