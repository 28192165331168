import React, { ReactElement, useEffect, FC } from 'react';
import LogRocketInstance from 'logrocket';
import { VERSION, LOG_ROCKET_APP_ID } from '@configuration/client';
import { useUser } from '@hooks';

declare const window: Window &
typeof globalThis & {
  LRLoggerInitialized?: boolean;
};

const LogRocket: FC = (): ReactElement => {
  const [user] = useUser();

  useEffect(() => {
    if (
      LOG_ROCKET_APP_ID
      && window !== undefined
      // don't re-initialize
      && !Object.prototype.hasOwnProperty.call(window, 'LRLoggerInitialized')
      // record if the user is anonymous or if it's the real user (ie not on behalf of through a dummy phone number)
      && (!user || !user.isOnBehalfOf)
    ) {
      window.LRLoggerInitialized = true;
      LogRocketInstance.init(LOG_ROCKET_APP_ID, {
        release: VERSION,
      });
    }

    if (user?.id && LOG_ROCKET_APP_ID) {
      LogRocketInstance.identify(user.id);
    }
  }, [user]);

  return (<></>);
};

export default LogRocket;
