import { useState } from 'react';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import useInboxRequest from './useInboxRequests';

const Inbox: React.FC = ({ children }) => {
  const isOneColumn = useIsMobile(SIZES.md);
  const [search, setSearch] = useState<string | null>(null);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);

  const inboxRequestData = useInboxRequest({
    search,
    types: selectedTypes,
  });

  const handleSearch = (newSearch: string): void => {
    setSearch(newSearch ? newSearch.toLowerCase() : null);
  };

  const reloadListView = async (): Promise<void> => {
    await inboxRequestData.reloadList();
  };

  return (
    <ListAndDetailsSubLayout
      header={{
        title: 'Inbox',
      }}
      items={inboxRequestData.items}
      filters={{
        items: [
          {
            isMultiSelect: true,
            items: [
              {
                isChecked: selectedTypes.includes('chat'),
                key: 'chat',
                label: 'Chat messages',
              },
              {
                isChecked: selectedTypes.includes('workflow-comment'),
                key: 'workflow-comment',
                label: 'Discussion comments',
              },
              {
                isChecked: selectedTypes.includes('task'),
                key: 'task',
                label: 'Workflow tasks',
              },
              {
                isChecked: selectedTypes.includes('noodle-broadcast'),
                key: 'noodle-broadcast',
                label: 'Noodle Broadcasts',
              },
            ],
            label: 'Type',
            onChange: setSelectedTypes,
          },
        ],
      }}
      ifNoItemsText={'No items yet'}
      isOneColumn={isOneColumn}
      getDetailPagePath={inboxRequestData.getDetailPagePath}
      onSearch={handleSearch}
      pagination={inboxRequestData.pagination}
      reloadListView={reloadListView}
      resourceType={'inbox'}
      selectedId={inboxRequestData.selectedId}
    >
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Inbox;
