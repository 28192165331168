import React, { ReactElement, useContext, useState } from 'react';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import { NoodleProductTypes } from '@typings/api-models';
import Buttons from '@components/Buttons';
import ArrowCircleLeft from '@components/Icons/ArrowCircleLeft';
import InputField from '@components/FormFields/InputField';
import ByteItem from '@components/CreateCollectionForm/ByteItem/ByteItem';
import getCreatorCollections from '@tsClient/getCreatorCollections';
import s from '../CreateCollectionForm.module.scss';

type ThisProduct = {
  id: string;
  productTypes: Array<{ id: string }>;
  title: string;
  slug: string;
  stepsAmount: number;
};

type Props = {
  onClick: (prod: ThisProduct) => void;
  close: () => void;
  collection?: Awaited<ReturnType<typeof getCreatorCollections>>[0]['productTypes'][0];
};
const AddByteInput = ({ onClick, close, collection }: Props): ReactElement => {
  const { products } = useContext(DashboardContext);
  const filterProds = products?.filter(prod => collection?.products.some(e => e.id !== prod.id));
  const filteredProducts = filterProds?.length ? filterProds : products;
  const [searchValue, setSearchValue] = useState<string>('');
  const handbookProducts = (filteredProducts ?? []).filter(prod =>
    prod.productTypes.some(type => type.noodleProductType === NoodleProductTypes.Handbook),
  );

  return (
    <>
      <div className={s['search-content']}>
        <Buttons iconBefore={<ArrowCircleLeft weight="fill" color={'black'} size={24} />} onClick={close}>
          Back
        </Buttons>
        <InputField id={'search-byte'} hasFixedHeight={false} name={'search'} onChange={setSearchValue} placeholder="Search for a byte" />
      </div>
      <div className={s['collection-dropdown']}>
        {handbookProducts
          .filter(prod => prod.title?.toLowerCase().includes(searchValue.toLowerCase()))
          .filter(prod => prod.isActive)
          .map(({ id, title, handbookSteps, slug, productTypes }) => (
            <Buttons
              isThird
              isFullWidth
              key={id}
              title={title || ''}
              onClick={() =>
                onClick({
                  id,
                  productTypes: productTypes.map(t => ({ id: t.id })),
                  slug,
                  stepsAmount: handbookSteps.length,
                  title: title ?? '',
                })
              }
            >
              <ByteItem id={id} title={title ?? ''} stepAmount={handbookSteps.length} />
            </Buttons>
          ))}
      </div>
    </>
  );
};

export default AddByteInput;
