import { useMemo } from 'react';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import useConversations from './useConversations';
import { ResourceType } from './common';
import useComments from './useComments';
import useBroadcasts from './useBroadcasts';

type Props = {
  resourceType: ResourceType;
  pageId?: string;
};

const TABS: Array<{
  href: string;
  label: string;
  resourceType: ResourceType;
}> = [
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_CONVERSATIONS),
    label: 'Conversations',
    resourceType: 'conversations',
  },
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_COMMENTS),
    label: 'Comments',
    resourceType: 'comments',
  },
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_BROADCASTS),
    label: 'Broadcasts',
    resourceType: 'broadcasts',
  },
  {
    href: getUrl(IDENTIFIERS.DASHBOARD_MESSAGING_FEED),
    label: 'AI Feed',
    resourceType: 'ai-feed',
  },
];

const Messaging: React.FC<Props> = ({ children, pageId, resourceType }) => {
  const isOneColumn = useIsMobile(SIZES.md);
  const conversationsData = useConversations({ resourceType });
  const commentsData = useComments({ resourceType });
  const broadcastsData = useBroadcasts({ resourceType });

  const header = useMemo(() => ({
    tabs: TABS.map(t => ({
      href: t.href,
      label: t.label,
      selected: resourceType === t.resourceType,
    })),
    title: "Messaging",
  }), [resourceType]);

  const resourceData = (resourceType === 'conversations' && conversationsData)
    || (resourceType === 'comments' && commentsData)
    || (resourceType === 'broadcasts' && broadcastsData)
    || null;

  if (!resourceData) {
    return null;
  }

  return (
    <ListAndDetailsSubLayout
      header={{
        ...header,
        actions: resourceData.actions,
      }}
      isOneColumn={isOneColumn}
      {...resourceData}
      selectedId={pageId || resourceData.selectedId}
      resourceType={resourceType}
    >
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Messaging;
