import { format } from 'date-fns';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import * as ApiModels from '@typings/api-models';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useNoodleApi from '@hooks/useNoodleApi';
import TeamsContext from '@providers/Teams/TeamsContext';
import { nanoid } from 'nanoid';
import { useParams } from 'next/navigation';
import { useContext, useState, useRef, useCallback, useEffect } from 'react';
import * as tsClient from '@tsClient';
import { serializers, useLocalStorageState } from '@providers/LocalStorageState';
import type { HookResponse, ResourceType } from './common';
import { PAGE_SIZE } from './common';

type Conversation = Pick<ApiModels.Conversation,
  | 'color'
  | 'conversationStatus'
  | 'createdAt'
  | 'id'
  | 'image'
  | 'isAiEnabled'
  | 'isLead'
  | 'isPaid'
  | 'isScheduled'
  | 'isSubscriber'
  | 'isWorkflow'
  | 'lastActivityTime'
  | 'lastMessage'
  | 'name'
  | 'sid'
>;

type Item = HookResponse['items'][number];

const conversationToItem = ({
  conversation,
}: {
  conversation: Conversation,
}): Item => {
  const timestamp = conversation.lastActivityTime || conversation.createdAt;
  const isThisYear = new Date().getFullYear() === new Date(timestamp).getFullYear();
  const isToday = format(new Date(timestamp), 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd');
  const isYesterday = format(new Date(timestamp), 'yyyy-MM-dd') === format(new Date(Date.now() - 86400000), 'yyyy-MM-dd');
  const readableDate = (isToday && 'Today') || (isYesterday && 'Yesterday') || format(new Date(timestamp), isThisYear ? 'MMM d' : 'MMM d, yyyy');
  const href = getUrl(IDENTIFIERS.DASHBOARD_CONVERSATION, { conversationSid:  conversation.sid });

  return {
    avatar: {
      color: conversation.color || undefined,
      name: conversation.name || 'Anonymous',
      url: conversation.image ?? undefined,
    },
    bottomLine: conversation.lastMessage,
    href,
    id: conversation.sid,
    isAi: conversation.isAiEnabled,
    label: conversation.name,
    needsAttention: conversation.conversationStatus === ApiModels.ConversationStatus.ResponseRequired,
    time: readableDate,
  };
};

const ALL_FILTERS = ['Leads', 'Paid', 'Anonymous', 'Scheduled', 'Subscriber', 'Workflow', 'needs_response'];
type Filters = typeof ALL_FILTERS[number];

const CONVERSATION_FILTERS: Array<{
  key: Filters;
  label: string;
}> = [
  {
    key: 'Paid',
    label: 'Paid',
  },
  {
    key: 'Scheduled',
    label: 'Scheduled',
  },
  {
    key: 'Subscriber',
    label: 'Subscriber',
  },
  {
    key: 'Leads',
    label: 'Leads',
  },
  {
    key: 'Workflow',
    label: 'Workflow',
  },
  {
    key: 'Anonymous',
    label: 'Anonymous',
  },
  {
    key: 'needs_response',
    label: 'Needs response',
  },
];

const DEFAULT_FILTERS: Filters[] = ['needs_response'];

const getDetailPagePath: Parameters<typeof ListAndDetailsSubLayout>[0]['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_CONVERSATION, { conversationSid: id });

const onlyValidFilters = (filters: string[]): Filters[] => filters.filter(filter => ALL_FILTERS.includes(filter as Filters)) as Filters[];

const deserializeFilters = (asString: string): Filters[] | null => {
  const deserialized = JSON.parse(asString);
  if (!Array.isArray(deserialized)) {
    return null;
  }
  return onlyValidFilters(deserialized);
};

const useConversations = ({
  resourceType,
}: {
  resourceType: ResourceType;
}): HookResponse => {
  const pathParams = useParams<{ conversationSid?: string }>();
  const { currentTeamOwner } = useContext(TeamsContext);
  const mostRecentRequestId = useRef<string | null>(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [itemsCount, setItemsCount] = useState<number | undefined>(undefined);

  const [items, setItems] = useState<Parameters<typeof ListAndDetailsSubLayout>[0]['items']>([]);

  const { getData: getCreatorConversations } = useNoodleApi(tsClient.getCreatorConversations);

  const [storedFilters, setStoredFilters] = useLocalStorageState<Filters[] | null>({
    deserialize: deserializeFilters,
    key: 'chats-list-filters',
    prefix: 'dashboard',
    serialize: serializers.json.serialize,
  });

  const selectedFilters = storedFilters || DEFAULT_FILTERS;
  const creatorId = currentTeamOwner?.id;
  const selectedId = pathParams?.conversationSid;

  const loadPage = useCallback(async ({
    page,
    filters,
    search: newSearch,
  }: {
    page: number;
    filters: string[];
    search: string;
  }): Promise<void> => {
    setCurrentPage(page);
    if (creatorId && resourceType === 'conversations') {
      setIsFetching(true);
      const thisRequestId = nanoid();
      mostRecentRequestId.current = thisRequestId;
      const getResponse = await getCreatorConversations({
        creatorId,
        filter: filters.join('+'),
        page,
        perPage: PAGE_SIZE,
        search: newSearch,
      });
      if (thisRequestId === mostRecentRequestId.current) {
        if (getResponse.data) {
          setItems(getResponse.data.items.map(conversation => conversationToItem({ conversation })));
          setNumPages(getResponse.data.numPages);
          setItemsCount(getResponse.data.numItems);
        }
        setIsFetching(false);
      }
    } else {
      setItems([]);
    }
  }, [creatorId, resourceType, getCreatorConversations]);

  const handlePageChange = (newPage: number): void => {
    loadPage({
      filters: selectedFilters,
      page: newPage,
      search: searchTerm,
    });
  };

  useEffect(() => {
    if (creatorId) {
      loadPage({
        filters: selectedFilters,
        page: 1,
        search: searchTerm,
      });
    }
  }, [loadPage, creatorId, searchTerm, selectedFilters]);

  const filters = {
    items: CONVERSATION_FILTERS.map(item => ({
      ...item,
      isChecked: selectedFilters.includes(item.key),
    })),
    onChange: (newFilters: string[]) => setStoredFilters(newFilters as Filters[]),
  };

  const pagination = {
    numPages,
    onChangePage: handlePageChange,
    page: currentPage,
  };

  return {
    filters: { items: [{
      ...filters,
      isMultiSelect: true,
      label: 'Type',
    }],
    },
    getDetailPagePath,
    ifNoItemsText: 'No Conversations',
    isFetchingList: isFetching,
    items,
    itemsCount,
    onSearch: setSearchTerm,
    pagination,
    selectedId,
  };
};

export default useConversations;
