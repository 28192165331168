import React, { ReactElement, useEffect, useState } from 'react';
import Button from '@components/Buttons/Buttons';
import Spacer from '@components/Spacer';
import Image from 'next/legacy/image';
import classNames from 'classnames';
import ArtistBroadcastStep from '@assets/creator_FTUE_steps/artist_broadcast_step.png';
import ArtistDashboardStep from '@assets/creator_FTUE_steps/artist_dashboard_step.png';
import DashboardStep from '@assets/creator_FTUE_steps/dashboard_step.png';
import WelcomeStep from '@assets/creator_FTUE_steps/noodle_logo_modal.png';
import ConnectStep from '@assets/creator_FTUE_steps/connect_step.png';
import BroadcastStep from '@assets/creator_FTUE_steps/broadcast_step.png';
import CommunityStep from '@assets/creator_FTUE_steps/community_step.png';
import ManageAvailabilityStep from '@assets/creator_FTUE_steps/manage_availability.png';
import WhatNowStep from '@assets/creator_FTUE_steps/what_now_step.png';
import ChatsCircle from '@components/Icons/ChatsCircle';
import Broadcast from '@components/Icons/Broadcast';
import UsersThree from '@components/Icons/UsersThree';
import CaretLeft from '@components/Icons/CaretLeft';
import CaretRight from '@components/Icons/CaretRight';
import CalendarBlank from '@components/Icons/CalendarBlank';
import Modal from '@components/Modal';
import s from './DashboardFTUEModal.module.scss';

type Props = {
  setModal: (isOpen: boolean) => void;
  isArtist: boolean;
};

const artistSteps = [
  {
    caption: (
      <>
        Let’s take a quick tour around your shop! If you need help at any time, contact <a href="mailto:support@noodle.shop">support@noodle.shop</a>.
      </>
    ),
    image: WelcomeStep.src,
    title: 'Welcome! 👋',
  },
  {
    caption: `We’ve set you up with 2 preset tiers - Free and Exclusive. You can edit your tier descriptions and prices from your Community page.`,
    image: CommunityStep.src,
    title: (
      <>
        <UsersThree weight="fill" color="var(--color-gray-100)" />
        Your Community
      </>
    ),
  },
  {
    caption: `Introduce yourself and your community purpose in your first broadcast! Try an AMA or giveaway next.`,
    image: ArtistBroadcastStep.src,
    title: (
      <>
        <Broadcast weight="fill" color="var(--color-gray-100)" />
        Broadcasts
      </>
    ),
  },
  {
    caption: `All your payments, offers, messaging, and member features can be found in the dashboard (click the banner above to access)!`,
    image: ArtistDashboardStep.src,
    title: 'Your Dashboard',
  },
  {
    caption: `Go ahead and create an intro broadcast! Then use your Noodle URL as a link in bio and welcome your audience members in!`,
    image: WhatNowStep.src,
    title: 'What now?',
  },
];

const steps = [
  {
    caption: (
      <>
        Let’s take a quick tour around your shop! If you need help at any time, contact <a href="mailto:support@noodle.shop">support@noodle.shop</a>.
      </>
    ),
    image: WelcomeStep.src,
    title: 'Welcome! 👋',
  },
  {
    caption: `Edit your preset offers to match your services! You can edit titles, descriptions, and prices, or turn connect offers on and off.`,
    image: ConnectStep.src,
    title: (
      <>
        <ChatsCircle weight="fill" color="var(--color-gray-100)" />
        Connect
      </>
    ),
  },
  {
    caption: `Set up your availability and use Noodle to schedule In Person services or Online Video Calls.`,
    image: ManageAvailabilityStep.src,
    title: (
      <>
        <CalendarBlank weight="fill" color="var(--color-gray-100)" />
        Manage Availability
      </>
    ),
  },
  {
    caption: `Broadcasts are a great way to message your entire audience. You decide who gets access to each message! Everyone with access gets notified via email.`,
    image: BroadcastStep.src,
    title: (
      <>
        <Broadcast weight="fill" color="var(--color-gray-100)" />
        Broadcasts
      </>
    ),
  },
  {
    caption: `We’ve set you up with a freemium Community membership. Members get all access to your Broadcast messages - edit your membership tiers however you’d like!`,
    image: CommunityStep.src,
    title: (
      <>
        <UsersThree weight="fill" color="var(--color-gray-100)" />
        Community
      </>
    ),
  },
  {
    caption: `All your payments, offers, messaging, and member features can be found in the dashboard (click the banner above to access)!`,
    image: DashboardStep.src,
    title: 'Your Dashboard',
  },
  {
    caption: `You’re just about ready! Edit your offer details, share your Noodle, and start growing your business with Noodle.`,
    image: WhatNowStep.src,
    title: 'What now?',
  },
];

const DashboardFTUEModalModal = ({ isArtist, setModal }: Props): ReactElement => {
  const [currentStep, setCurrentStep] = useState(0);
  const [hasCompleted, setHasCompleted] = useState(false);

  const currentSteps = isArtist ? artistSteps : steps;

  useEffect(() => {
    if (currentStep === currentSteps.length - 1 && hasCompleted === false) {
      setHasCompleted(true);
    }
  }, [currentStep, currentSteps, hasCompleted, setHasCompleted]);

  return (
    <Modal hasPadding onClose={() => setModal(false)} title="Noodle Guide" >
      <Image src={currentSteps[currentStep].image} objectFit="contain" alt="" width={563} height={320} className={s.modal__image} />
      <div>
        <h2 className={s.modal__heading}>{currentSteps[currentStep].title}</h2>
        <p className={classNames(s.modal__caption, 'body-sm')}>{currentSteps[currentStep].caption}</p>
        <Spacer size="24px" />
        <div className={s.modal__step__control}>
          <Button isExtraSmall isSecondary onClick={() => setCurrentStep(currentStep - 1)} disabled={currentStep === 0} icon={<CaretLeft />} />
          <p className={classNames(s.modal__step__text, 'body-sm')}>{`${currentStep + 1} of ${currentSteps.length}`}</p>
          <Button
            isExtraSmall
            isSecondary
            disabled={currentStep === currentSteps.length - 1}
            onClick={() => setCurrentStep(currentStep + 1)}
            icon={<CaretRight />}
          />
        </div>
        {hasCompleted && (
          <>
            <Spacer size="16px" />
            <Button isSecondary isLarge isFullWidth onClick={() => setModal(false)}>
              Get Started!
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DashboardFTUEModalModal;
