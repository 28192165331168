import { useContext, useMemo, useState } from 'react';
import { useParams } from 'next/navigation';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useIsMobile, { SIZES } from '@hooks/useIsMobile';
import Plus from '@components/Icons/Plus';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import removeNullish from '@helpers/removeNullish';
import { NoodleProductTypes } from '@typings/graphql-models';
import { isChargeProduct, isHandbookProduct, isSubscriptionProduct } from '@helpers/isProductType';
import hasNoodleProductType from '@helpers/hasNoodleProductType';
import * as format from '@format';

const Services: React.FC = ({ children }) => {
  const pathParams = useParams<{ productSlug?: string }>();
  const isOneColumn = useIsMobile(SIZES.md);
  const { setIsCreateServiceModalOpen, products, fetchProducts } = useContext(DashboardContext);
  const [searchTerms, setSearchTerms] = useState<string | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const selectedSlug = pathParams?.productSlug;

  const isNotService = (product: {
      productTypes: {
        noodleProductType?: NoodleProductTypes | null,
      }[];
     },
  ): boolean => (
    isChargeProduct(product)
      || isHandbookProduct(product)
      || hasNoodleProductType(product, NoodleProductTypes.Response, NoodleProductTypes.ProductGroup)
      || isSubscriptionProduct(product)
  );

  const items = useMemo(() => {
    if (!products) {
      return [];
    }

    return products
      .filter(
        p => !isNotService(p),
      )
      .sort(p => {
        if (p.isActive) {
          return -1;
        }
        return 1;
      })
      .map(product => ({
        bottomLine:
          product.description
          || product.prices
            .map(price => price.priceTitle)
            .filter(removeNullish)
            .filter(title => title !== '')
            .join(', '),
        href: getUrl(IDENTIFIERS.DASHBOARD_SERVICES_DETAILS, { productSlug: product.slug }),
        id: product.id,
        isMuted: !product.isActive,
        isSelected: product.slug === selectedSlug,
        label: product.title || 'No title',
        slug: product.slug,
        topLine: product.productTypes?.[0]?.noodleProductType && format.productType(product.productTypes[0]?.noodleProductType) || '',
      }));
  }, [products, selectedSlug]);

  const filters = useMemo(() => {
    if (!products) {
      return [];
    }
    const productTypes = products.filter(
      p => !isNotService(p),
    ).map(product => ({
      isChecked: product.productTypes?.[0]?.noodleProductType ? selectedFilters.includes(product.productTypes[0]?.noodleProductType) : false,
      key: product.productTypes?.[0]?.noodleProductType || '',
      label: (product.productTypes?.[0]?.noodleProductType && format.productType(product.productTypes?.[0]?.noodleProductType)) || '',
    }));
    const uniqueProductTypes = productTypes.filter((productType, index) => productTypes.findIndex(p => p.key === productType.key) === index);
    return uniqueProductTypes;
  }, [products, selectedFilters]);

  return (
    <ListAndDetailsSubLayout
      header={{
        actions: [
          {
            icon: Plus,
            label: 'Create new service',
            onClick: () => {
              setIsCreateServiceModalOpen(true);
            },
          },
        ],
        title: 'Your services',
      }}
      filters={{
        items: [{
          isMultiSelect: true,
          items: filters,
          label: 'Type',
          onChange: setSelectedFilters,
        }],
      }}
      onSearch={setSearchTerms}
      items={items
        .filter(i => (searchTerms ? i.label.toLowerCase().includes(searchTerms?.toLowerCase()) : true))
        .filter(i => (selectedFilters.length > 0 && i.topLine
          ? selectedFilters.map(f => format.productType(f as NoodleProductTypes)).includes(i.topLine)
          : true))
      }
      selectedId={selectedSlug}
      ifNoItemsText={'No products yet'}
      isFetchingList={products === null}
      isOneColumn={isOneColumn}
      getDetailPagePath={() => getUrl(IDENTIFIERS.DASHBOARD_SERVICES_DETAILS, { productSlug: items[0].slug })}
      reloadListView={async () => {
        await fetchProducts();
      }}
      resourceType={'services'}
    >
      {children}
    </ListAndDetailsSubLayout>
  );
};

export default Services;
