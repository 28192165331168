import CustomerSchedulingCalendar from "@components/CustomerSchedulingCalendar";
import Button from "@components/DesignLibrary/Button";
import { Maybe } from "@typings/graphql-models";
import { FC } from "react";
import * as ApiModels from '@typings/api-models';
import { CartItem } from "@providers/ShoppingCart";

type ProductPrice = (Pick<ApiModels.Price, 'sessionDuration' | 'sessionInterval'> &
  CartItem & {
    priceDescription?: {
      html?: string | null;
    } | null;
  });

type Product = Pick<ApiModels.Product, 'id' | 'title' | 'isActive'> & {
  productTypes: Array<Pick<ApiModels.ProductType, 'noodleProductType' | 'id' | 'title'>>;
  prices: Array<Pick<ApiModels.Price,
    'price' | 'id' | 'sessionDuration' | 'sessionInterval' |"id" |
    "currency" | "numBookings" | "price" | "priceSubDescription" |
    "priceTitle" | "recurringInterval" | "sessionDuration" | "sessionInterval" | "sessionTime"
  > & ProductPrice>;
  creator?: {
    name?: string | null;
  } | null;
};
type Price = Product['prices'][0];

type Props = {
  creatorTimezone: Maybe<string>;
  priceId: string | null;
  product: Product;
  onClickCta: () => void;
  onDateTimeSelect: (
    selected: {
      price: Price, sessionDuration: number, sessionTime: string
  }) => Promise<void>;
  disabled: boolean;
  selectedTeamMemberPersonId?: string;
};

const AssignTimeSlot: FC<Props> = ({ creatorTimezone, priceId, product, onDateTimeSelect, disabled, onClickCta, selectedTeamMemberPersonId }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: 16, padding: 24 }}>
    <CustomerSchedulingCalendar
      availabilityTimezone={creatorTimezone}
      priceId={priceId}
      product={product}
      onDateTimeSelect={onDateTimeSelect}
      selectedTeamMemberPersonId={selectedTeamMemberPersonId}
      vertical
    />
    <Button
      fullWidth
      disabled={disabled}
      variant="primary"
      size="md"
      onClick={onClickCta}
    >
      Next
    </Button>
  </div>
);

export default AssignTimeSlot;