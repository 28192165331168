import noodleApiClient, { ApiDefinition } from '@noodleApi';

const PATH_TEMPLATE = '/product-types/{productTypeSlug}';
type THIS_API = ApiDefinition[typeof PATH_TEMPLATE]['PUT'];
type Input = THIS_API['body'];
type Output = THIS_API['response'];

const updateProductType = async (body: Input, productTypeSlug: string): Promise<Output> => {
  const response = await noodleApiClient.put(`/product-types/${productTypeSlug}`, body);
  return response.data;
};

export default updateProductType;