import Buttons from '@components/Buttons';
import ProgressIndicator from '@components/ProgressIndicator';
import Spacer from '@components/Spacer';
import isInIframe from '@helpers/isInIframe';
import React, { FC, ReactElement, useEffect, useRef } from 'react';

type Props = {
  loadFn: () => void;
  isDisabled: boolean;
  intersectTime?: number;
  showSpinner?: boolean;
};

const PaginationObserver: FC<Props> = ({ showSpinner = true, loadFn, isDisabled, intersectTime }): ReactElement => {
  const boxElement = useRef<HTMLDivElement>(null);
  const loadFnRef = useRef<Props['loadFn']>(loadFn);
  const loadFnTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    loadFnRef.current = loadFn;
  }, [loadFn]);

  useEffect(() => {
    if (!isInIframe()) {
      const handleIntersect: ConstructorParameters<typeof IntersectionObserver>[0] = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (intersectTime) {
              loadFnTimeoutRef.current = setTimeout(() => loadFnRef.current(), intersectTime);
            } else {
              loadFnRef.current();
            }
          } else if (loadFnTimeoutRef.current) {
            clearTimeout(loadFnTimeoutRef.current);
            loadFnTimeoutRef.current = null;
          }
        });
      };

      const options = {
        root: null,
        rootMargin: '0px',
        threshold: [0.75],
      };

      const boxRef = boxElement.current;
      const observer = new IntersectionObserver(handleIntersect, options);
      if (boxRef) {
        observer.observe(boxRef);
      }

      return () => {
        if (boxRef) {
          observer.unobserve(boxRef);
        }

        if (loadFnTimeoutRef.current) {
          clearTimeout(loadFnTimeoutRef.current);
          loadFnTimeoutRef.current = null;
        }
      };
    }

    return () => {};
  }, [boxElement, intersectTime]);

  return (
    <>
      {isInIframe() && !isDisabled && (
        <>
          <Buttons isFourth isFullWidth isExtraSmall disabled={isDisabled} isShimmering={isDisabled} onClick={() => loadFnRef?.current?.()}>
            Load more...
          </Buttons>
          <Spacer size={24} />
        </>
      )}
      {!isInIframe() && (
        <div
          ref={boxElement}
          style={{
            borderRadius: '20px',
            height: '56px',
            overflow: 'hidden',
            width: '100%',
            ...(isDisabled && showSpinner ? { bottom: '-100px', position: 'fixed' } : {}),
          }}
        >
          {isDisabled
            ? (
              <></>
            )
            : (
              <>
                {showSpinner && (
                  <>
                    <Spacer />
                    <ProgressIndicator isCentered />
                    <Spacer />
                  </>
                )}
              </>
            )}
        </div>
      )}
    </>
  );
};

export default PaginationObserver;
