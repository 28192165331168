import { FC, useContext, useEffect, useState } from 'react';
import * as ApiModels from '@typings/api-models';
import { CONTEXT_TYPE } from '@typings/api-models';
import Button from '@components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { useRouter } from 'next/router';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import UserImage from '@components/UserImage';
import * as format from '@format';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import { DEFAULT_CURRENCY } from '@typings/common';
import Bluebird from 'bluebird';
import removeNullish from '@helpers/removeNullish';
import getProcessingFees from '@tsClient/getProcessingFees';
import ProgressIndicator from '@components/ProgressIndicator';
import BannerStatus from '@components/DesignLibrary/BannerStatus';
import Buttons from '@components/Buttons';
import formatContextData from '@/helpers/formatContextData';
import s from './InitiateCaseModal.module.scss';
import { InvoiceData } from './CreateInvoice';
import { ContextData } from './types';

type Props = {
  associatedUserWorkflowId?: string;
  customer: Pick<ApiModels.Person, 'id' | 'name' | 'email'> & {
    primaryColour?: { hex: string } | null;
    image?: { url: string } | null;
  };
  workflow: Pick<ApiModels.Workflow, 'id' | 'name'>;
  context: ContextData[];
  product: Pick<ApiModels.Product, 'id' | 'title'>;
  onClose: () => void;
  invoiceData: (InvoiceData & Pick<ApiModels.InvoiceTemplate, 'isExemptFromApplicationFee'>)[];
  ownerIds: string[];
  teamMembers?: Pick<ApiModels.TeamMember, 'id' | 'name' | 'email'>[];
};

const Review: FC<Props> = ({
  associatedUserWorkflowId,
  customer,
  context,
  product,
  workflow,
  onClose,
  ownerIds,
  invoiceData,
  teamMembers,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [failedPayments, setFailedPayments] = useState<Array<{
    invoiceTemplateId: string;
    failureReason?: string | null;
    paymentId?: string | null;
  }>>([]);
  const [successfulPayments, setSuccessfulPayments] = useState<Array<{
    invoiceTemplateId: string;
    paymentId?: string | null;
  }>>([]);
  const [processingFees, setProcessingFees] = useState<Record<string, number>>({});
  const [isFetchingProcessingFees, setIsFetchingProcessingFees] = useState(false);

  const router = useRouter();

  const { creator } = useContext(dashboardContext);

  const { getData: initiateFn } = useNoodleApi(tsClient.workflows.initiateWorkflowAsCreator, {
    toastOnError: true,
  });
  const { getData: getProcessingFeesFn } = useNoodleApi(getProcessingFees);
  const { getData: initiatePaymentForUserFn } = useNoodleApi(tsClient.payments.initiatePaymentForUser);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setIsFetchingProcessingFees(true);
      const feesMap: Record<string, number> = {};
      await Bluebird.map(invoiceData, async (invoice) => {
        if (creator && invoice.passProcessingFeesToCustomer && invoice.initialPaymentAmount) {
          const { data: fees } = await getProcessingFeesFn({
            amount: invoice.initialPaymentAmount,
            isConfido: creator.usesConfido,
          });
          if (fees) {
            // If we ever want to support ACH, we will need to update this
            feesMap[invoice.id] = fees.USCardFee;
          }
        }
      });
      setProcessingFees(feesMap);
      setIsFetchingProcessingFees(false);
    };
    getData();
  }, [creator, invoiceData, getProcessingFeesFn]);

  const handleSubmit = async (config?: { isRetry: boolean }): Promise<void> => {
    setIsSubmitting(true);

    let payments: Array<{ invoiceTemplateId: string; paymentId?: string | null; }> = config?.isRetry
      ? []
      : [...successfulPayments, ...failedPayments];

    if ((successfulPayments.length === 0 && failedPayments.length === 0) || config?.isRetry) {
      const paymentData = await Bluebird.mapSeries(invoiceData, async (invoiceTemplate) => {
        if (creator && invoiceTemplate.initialPaymentAmount && invoiceTemplate.paymentMethodId && !invoiceTemplate.createPaymentPlan) {
          const { data } = await initiatePaymentForUserFn({
            amount: invoiceTemplate.initialPaymentAmount + (processingFees[invoiceTemplate.id] || 0),
            creatorId: creator.id,
            currency: creator.defaultCurrency || DEFAULT_CURRENCY,
            destinationAccountId: creator.usesConfido ? invoiceTemplate.confidoBankAccountId : invoiceTemplate.secondaryStripeAccountId,
            id: customer.id,
            isAchPaymentEnabled: false,
            isCardPaymentEnabled: invoiceTemplate.isCreditCardPaymentEnabled,
            isDebitCardPaymentEnabled: invoiceTemplate.isDebitCardPaymentEnabled,
            isExemptFromApplicationFee: invoiceTemplate.isExemptFromApplicationFee,
            passProcessingFeesToCustomer: invoiceTemplate.passProcessingFeesToCustomer,
            paymentMethodId: invoiceTemplate.paymentMethodId,
          });
          return {
            ...data,
            invoiceTemplateId: invoiceTemplate.id,
          };
        }
        return null;
      });

      const failedPaymentData = paymentData.filter((d) => d?.failureReason).filter(removeNullish);
      payments = [...paymentData.filter((p) => p?.paymentId).filter(removeNullish)];
      if (failedPaymentData.length > 0) {
        setIsSubmitting(false);
        setFailedPayments(failedPaymentData);
        setSuccessfulPayments(paymentData.filter((d) => d?.isSuccessful && d?.paymentId).filter(removeNullish));
        return;
      }
    }

    const invoicesWithPayments = invoiceData.map(({ id, ...i }) => ({
      ...i,
      paymentId: payments.find((p) => p?.invoiceTemplateId === id)?.paymentId ?? null,
      recurringAmount: i.initialPaymentAmount,
    }));

    const { data: userWorkflow } = await initiateFn({
      associatedUserWorkflowId,
      contexts: context,
      customerId: customer.id,
      invoices: invoicesWithPayments,
      ownerIds,
      workflowId: workflow.id,
    });
    if (userWorkflow) {
      await router.push(getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOW_DETAIL, { userWorkflowId: userWorkflow.id }));
      setIsSubmitting(false);
      onClose();
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={s.review}>
      <div className={s.content}>
        <p className="body-lg" style={{ textAlign: 'center' }}>
          {product.title}:<br />
          <b>{workflow.name}</b>
        </p>
        <Spacer size="16px" />
        <p className="caption" style={{ color: 'var(--color-gray-75)', textAlign: 'center' }}>
          Contact
        </p>
        <Spacer size="8px" />
        <div className={s.customer}>
          <UserImage size={24} color={customer.primaryColour?.hex} name={customer.name} url={customer.image?.url} />
          <p className="body-sm">{customer.name}</p>
        </div>
        <Spacer size="16px" />
      </div>
      {context.length > 0 && (
        <div className={s.reviewCell}>
          <p className="caption">
            Context <span style={{ color: 'var(--color-gray-75)' }}>{context.filter((c) => c.context.type === CONTEXT_TYPE.PERSON).length > 0 ? `(We'll invite these people by email)` : ''}</span>
          </p>
          <Spacer size="16px" />
          <div className={s.item}>
            {context.map(data => (
              <div key={data.context.id}>
                <p className="caption" style={{ color: 'var(--color-gray-75)' }}>
                  {data.context.label}
                </p>
                <p className="body-sm">
                  {formatContextData(
                    data.data,
                    data.context.type === CONTEXT_TYPE.SELECTION
                      ? data.context.options.find((option) => option.slug === (data.data as ApiModels.CreateSelectionContextData).slug)?.label || null
                      : null,
                    data.context.type,
                    customer,
                  )}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      {ownerIds.length > 0 && (
        <>
          {context.length > 0 && <Spacer />}
          <div className={s.reviewCell}>
            <p className="caption">
              Assigned team members
            </p>
            <Spacer size="16px" />
            <div className={s.item}>
              {ownerIds.map(ownerId => (
                <div key={ownerId}>
                  <p className="caption" style={{ color: 'var(--color-gray-75)' }}>
                    {teamMembers?.find((m) => m.id === ownerId)?.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <Spacer size="16px" />
        </>
      )}
      <div className={s.invoices}>
        {(invoiceData?.length || 0) > 0 && invoiceData.map((invoice) => (
          <div key={invoice.id} className={s.reviewCell}>
            <p className="caption">
              Workflow Invoice ({invoice.title})
            </p>
            <Spacer size="16px" />
            {isFetchingProcessingFees
              ? <ProgressIndicator isCentered />
              : (
                <>
                  {invoice.lineItems.map((lineItem) => (
                    <div className={s.lineItem} key={lineItem.id}>
                      <p className='body-sm' style={{ color: 'var(--color-gray-75)' }}>{lineItem.item}</p>
                      <p className='body-sm' style={{ color: 'var(--color-gray-100)' }}>{format.price.withCurrency((lineItem.price || 0) * lineItem.quantity, creator?.defaultCurrency || DEFAULT_CURRENCY, true)}</p>
                    </div>
                  ))}
                  <div className={s.lineItem}>
                    <p className="body-sm-bold" style={{ color: 'var(--color-gray-100)' }}>Total</p>
                    <p className="body-sm-bold" style={{ color: 'var(--color-gray-100)' }}>{format.price.withCurrency(invoice.lineItems.reduce((acc, val) => acc + (val.price || 0) * val.quantity, 0), creator?.defaultCurrency || DEFAULT_CURRENCY, true)}</p>
                  </div>
                </>
              )}
          </div>
        ))}
        {invoiceData?.filter(i => i.initialPaymentAmount && !i.createPaymentPlan)?.map((invoice) => (
          <div key={invoice.id} className={s.reviewCell}>
            <div className={s.title}>
              <p className="caption">
                Pay now ({invoice.title})
              </p>
              {failedPayments.find(payment => payment.invoiceTemplateId === invoice.id) && (
                <Buttons isWrapper onClick={() => {
                  setFailedPayments([]);
                  setSuccessfulPayments([]);
                  handleSubmit({ isRetry: true });
                }}>
                  <p className='caption-bold' style={{ color: 'var(--color-noodle)' }}>Retry</p>
                </Buttons>
              )}
            </div>
            <Spacer size="16px" />
            <div className={s.lineItem}>
              <p className='body-sm' style={{ color: 'var(--color-gray-75)' }}>Initial payment</p>
              <p className='body-sm' style={{ color: 'var(--color-gray-100)' }}>{format.price.withCurrency(invoice.initialPaymentAmount || 0, creator?.defaultCurrency || DEFAULT_CURRENCY, true)}</p>
            </div>
            {Boolean(processingFees[invoice.id]) && (
              <div className={s.lineItem}>
                <p className="body-sm" style={{ color: 'var(--color-gray-75)' }}>Processing fee</p>
                <p className="body-sm" style={{ color: 'var(--color-gray-100)' }}>{format.price.withCurrency(processingFees[invoice.id] || 0, creator?.defaultCurrency || DEFAULT_CURRENCY, true)}</p>
              </div>
            )}
            <div className={s.lineItem}>
              <p className="body-sm-bold" style={{ color: 'var(--color-gray-100)' }}>Total</p>
              <p className="body-sm-bold" style={{ color: 'var(--color-gray-100)' }}>{format.price.withCurrency((invoice.initialPaymentAmount || 0) + (processingFees[invoice.id] || 0), creator?.defaultCurrency || DEFAULT_CURRENCY, true)}</p>
            </div>
            {failedPayments.filter(payment => payment.invoiceTemplateId === invoice.id).map(payment => (
              <BannerStatus key={payment.invoiceTemplateId} status='error' label={`Payment failed: ${payment.failureReason}`} />
            ))}
          </div>
        ))}
        {invoiceData?.filter(i => i.createPaymentPlan)?.map((invoice) => (
          <div key={invoice.id} className={s.reviewCell}>
            <div className={s.title}>
              <p className="caption">
                Create payment plan ({invoice.title})
              </p>
            </div>
            <Spacer size="16px" />
            <div className={s.lineItem}>
              {invoice.initialPaymentAmount && invoice.paymentPlanFrequency && invoice.nextPaymentDate && (
                <div className={s.summary}>
                  <p className='body-md-bold'>The first {format.price.withCurrency(invoice.initialPaymentAmount, creator?.defaultCurrency || DEFAULT_CURRENCY, true)} payment will be on {format.datetime.monthDay(invoice.nextPaymentDate)}.</p>
                  <Spacer size={8} />
                  <p className='caption'>Then, the client will be billed {invoice.paymentPlanFrequency === 'biweekly' && 'every two weeks' || invoice.paymentPlanFrequency === 'monthly' && 'every month' || 'every week'} automatically. Payments will cease when the invoice is paid in full.</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Spacer size="16px" />
      {failedPayments.length === 0
        ? (
          <Button variant="primary" size="md" fullWidth loading={isSubmitting} onClick={() => handleSubmit()}>
            Initiate the case
          </Button>
        )
        : (
          <>
            <Button variant="primary" size="md" fullWidth loading={isSubmitting} onClick={() => handleSubmit()}>
              Initiate without collecting payment
            </Button>
            <Spacer size={16} />
            <Button variant="secondary" size="md" fullWidth onClick={onClose}>
              Cancel initiation
            </Button>
          </>
        )}
    </div>
  );
};

export default Review;
