import React from 'react';
import { getUrl, IDENTIFIERS } from 'helpers/urlsHelper';
import Modal from '@components/Modal';
import { useIsMobile } from '@hooks';
import QRCode from '@components/QRCode';
import UserImage from '@components/UserImage';
import Spacer from '@components/Spacer';
import CustomLink from '@components/CustomLink';
import Copy from '@components/Icons/Copy';
import Eye from '@components/Icons/Eye';
import CopyButton from '@components/CopyButton';
import { SELF_ORIGIN } from '@configuration/client';
import useNoodleApi from '@hooks/useNoodleApi';
import { getProductBySlug } from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import s from './ShareProductModal.module.scss';

type ShareProductModalProps = {
  creatorSlug: string;
  productSlug: string;
  productTitle?: string;
  creator: {
    name: string;
    primaryColour: {
      hex: string;
    };
    image: string;
  };
  onClose: () => void;
};

const ShareProductModal = ({ creatorSlug, productSlug, productTitle, creator, onClose }: ShareProductModalProps): JSX.Element => {
  const isMobile = useIsMobile();
  const { data: productInfo, fetchingState: fetchingProduct, getData: getProductFn } = useNoodleApi(getProductBySlug);

  React.useEffect(() => {
    if (!productTitle) {
      getProductFn({ creatorSlug, productSlug });
    }
  }, [creatorSlug, productSlug, getProductFn, productTitle]);

  return (
    <Modal
      containerStyle={isMobile ? {} : { width: '80%' }}

      title={`Share ${(fetchingProduct.isFetching && '') || productInfo?.title || productTitle || ''}`}
      onClose={onClose}

    >
      <div className={s.modalWrapper}>
        <div className={s.code}>
          <QRCode data={getUrl(IDENTIFIERS.PRODUCT, { creatorSlug, productSlug })} />
          <UserImage hasBorder name={creator?.name} size={48} color={creator?.primaryColour?.hex} url={creator?.image} />
        </div>
        <Spacer size={24} />
        <p className="caption">{creator?.name}</p>
        <Spacer size={8} />
        <h1 className="heading-sm">{(fetchingProduct.isFetching && <ProgressIndicator size={32} />) || productInfo?.title || productTitle}</h1>
        <Spacer size={24} />
        <CustomLink to={getUrl(IDENTIFIERS.PRODUCT, { creatorSlug, productSlug })} newTab className={s.previewLink}>
          <Eye weight="fill" size={20} /> Preview product link
        </CustomLink>
        <Spacer size={16} />
        <CopyButton
          isSecondary
          isFullWidth
          text={SELF_ORIGIN + getUrl(IDENTIFIERS.PRODUCT, { creatorSlug, productSlug })}
          iconBefore={<Copy weight="fill" size={20} />}
        >
          Copy link
        </CopyButton>
      </div>
    </Modal>
  );
};

export default ShareProductModal;
