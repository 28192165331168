import { useContext, useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import * as ApiModels from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import TeamsContext from '@providers/Teams/TeamsContext';
import { useParams } from 'next/navigation';
import * as tsClient from '@tsClient';
import * as format from '@format';
import { useFeatureFlag } from '@/providers/FeatureFlags';
import { useQueryState } from 'nuqs';
import { keys } from 'lodash';
import {
  type HookResponse,
  type ResourceType,
  type ListViewRegularItem,
  type TableCell,
  type TableRow,
  type TableColumn,
  addHeaderKeyToCell,
} from '../Workflow/common';

type ThisUserWorkflow = Awaited<ReturnType<typeof tsClient.creators.getUserWorkflows>>['items'][number];
type Status = ApiModels.UserWorkflow['status'] | 'paused';

type PendingClientTasksFilter = 'custom-terms' | 'document-request-user' | 'form-request' | 'invoice';

interface StatusProperty {
  label: string;
  color: string;
}

const StatusToBadge: Record<Status, StatusProperty> = {
  archived: { color: 'var(--color-workflow-archived)', label: 'Archived' },
  completed: { color: 'var(--color-workflow-completed)', label: 'Completed' },
  'data-collection': { color: 'var(--color-workflow-data-collection)', label: 'Data Collection' },
  'filed-and-pending': { color: 'var(--color-workflow-filed-and-pending)', label: 'Filed and Pending' },
  paused: { color: 'var( --color-workflow-paused)', label: 'Paused' },
  'preparing-case': { color: 'var(--color-workflow-preparing-case)', label: 'Processing' },
};

const formatUserWorkflowContext = (userWorkflowContext: Pick<ApiModels.UserWorkflowContext, 'label' | 'value' | 'type'>[]): string => {
  let returnString = '';
  userWorkflowContext.forEach((context, index) => {
    returnString = returnString.concat(`${context.label}: ${context.value}${index === userWorkflowContext.length - 1 ? '' : ', '}`);
  });
  return returnString;
};

const statusToBadge = (userWorkflow: Pick<ApiModels.UserWorkflow, 'status' | 'pausedUntil'>): ListViewRegularItem['badge'] | undefined => {
  if (userWorkflow.pausedUntil) {
    return StatusToBadge.paused;
  }
  if (userWorkflow.status in StatusToBadge) {
    return StatusToBadge[userWorkflow.status];
  }
  return undefined;
};

const TABLE_COLUMNS: TableColumn[] = [
  {
    key: 'workflow',
    label: 'Workflow Title',
  },
  // {
  //   key: 'assignees',
  //   label: 'Assigned to',
  // },
  {
    key: 'status',
    label: 'Workflow Status',
  },
  {
    key: 'contact',
    label: 'Contact',
  },
  {
    key: 'context',
    label: 'Context',
  },
  {
    key: 'progress',
    label: 'Progress',
  },
  {
    key: 'recent-activity',
    label: 'Recent activity',
  },
  {
    key: 'pending-client-tasks',
    label: 'Pending Client Tasks',
  },
];

const curriedAddHeaderKeyToCell = addHeaderKeyToCell(TABLE_COLUMNS);

const userWorkflowToTableRow = ({
  hasAssignedTasksByUserWorkflow,
  userWorkflow,
}: {
  hasAssignedTasksByUserWorkflow: Record<string, boolean | 'fetching'>;
  userWorkflow: Awaited<ReturnType<typeof tsClient.creators.getUserWorkflows>>['items'][number];
}): TableRow => {
  const statusBadge = statusToBadge(userWorkflow);
  const progress = userWorkflow.status === 'data-collection' || userWorkflow.pausedUntil
    ? userWorkflow.progress * 100
    : undefined;
  const hasAssignedTasks = hasAssignedTasksByUserWorkflow[userWorkflow.id] !== 'fetching' && hasAssignedTasksByUserWorkflow[userWorkflow.id];
  const needsAttention = userWorkflow.status === 'data-collection' ? Boolean(hasAssignedTasks && hasAssignedTasks !== 'fetching') : false;

  const cells: TableCell[] = [
    {
      cta: 'Open workflow',
      href: getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOW_DETAIL, { userWorkflowId: userWorkflow.id }),
      label: userWorkflow.workflow.name,
      type: 'open-details',
    },
    // {
    //   assignees: userWorkflow.workflow.assignees || [],
    //   type: 'assignees',
    // },
    statusBadge
      ? {
        type: 'badge',
        ...statusBadge,
      }
      : {
        type: 'empty',
      },
    {
      content: userWorkflow.person.name || '—',
      type: 'text',
    },
    {
      content: formatUserWorkflowContext(userWorkflow.context),
      type: 'text',
    },
    typeof progress === 'number'
      ? {
        progress,
        type: 'progress',
      }
      : { type: 'empty' },
    {
      content: format.datetime.withoutTimezone({datetime:userWorkflow.lastActivityAt}),
      type: 'text',
    },
    {
      content: `${userWorkflow.numPendingClientTasks}`,
      type: 'text',
    },
  ];

  return {
    cells: cells.map(curriedAddHeaderKeyToCell),
    id: userWorkflow.id,
    state: needsAttention ? 'warning' : undefined,
  };
};

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_USER_WORKFLOW_DETAIL, { userWorkflowId: id });

const useActivity = (
  { resourceType }:
  { resourceType: ResourceType },
): HookResponse => {
  const useTableView = useFeatureFlag('table-view');
  const pathParams = useParams<{ userWorkflowId?: string }>();
  const { creatorId } = useContext(TeamsContext);
  const [numCompletedUserWorkflows, setNumCompletedUserWorkflows] = useState(0);
  const [numArchivedUserWorkflows, setNumArchivedUserWorkflows] = useState(0);
  const [numPausedUserWorkflows, setNumPausedUserWorkflows] = useState(0);
  const [numDataCollectionUserWorkflows, setNumDataCollectionUserWorkflows] = useState(0);
  const [numFiledAndPendingUserWorkflows, setNumFiledAndPendingUserWorkflows] = useState(0);
  const [numPreparingCaseUserWorkflows, setNumPreparingCaseUserWorkflows] = useState(0);
  const [userWorkflows, setUserWorkflows] = useState<ThisUserWorkflow[]>([]);
  const [itemsCount, setItemsCount] = useState<number | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState<Status[]>([]);
  const [hasAssignedTasksByUserWorkflow, setHasAssignedTasksByUserWorkflow] = useState<Record<string, boolean | 'fetching'>>({});
  const [numPages, setNumPages] = useState(0);
  const mostRecentRequestRef = useRef<string | null>(null);
  const { getData: getUserWorkflowsFn } = useNoodleApi(tsClient.creators.getUserWorkflows);
  const { getData: getUserWorkflowProgressFn } = useNoodleApi(tsClient.workflows.getUserWorkflowProgress);
  const [customTermsFilterValue, setCustomTermsFilterValue] = useQueryState('custom-terms');
  const [documentRequestUserFilterValue, setDocumentRequestUserFilterValue] = useQueryState('document-request-user');
  const [formRequestFilterValue, setFormRequestFilterValue] = useQueryState('form-request');
  const [invoiceFilterValue, setInvoiceFilterValue] = useQueryState('invoice');

  const selectedClientTaskFilters = useMemo(() => {
    const allFilters: Record<PendingClientTasksFilter, string | null> = {
      'custom-terms': customTermsFilterValue,
      'document-request-user': documentRequestUserFilterValue,
      'form-request': formRequestFilterValue,
      'invoice': invoiceFilterValue,
    };

    return keys(allFilters).filter(key => allFilters[key as unknown as PendingClientTasksFilter] === 'true');
  }, [customTermsFilterValue, documentRequestUserFilterValue, formRequestFilterValue, invoiceFilterValue]);

  const [queryInitiatedByPersonId] = useQueryState('initiatedByPersonId');

  const selectedId = pathParams?.userWorkflowId;

  const fetchWorkflows = useCallback(
    async ({ page: newPage, search: newSearch, statuses }: { page: number; search: string; statuses: Status[] }): Promise<void> => {
      if (creatorId && resourceType === 'activity') {
        setCurrentPage(newPage);
        const thisRequestId = nanoid();
        mostRecentRequestRef.current = thisRequestId;
        setIsFetching(true);
        setUserWorkflows([]);
        setCurrentPage(newPage);

        const response = await getUserWorkflowsFn({
          archived: statuses.includes('archived') || statuses.length === 0,
          clientTaskFilters: selectedClientTaskFilters,
          completed: statuses.includes('completed') || statuses.length === 0,
          creatorId,
          dataCollection: statuses.includes('data-collection') || statuses.length === 0,
          filedAndPending: statuses.includes('filed-and-pending') || statuses.length === 0,
          initiatedByPersonId: queryInitiatedByPersonId,
          page: newPage,
          paused: statuses.includes('paused'),
          perPage: 25,
          preparingCase: statuses.includes('preparing-case') || statuses.length === 0,
          search: newSearch,
        });

        if (thisRequestId === mostRecentRequestRef.current) {
          if (response.data) {
            setNumPages(response.data.numPages);
            setUserWorkflows(response.data.items);
            setItemsCount(response.data.numItems);
          }
          setIsFetching(false);
        }
      }
    },
    [creatorId, resourceType, getUserWorkflowsFn, queryInitiatedByPersonId, selectedClientTaskFilters],
  );

  const handleNewPage = (newPage: number): void => {
    fetchWorkflows({
      page: newPage,
      search: searchTerm,
      statuses: selectedStatuses,
    });
  };

  useEffect(() => {
    const getCounts = async (): Promise<void> => {
      if (creatorId && resourceType === 'activity') {
        const getParams = {
          creatorId,
          initiatedByPersonId: queryInitiatedByPersonId,
          page: 1,
          perPage: 1,
          search: searchTerm,
        };
        const [completedResponse, archivedResponse, pausedResponse,
          dataCollectionResponse, filedAndPendingResponse, preparingCaseResponse] = await Promise.all([
          getUserWorkflowsFn({
            completed: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            archived: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            paused: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            dataCollection: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            filedAndPending: true,
            ...getParams,
          }),
          getUserWorkflowsFn({
            preparingCase: true,
            ...getParams,
          }),
        ]);
        setNumCompletedUserWorkflows(completedResponse?.data?.numItems ?? 0);
        setNumArchivedUserWorkflows(archivedResponse?.data?.numItems ?? 0);
        setNumPausedUserWorkflows(pausedResponse?.data?.numItems ?? 0);
        setNumDataCollectionUserWorkflows(dataCollectionResponse?.data?.numItems ?? 0);
        setNumFiledAndPendingUserWorkflows(filedAndPendingResponse?.data?.numItems ?? 0);
        setNumPreparingCaseUserWorkflows(preparingCaseResponse?.data?.numItems ?? 0);
      } else {
        setNumCompletedUserWorkflows(0);
        setNumArchivedUserWorkflows(0);
        setNumPausedUserWorkflows(0);
        setNumDataCollectionUserWorkflows(0);
        setNumFiledAndPendingUserWorkflows(0);
        setNumPreparingCaseUserWorkflows(0);
      }
    };
    getCounts();
  }, [getUserWorkflowsFn, searchTerm, resourceType, creatorId, selectedId, queryInitiatedByPersonId]);

  useEffect(() => {
    fetchWorkflows({
      page: 1,
      search: searchTerm,
      statuses: selectedStatuses,
    });
  }, [fetchWorkflows, searchTerm, selectedStatuses]);

  useEffect(() => {
    const needProgressIds = userWorkflows
      .filter(userWorkflow => hasAssignedTasksByUserWorkflow[userWorkflow.id] === undefined)
      .map(userWorkflow => userWorkflow.id);

    Promise.all(
      needProgressIds.map(async userWorkflowId => {
        setHasAssignedTasksByUserWorkflow(prev => ({ ...prev, [userWorkflowId]: 'fetching' }));
        const response = await getUserWorkflowProgressFn({ userWorkflowId });
        if (response.data) {
          const responseData = response.data;
          setHasAssignedTasksByUserWorkflow(prev => ({ ...prev, [userWorkflowId]: responseData.hasAssignedTasks }));
        }
      }),
    );
    // intentionally not looking at hasAssignedTasksByUserWorkflow, only do this if the userWorkflows changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userWorkflows, getUserWorkflowProgressFn]);

  const pagination = {
    numPages,
    onChangePage: handleNewPage,
    page: currentPage,
  };

  const filterItems = useMemo(() => {
    const statuses: Array<{
      isChecked: boolean;
      key: Status;
      label: string;
    }> = [
      {
        isChecked: selectedStatuses.includes('data-collection'),
        key: 'data-collection',
        label: `Data Collection (${numDataCollectionUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('preparing-case'),
        key: 'preparing-case',
        label: `Preparing Cases (${numPreparingCaseUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('filed-and-pending'),
        key: 'filed-and-pending',
        label: `Filed and Pending (${numFiledAndPendingUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('completed'),
        key: 'completed',
        label: `Completed (${numCompletedUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('paused'),
        key: 'paused',
        label: `Paused (${numPausedUserWorkflows})`,
      },
      {
        isChecked: selectedStatuses.includes('archived'),
        key: 'archived',
        label: `Archived (${numArchivedUserWorkflows})`,
      },
    ];
    return statuses;
  }, [
    selectedStatuses,
    numArchivedUserWorkflows,
    numCompletedUserWorkflows,
    numDataCollectionUserWorkflows,
    numFiledAndPendingUserWorkflows,
    numPausedUserWorkflows,
    numPreparingCaseUserWorkflows,
  ]);

  const clientTaskFilterItems = useMemo(() => {
    const clientTaskTypes: Array<{
      isChecked: boolean;
      key: string;
      label: string;
    }> = [
      {
        isChecked: selectedClientTaskFilters.includes('document-request-user'),
        key: 'document-request-user',
        label: `Upload Document Requests`,
      },
      {
        isChecked: selectedClientTaskFilters.includes('custom-terms'),
        key: 'custom-terms',
        label: `Retainer Term Requests`,
      },
      {
        isChecked: selectedClientTaskFilters.includes('form-request'),
        key: 'form-request',
        label: `Form Requests`,
      },
      {
        isChecked: selectedClientTaskFilters.includes('invoice'),
        key: 'invoice',
        label: `Invoice Requests`,
      },
    ];
    return clientTaskTypes;
  }, [
    selectedClientTaskFilters,
  ]);

  const filters: NonNullable<HookResponse['filters']> = {
    items: [
      {
        isMultiSelect: true,
        items: filterItems,
        label: 'Status',
        onChange: (newStatuses) => setSelectedStatuses(newStatuses as Status[]),
      },
      {
        isMultiSelect: true,
        items: clientTaskFilterItems,
        label: 'Pending Client Tasks',
        onChange: (newFilters) => {
          if (newFilters.includes('custom-terms')) {
            setCustomTermsFilterValue('true');
          } else {
            setCustomTermsFilterValue('false');
          }

          if (newFilters.includes('document-request-user')) {
            setDocumentRequestUserFilterValue('true');
          } else {
            setDocumentRequestUserFilterValue('false');
          }

          if (newFilters.includes('form-request')) {
            setFormRequestFilterValue('true');
          } else {
            setFormRequestFilterValue('false');
          }

          if (newFilters.includes('invoice')) {
            setInvoiceFilterValue('true');
          } else {
            setInvoiceFilterValue('false');
          }
        },
      },
    ],
  };

  const userWorkflowsWithStatusSelected = userWorkflows.filter(userWorkflow => {
    if (selectedStatuses.includes(userWorkflow.status) || selectedStatuses.length === 0) {
      return userWorkflow;
    }
    return null;
  });

  const tableRows = useMemo(() => userWorkflowsWithStatusSelected.map(userWorkflow =>
    userWorkflowToTableRow({
      hasAssignedTasksByUserWorkflow,
      userWorkflow,
    }),
  ), [userWorkflowsWithStatusSelected, hasAssignedTasksByUserWorkflow]);

  return {
    filters,
    getDetailPagePath,
    ifNoItemsText: 'No activity',
    isFetchingList: isFetching,
    items: [],
    itemsCount,
    onSearch: setSearchTerm,
    pagination,
    selectedId,
    tableView: useTableView
      ? {
        columns: TABLE_COLUMNS,
        rows: tableRows,
      }
      : undefined,
  };
};

export default useActivity;
