import { useMemo } from 'react';
import * as ApiModels from '@typings/api-models';
import { Select } from '@/components/DesignLibrary/Atoms';

type Props = {
  context: Pick<ApiModels.WorkflowContext, 'id' | 'options' | 'isOptional'>;
  value: string;
  onChange: (key: string) => void;
};

const AddContextSelection: React.FC<Props> = ({ context, onChange, value }) => {
  const selectOptions = useMemo(() =>
    context.options?.map(opt => ({ key: opt.slug, label: opt.label })) ?? [],
  [context.options]);
  return (
    <div>
      <Select
        label={''}
        hideLabel
        options={selectOptions}
        value={value}
        onChange={onChange}
        optional={context.isOptional}
      />
    </div>
  );
};

export default AddContextSelection;