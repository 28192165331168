import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { UsersThree, VideoCamera } from '@phosphor-icons/react';
import InputField from '@components/FormFields/InputField';
import PriceInput from '@components/FormFields/PriceInput';
import TiersContent from '@components/TiersContent';
import ThumbnailInput from '@lib/HandbookProduct/HandbookPriceModal/ThumbnailInput/ThumbnailInput';
import Button from '@components/DesignLibrary/Button';
import uploadLogo from '@helpers/uploadLogo';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import { NoodleProductTypes } from '@typings/graphql-models';
import { Collections, CreateMembership } from '@components/CreateMembershipModal/types';
import Divider from '@components/Divider';
import s from './CreateMembershipForm.module.scss';

type Props = {
  onSubmit: (data: CreateMembership) => void;
  passedTitle?: string;
  currency: Parameters<typeof PriceInput>[0]['currency'];
};

const CreateMembershipForm = ({ currency, onSubmit, passedTitle }: Props): ReactElement => {
  const { collections, fetchProducts } = useContext(DashboardContext);
  const collectionInitialState = (collections || [])
    .filter(e => e.noodleProductType === NoodleProductTypes.Handbook)
    .map(byte => ({ ...byte, selected: true }));

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [broadcasts, setBroadcasts] = useState(true);

  const [title, setTitle] = useState(passedTitle || '');
  const [tierTitle, setTierTitle] = useState('');
  const [description, setDescription] = useState('');
  const [monthlyPrice, setMonthlyPrice] = useState(0);
  const [yearlyPrice, setYearlyPrice] = useState(0);
  const [photoFile, setPhotoFile] = useState<string | null>(null);
  const [imageId, setImageId] = useState('');
  const [error, setError] = useState(false);
  const [selectedCollections, setCollections] = useState<Collections>(collectionInitialState);

  const isValidForm = title && tierTitle && monthlyPrice && yearlyPrice;

  const updateAvatar = async (newPhotoFile: string): Promise<void> => {
    setPhotoFile(newPhotoFile);
    const response = await uploadLogo(newPhotoFile);
    if (response) {
      setImageId(response);
      setError(false);
    }
  };

  useEffect(() => {
    if (isValidForm && error) {
      setError(false);
    }
  }, [title, tierTitle, monthlyPrice, yearlyPrice, imageId, selectedCollections]);

  const handleSubmit = async (): Promise<void> => {
    if (isValidForm) {
      setIsLoading(true);
      await onSubmit({
        description,
        imageId,
        includesBroadcasts: broadcasts,
        monthlyPrice: monthlyPrice.toString(),
        productTypes: selectedCollections.map(p => ({ id: p.id })),
        tierTitle,
        title,
        yearlyPrice: yearlyPrice.toString(),
      });
      fetchProducts();
      setIsLoading(false);
    } else {
      setError(true);
    }
  };

  return (
    <div className={s['form-container']}>
      <div className={s['form-container__title-container']}>
        <p>Create your </p>
        <div className={s['form-container__title-container__icon']}>
          <UsersThree weight="fill" />
          <p> Membership</p>
        </div>
      </div>
      <ThumbnailInput
        accept='video/*,image/*'
        onChange={updateAvatar}
        file={photoFile}
        className={s['margin-bottom']}
        icon={<VideoCamera weight="fill" color="#006DFF" size={24} />}
        title={'Add a Welcome Video or Membership Thumbnail'}
      />
      <InputField
        id="title"
        name="title"
        placeholder="My Community"
        label="Membership Title"
        values={{ title }}
        onChange={setTitle}
        className={error && !title.length ? s.error : undefined}
        hasFixedHeight={false}
      />
      <Divider dashed />
      <div>
        <p className={s['form-container__tiers-title']}>Tiers</p>
        <p className={s['form-container__tiers-description']}>
          Noodle currently supports a single tier membership on your plan. Chat with Noodle support if you’re interested in adding more tiers.
        </p>
        <InputField
          id="tier-title"
          name="tier-title"
          placeholder="All Access Membership"
          label="Name of Membership Tier"
          values={{ tierTitle }}
          onChange={setTierTitle}
          className={error && !tierTitle.length ? s.error : undefined}
        />
        <div className={s['form-container__tiers-prices']}>
          <PriceInput
            name=""
            onChange={setMonthlyPrice}
            currency={currency}
            value={monthlyPrice}
            isMonthly
            className={error ? s.error : undefined}
          />
          <PriceInput
            name=""
            onChange={setYearlyPrice}
            currency={currency}
            value={yearlyPrice}
            isYearly
            className={error ? s.error : undefined}
          />
        </div>
        <TiersContent broadcasts={broadcasts} setBroadcasts={setBroadcasts} selected={selectedCollections} setCollections={setCollections} />
        {error && collectionInitialState.length > 0 && !selectedCollections.some(e => e.selected) && (
          <p style={{ color: 'red' }}>Select at least one option</p>
        )}
      </div>
      <InputField
        id="description"
        name="description"
        rows={4}
        placeholder="Get access to all my broadcast messages and digital byte content!"
        label="Describe what you get as a member of this tier:"
        values={{ description }}
        onChange={setDescription}
        className={error && !description.length ? s.error : undefined}
      />
      <Button fullWidth variant='primary' size='lg' onClick={handleSubmit} loading={isLoading}>
        Save
      </Button>
    </div>
  );
};
export default CreateMembershipForm;
