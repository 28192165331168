import { FC, useState, useEffect, useContext } from 'react';
import Search from '@components/DesignLibrary/Atoms/Search';
import ListCell from '@components/DesignLibrary/ListCell';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import uniqBy from 'lodash/uniqBy';
import Button from '@components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import PlusCircle from '@components/Icons/PlusCircle';
import ProgressIndicator from '@components/ProgressIndicator';
import TextInput from '@components/FormFields/TextInput';
import EmailInput from '@components/FormFields/EmailInput';
import PhoneInput from '@components/FormFields/PhoneInput';
import { useToast } from '@hooks';
import { ToastTypeVariants } from '@context/ToastContext';
import PaginationObserver from '@components/PaginationObserver';
import * as ApiModels from '@typings/api-models';
import teamsContext from '@providers/Teams/TeamsContext';
import s from './InitiateCaseModal.module.scss';

type Props = {
  creatorSlug: string;
  onCustomerSelect: (newCustomer: Pick<ApiModels.Person, 'id' | 'name' | 'email'>) => void;
  setIsCreatingNewCustomer: (c: boolean) => void;
  selectedCustomer?: Pick<ApiModels.Person, 'id' | 'name' | 'email'> | null;
};

const PAGE_SIZE = 25;

const SelectCustomer: FC<Props> = ({ creatorSlug, onCustomerSelect, setIsCreatingNewCustomer, selectedCustomer: existingCustomer }) => {
  const [search, setSearch] = useState('');
  const [isCreate, setIsCreate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState<Pick<ApiModels.Person, 'id' | 'name' | 'email'> | null>(existingCustomer || null);
  const [customers, setCustomers] = useState<Awaited<ReturnType<typeof tsClient.getMembers>>['items'][number][]>([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [nameError, setNameError] = useState<string | null>(null);
  const [numCustomers, setNumCustomers] = useState(0);
  const [isFirstPageLoaded, setFirstPageLoaded] = useState(false);
  const [hasAttemptedToSubmit, setHasAttemptedToSubmit] = useState(false);

  const addToast = useToast();

  const { creatorId } = useContext(teamsContext);

  const { getData: findOrCreatePersonFn } = useNoodleApi(tsClient.findOrCreatePerson);
  const { getData: getMembersFn, fetchingState: { isFetching: isFetchingMembers } } = useNoodleApi(tsClient.getMembers);
  const { getData: isTeamMemberFn } = useNoodleApi(tsClient.creators.isTeamMember);

  const handleSearchChange = (newSearch: string): void => {
    setSearch(newSearch);
    setPage(1);
    setCustomers([]);
  };

  useEffect(() => {
    setIsCreatingNewCustomer(isCreate);
  }, [setIsCreatingNewCustomer, isCreate]);

  useEffect(() => {
    setPage(1);
    setCustomers([]);
  }, [creatorSlug]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const { data: newCustomers } = await getMembersFn({
        creatorSlug,
        page,
        perPage: PAGE_SIZE,
        search,
      });
      if (newCustomers) {
        setCustomers(prev => uniqBy([...(page === 1 ? [] : prev), ...newCustomers.items], 'id'));
        setFirstPageLoaded(true);
        setNumCustomers(newCustomers.numItems);
      }
    };
    fetchData();
  }, [page, search, creatorSlug, getMembersFn]);

  const handleSubmit = async (): Promise<void> => {
    if (!creatorId) {
      addToast(ToastTypeVariants.ERROR, 'Unable to determine creator team. Please try refreshing the page or contact support.');
      return;
    }
    if (!isCreate && selectedCustomer) {
      onCustomerSelect(selectedCustomer);
    } else if (isCreate) {
      setHasAttemptedToSubmit(true);
      setIsSubmitting(true);
      if (emailError || nameError || phoneError) {
        setIsSubmitting(false);
        return;
      }
      const { data: teamMemberResponse } = await isTeamMemberFn({
        creatorId,
        email,
        phone,
      });
      if (teamMemberResponse?.isTeamMember) {
        addToast(ToastTypeVariants.ERROR, 'Contact is a member of your team. Please use a different email/phone number that is not already in use.');
        setIsSubmitting(false);
        return;
      }
      const { data } = await findOrCreatePersonFn({
        creatorSlug,
        identifier: email,
        name,
        phoneNumber: phone,
      });

      if (data?.person) {
        onCustomerSelect({
          email,
          ...data.person,
        });
      } else {
        addToast(ToastTypeVariants.ERROR, 'Failed to create contact. Please try again, check the email, or contact support.');
      }
      setIsSubmitting(false);
    }
  };

  const handleFetchNextPage = (): void => {
    if (isFetchingMembers) {
      return;
    }
    setPage(prev => prev + 1);
  };

  const isPaginationEnabled = isFirstPageLoaded && numCustomers !== customers.length;

  return (
    <div className={s.customerSelect}>
      <div className={s.createButtonContainer}>
        <Button size='lg' variant='secondary' fullWidth onClick={() => setIsCreate(prev => !prev)}>
          <div className={s.createButtonContent}>
            {isCreate
              ? (
                <p className='body-sm-bold' style={{ color: 'var(--color-noodle)' }}>Select existing contact</p>
              )
              : (
                <>
                  <PlusCircle size={24} weight='fill' color='var(--color-noodle)' />
                  <p className='body-sm-bold' style={{ color: 'var(--color-noodle)' }}>Create new contact</p>
                </>
              )}
          </div>
        </Button>
      </div>
      {isCreate
        ? (
          <div className={s.createCustomerForm}>
            <TextInput
              id='name'
              value={name}
              onChange={newName => setName(newName)}
              label='Name'
              placeholder='Name'
              required
              isTouched={hasAttemptedToSubmit}
              onError={setNameError}
              error={nameError}
            />
            <EmailInput
              id='email'
              value={email}
              onChange={newEmail => setEmail(newEmail)}
              label='Email'
              placeholder='Email'
              required
              isTouched={hasAttemptedToSubmit}
              onError={setEmailError}
              error={emailError}
            />
            <PhoneInput
              id='phone'
              value={phone}
              onChange={setPhone}
              label='Phone'
              isTouched={hasAttemptedToSubmit}
              onError={setPhoneError}
              error={phoneError}
            />
          </div>
        )
        : (
          <>
            <Search
              onChange={handleSearchChange}
            />
            {page === 1 && isFetchingMembers && <ProgressIndicator isCentered />}
            {customers.map(customer => (
              <ListCell
                key={customer.id}
                onClick={() => setSelectedCustomer(customer)}
                isSelected={selectedCustomer?.id === customer.id}
                showCheckbox
                avatar={{
                  color: customer.primaryColour?.hex,
                  name: customer.name || '',
                  url: customer.image?.url,
                }}
                bottomLine={customer.email}
                label={customer.name || ''}
              />
            ))}
            {isPaginationEnabled && <PaginationObserver showSpinner intersectTime={1000} isDisabled={false} loadFn={handleFetchNextPage} />}
          </>
        )}
      <Spacer size='72px' />
      <div className={s.buttonContainer}>
        <Button loading={isSubmitting} variant='primary' size='md' fullWidth onClick={handleSubmit}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default SelectCustomer;
