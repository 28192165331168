import { NoodleProductTypes } from '@typings/api-models';

const CREATABLE_NOODLE_PRODUCT_TYPES = [
  NoodleProductTypes.InPersonSession,
  NoodleProductTypes.OnlineSession,
  NoodleProductTypes.Lite,
  NoodleProductTypes.Handbook,
  NoodleProductTypes.Collection,
  NoodleProductTypes.Subscription,
  NoodleProductTypes.Consultation,
] as const;

export type AllowedNoodleProductTypes = typeof CREATABLE_NOODLE_PRODUCT_TYPES[number];

export const isCreatable = (thisService: NoodleProductTypes): boolean =>
  (CREATABLE_NOODLE_PRODUCT_TYPES as unknown as NoodleProductTypes[]).includes(thisService);
