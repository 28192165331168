import { FC, useContext } from 'react';
import teamsContext from '@providers/Teams/TeamsContext';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import UserImage from '@components/UserImage';
import { useUserProfile } from '@providers/Auth';
import Spacer from '@components/Spacer';
import Buttons from '@components/Buttons';
import s from './TeamMemberSwitcher.module.scss';

type Props = {
  closeModal: () => void;
};

const TeamMemberSwitcher: FC<Props> = ({ closeModal }) => {
  const profile = useUserProfile();
  const { currentTeamOwner, teams, teamUserId, setTeamUserId } = useContext(teamsContext);
  const { setTeamsModalOpen } = useContext(DashboardContext);

  if (!currentTeamOwner || !profile || (teams.length === 1 && profile.id === currentTeamOwner.person.id)) {
    return null;
  }
  return (
    <div className={s.container}>
      <Buttons isWrapper className={s.top} onClick={() => {
        setTeamsModalOpen(true);
        closeModal();
      }}>
        <p className={s.teamName}>
          {currentTeamOwner.name}&apos;s team
          <br />
          {profile.id !== currentTeamOwner.person.id && <span>Available roles</span>}
        </p>
        {teams.length > 1 ? <span className={s.switchTeam}>Switch team ({navigator.userAgent.includes('Mac') ? '⌘' : 'Ctrl'}+K)</span> : <div />}
      </Buttons>
      {profile.id !== currentTeamOwner.person.id && (
        <div className={s.users}>
          <label>
            <input
              type="radio"
              name="role"
              value={currentTeamOwner.person.id}
              defaultChecked={teamUserId === currentTeamOwner.person.id}
              onClick={() => {
                setTeamUserId(currentTeamOwner.person.id);
                closeModal();
              }}
            />
            <UserImage url={currentTeamOwner.person.image} name={currentTeamOwner.name} color={currentTeamOwner.primaryColour?.hex} size={24} />
            <Spacer size="12px" />
            <p>{currentTeamOwner.name}</p>
          </label>
          <label>
            <input
              type="radio"
              name="role"
              value={profile.id}
              defaultChecked={teamUserId === profile.id}
              onClick={() => {
                setTeamUserId(profile.id);
                closeModal();
              }}
            />
            <UserImage url={profile.image} name={profile.name} size={24} />
            <Spacer size="12px" />
            <p>{profile.name}</p>
          </label>
        </div>
      )}
    </div>
  );
};

export default TeamMemberSwitcher;
