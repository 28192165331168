import React, { FC } from 'react';
import Button from '@components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import classNames from 'classnames';
import { useToast } from '@hooks';
import { SELF_ORIGIN } from '@configuration/client';
import { IBM_Plex_Mono as ibmPlexMono } from 'next/font/google';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import s from './WidgetSnippetForm.module.scss';

type Props = {
  creatorSlug: string;
  creatorColor: string;
  creatorImage?: string;
};

const mono = ibmPlexMono({
  subsets: ['latin'],
  weight: '400',
});

const WidgetSnippetForm: FC<Props> = ({ creatorSlug, creatorColor, creatorImage }) => {
  const addToast = useToast();
  const snippet = `<script async data-creator-slug="${creatorSlug}" data-creator-color="${creatorColor}"${creatorImage ? ` data-creator-image="${creatorImage}"` : ''} src="${SELF_ORIGIN}/popover-widget.js"></script>`;
  const copyCodeSnippet = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(snippet);
      addToast(useToast.ToastTypeVariants.SUCCESS, 'Copied snippet!');
    } catch {
      addToast(useToast.ToastTypeVariants.ERROR, 'Failed to copy snippet');
    }
  };

  return (
    <div className={s['widget-snippet']}>
      <div className={s['widget-snippet_content']}>
        <p className={classNames('caption', s['widget-snippet_add_text'])}>Add this snippet to the header of your website</p>
        <SyntaxHighlighter
          language="javascript"
          customStyle={{
            backgroundColor: 'var(--color-gray-0)',
            borderRadius: 4,
            fontSize: 14,
            margin: 0,
          }}
          wrapLongLines
          codeTagProps={{ style: mono.style }}
        >{snippet}</SyntaxHighlighter>
      </div>
      <Spacer size={16}/>
      <Button
        variant='primary'
        size='md'
        fullWidth
        onClick={copyCodeSnippet}
      >
        Copy the code
      </Button>
    </div>
  );
};

export default WidgetSnippetForm;
