import { FC, useContext, useState } from 'react';
import * as ApiModels from '@typings/api-models';
import Button from '@components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { useRouter } from 'next/router';
import UserImage from '@components/UserImage';
import * as format from '@format';
import { Maybe } from '@typings/graphql-models';
import { CalendarBlank, Clock } from '@phosphor-icons/react';
import { useGAClientId } from '@providers/GoogleAnalytics';
import { getUTMParams } from '@helpers/analytics';
import { CartItem } from '@providers/ShoppingCart';
import { useToast } from '@hooks';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import s from './InitiateBookingModal.module.scss';

type ProductPrice = (Pick<ApiModels.Price, 'sessionDuration' | 'sessionInterval'> &
  CartItem & {
    priceDescription?: {
      html?: string | null;
    } | null;
  });

type Product = Pick<ApiModels.Product, 'id' | 'title' | 'isActive'> & {
  productTypes: Array<Pick<ApiModels.ProductType, 'noodleProductType' | 'id' | 'title'>>;
  prices: Array<Pick<ApiModels.Price,
    'price' | 'id' | 'sessionDuration' | 'sessionInterval' |"id" |
    "currency" | "numBookings" | "price" | "priceSubDescription" |
    "priceTitle" | "recurringInterval" | "sessionDuration" | "sessionInterval" | "sessionTime"
  > & ProductPrice>;
  creator?: {
    name?: string | null;
  } | null;
};
type Price = Product['prices'][0];

type TimeSlotProduct = {
  price: Price, sessionDuration: number, sessionTime: string
};

type Props = {
  customer: Pick<ApiModels.Person, 'id' | 'name' | 'email'> & {
    primaryColour?: { hex: string } | null;
    image?: { url: string } | null;
  };
  product: Pick<ApiModels.Product, 'id' | 'title'>;
  onClose: () => void;

  selectedTimeSlotProduct: TimeSlotProduct;
  selectedTeamMemberPersonId: string;
  creatorTimezone: Maybe<string>;
};

const Review: FC<Props> = ({
  customer,
  product,
  onClose,
  selectedTimeSlotProduct,
  selectedTeamMemberPersonId,
  creatorTimezone,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const gaClientId = useGAClientId();
  const router = useRouter();
  const { getData: buyFreeProductFn } = useNoodleApi(tsClient.buyFreeProduct);
  const addToast = useToast();
  const { triggerRefetchBooking } = useContext(DashboardContext);

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);

    const { error: buyFreeProductError } = await buyFreeProductFn({
      gaClientId: gaClientId as string,
      personId: customer.id,
      priceId: selectedTimeSlotProduct.price.id,
      productId: selectedTimeSlotProduct.price?.product.id,
      sessionDuration: selectedTimeSlotProduct.sessionDuration,
      sessionTime: selectedTimeSlotProduct.sessionTime,
      source: Array.isArray(router.query.source) ? router.query.source[0] : router.query.source,
      teamMemberPersonId: selectedTeamMemberPersonId,
      utmParams: {
        ...getUTMParams(router.query),
      },
      workflowReferenceId: null,
    });

    if (buyFreeProductError) {
      addToast(useToast.ToastTypeVariants.INFO, 'Cannot purchase consultation product.');
    } else {
      addToast(useToast.ToastTypeVariants.INFO, 'Booking created.');
    }

    triggerRefetchBooking();
    setIsSubmitting(false);
    onClose();
  };
  return (
    <div className={s.review}>
      <div className={s.content}>
        <p className="body-lg" style={{ fontWeight: 700, textAlign: 'center' }}>
          {selectedTimeSlotProduct.sessionDuration} min {' '}
          {product.title}<br />
        </p>
        <Spacer size="16px" />
        <p className="caption" style={{ color: 'var(--color-gray-75)', textAlign: 'center' }}>
          Customer
        </p>
        <Spacer size="8px" />
        <div className={s.customer}>
          <UserImage size={24} color={customer.primaryColour?.hex} name={customer.name} url={customer.image?.url} />
          <p className="body-sm">{customer.name}</p>
        </div>
        <Spacer size="16px" />
      </div>
      <div style={{
        border: '1px dashed var(--color-gray-25)',
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: 16,
      }}>
        <p className='caption'>Date and Time:</p>
        <div className='body-md' style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
        }}>
          <div
            style={{
              alignItems: "center",
              color: "var(--color-gray-75)",
              display: "flex",
              flexDirection: "row",
              fontSize: 12,
              gap: 8,
            }}
          >
            <CalendarBlank
              style={{
                height: 16,
                width: 16,
              }}
              color="var(--color-gray-75)"
              fill="var(--color-gray-75)"
            />
            {format.datetime.withYear(selectedTimeSlotProduct.sessionTime)}
          </div>
          <div
            style={{
              alignItems: "center",
              color: "var(--color-gray-75)",
              display: "flex",
              flexDirection: "row",
              fontSize: 12,
              gap: 8,
            }}
          >
            <Clock
              style={{
                height: 16,
                width: 16,
              }}
              color="var(--color-gray-75)"
              fill="var(--color-gray-75)"
            />
            {format
              .datetime
              .durationWithoutTimezone({
                datetime: selectedTimeSlotProduct.sessionTime,
                durationInMinutes: selectedTimeSlotProduct.sessionDuration,
              })
            }
            {" "}
            {creatorTimezone && format.datetime.unabridgeTimezone({ timezone: creatorTimezone })}
          </div>
        </div>
      </div>
      <Spacer size="16px" />
      <Button variant="primary" size="md" fullWidth loading={isSubmitting} onClick={handleSubmit}>
        Initiate Booking
      </Button>
    </div>
  );
};

export default Review;
