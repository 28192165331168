import React from 'react';
import ReactCountryFlag from "react-country-flag";
import s from './CreatorFlag.module.scss';

type Props = {
  countryCode: string;
};

const CreatorFlag: React.FC<Props> = ({ countryCode }) => (
  <div className={s['creator-flag']}>
    <ReactCountryFlag countryCode={countryCode} svg style={{marginTop: '-2px'}} alt={countryCode} />
  </div>
);

export default CreatorFlag;