import { FC } from 'react';
import Spacer from '@components/Spacer';
import { pickProductIcon } from '@components/ProductIcon';
import Buttons from '@components/Buttons/Buttons';
import * as ApiModels from '@typings/api-models';
import ProgressIndicator from '@components/ProgressIndicator';
import CollapsibleArea from '@components/CollapsibleArea';
import s from './InitiateBookingModal.module.scss';

type Props = {
  fullProducts: Array<
    Pick<ApiModels.Product, 'id' | 'title'> & {
      productTypes: Array<Pick<ApiModels.ProductType, 'noodleProductType' | 'id' |'title'>>;
    }
  >;
  onConsultationSelect: (workflowId: string, productId: string) => void;
  isFetching: boolean;
};

const SelectWorkflow: FC<Props> = ({ isFetching, fullProducts, onConsultationSelect }) => (
  <div className={s.consultationSelect}>
    {isFetching
      ? (
        <ProgressIndicator isCentered />
      )
      : (
        <>
          <p className="caption" style={{ color: 'var(--color-gray-75)' }}>
          Select consultation
          </p>
          <Spacer size="12px" />
          <div className={s.products}>
            {fullProducts.length === 0 && <p className="body-sm">You have no consultations that are available to initiate.</p>}
            {fullProducts.map(product => {
              const Icon = pickProductIcon(product.productTypes?.[0]?.noodleProductType);
              return (
                <CollapsibleArea
                  title={product.title as string}
                  key={product.id}
                  Icon={Icon ? { Component: Icon, color: 'var(--color-noodle)' } : undefined}
                  className={s.details}
                >
                  <Spacer size="16px" />
                  <div className={s.consultations}>
                    {product.productTypes.map(productType => (
                      <Buttons key={productType.id} isWrapper onClick={() => onConsultationSelect(productType.id, product.id)}>
                        <div className={s.consultation}>
                          <p className="caption">{productType.title}</p>
                          <p className="caption-bold" style={{ color: 'var(--color-noodle)' }}>
                          Initiate
                          </p>
                        </div>
                      </Buttons>
                    ))}
                  </div>
                </CollapsibleArea>
              );
            })}
          </div>
        </>
      )}
  </div>
);

export default SelectWorkflow;
