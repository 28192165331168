import React, { FC, useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import { DEFAULT_CURRENCY } from '@typings/common';
import InvoiceSettings from '@components/InvoiceSettings';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import SelectInvoiceTemplate from '@components/SelectInvoiceTemplate';
import Spacer from '@components/Spacer';
import { PaymentPlan } from '@/typings/api-models';
import s from './CreateInvoice.module.scss';

export type InvoiceData = Parameters<Parameters<typeof InvoiceSettings>[0]['onSave']>[0]['invoiceData'] & {
  id: string;
  templateId: string | null;
  lineItems: Parameters<Parameters<typeof InvoiceSettings>[0]['onSave']>[0]['lineItems'];
  paymentMethodId: string | null;
  initialPaymentAmount?: number;
  paymentPlanFrequency?: PaymentPlan['frequency'];
  nextPaymentDate?: string | null;
  isExemptFromApplicationFee: boolean;
  createPaymentPlan?: boolean;
} & { expressionId?: string | null };

type Props = {
  invoiceData: InvoiceData[];
  invoiceTemplateIndex: number;
  onSubmit: (d: {
    data: InvoiceData;
    index: number;
  }) => void;
};

const CreateInvoice: FC<Props> = ({ invoiceData, invoiceTemplateIndex, onSubmit }) => {
  const { creator } = useContext(dashboardContext);

  const [invoice, setInvoice] = useState(invoiceData[invoiceTemplateIndex]);

  useEffect(() => {
    setInvoice(invoiceData[invoiceTemplateIndex]);
  }, [invoiceData, invoiceTemplateIndex]);

  if (!creator) {
    return null;
  }

  const handleSubmit: Parameters<typeof InvoiceSettings>[0]['onSave'] = async ({ invoiceData: newData, lineItems }) => {
    onSubmit({
      data: {
        ...newData,
        expressionId: invoice.expressionId,
        id: invoice.id,
        lineItems,
        paymentMethodId: null,
        templateId: invoice.templateId,
      },
      index: invoiceTemplateIndex,
    });
  };

  const handleSelectInvoice: Parameters<typeof SelectInvoiceTemplate>[0]['onSelect'] = (invoiceTemplate) => {
    setInvoice({
      ...invoiceTemplate,
      id: nanoid(), // need a new id so that the same template can be selected multiple times.
      paymentMethodId: null,
      templateId: invoiceTemplate.id,
    });
  };

  return (
    <div>
      <Spacer size={8} />
      <div className={s.padding}>
        <SelectInvoiceTemplate initialValue={invoice.templateId ?? undefined} onSelect={handleSelectInvoice} />
      </div>
      <InvoiceSettings
        currency={creator?.defaultCurrency || DEFAULT_CURRENCY}
        initialValue={invoice || undefined}
        onSave={handleSubmit}
        cta='Next'
        requireLineItems
      />
    </div>
  );
};

export default CreateInvoice;
