import Buttons from '@components/Buttons';
import Modal from '@components/Modal';
import ChatsCircle from '@components/Icons/ChatsCircle';
import LightBulb from '@components/Icons/Lightbulb';
import Presentation from '@components/Icons/Presentation';
import React, { useState } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import { getCannyToken } from '@tsClient';
import { useRouter } from 'next/router';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import { CANNY_COMPANY_ID, NOODLE_CREATOR_SLUG } from '@configuration/client';
import { ToastTypeVariants } from '@context/ToastContext';
import s from './SupportModal.module.scss';

const SupportModal = ({ onClose }: { onClose: () => void }): React.ReactElement => {
  const router = useRouter();
  const { getData } = useNoodleApi(getCannyToken, {
    toastErrorMessage: () => [ToastTypeVariants.ERROR, 'There was an error with the Canny login'],
    toastOnError: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleRouteToBookDemo = (): void => {
    setIsLoading(true);
    router.push("https://noodle.shop/creator-success/brainstorming-call/purchase?priceId=clhz7bxzudwjv0bn2bvuyeqvn");
    setIsLoading(false);
  };

  const handleOpenCannyFeatureRequest = async (): Promise<void> => {
    const { data } = await getData();
    if (data?.token) {
      window.open(
        `https://canny.io/api/redirects/sso?companyID=${CANNY_COMPANY_ID}&ssoToken=${data.token}&redirect=https://noodleshopsinc.canny.io/feature-requests`,
        '_top',
      );
    }
  };

  const handleRouteToNoodleConversation = (): void => {
    setIsLoading(true);
    router.push(getUrl(IDENTIFIERS.DASHBOARD_CHAT_WITH_CREATOR, { creatorSlug: NOODLE_CREATOR_SLUG }));
    setIsLoading(false);
  };

  return (
    <Modal hideBottomInset title={'Get Support'} onClose={onClose} >
      <div className={s.support}>
        <Buttons isShimmering={isLoading} className={s.support_button} onClick={handleRouteToBookDemo}>
          <Presentation size={28} weight="fill" />
          <p>Get a free demo</p>
        </Buttons>
        <Buttons isShimmering={isLoading} className={s.support_button} onClick={handleRouteToNoodleConversation}>
          <ChatsCircle size={28} weight="fill" />
          <p>Chat with seller support</p>
        </Buttons>
        <Buttons isWrapper className={s.support_button_feature} onClick={handleOpenCannyFeatureRequest}>
          <LightBulb size={16} weight="fill" />
          <p>Submit a feature request</p>
        </Buttons>
      </div>
    </Modal>
  );
};

export default SupportModal;
