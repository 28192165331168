import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryState } from 'nuqs';
import { nanoid } from 'nanoid';
import { Price, Product } from '@typings/graphql-models';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Calendar from '@components/Calendar';
import CustomerTimeSelector from '@components/CustomerTimeSelector';
import { CartItem } from '@providers/ShoppingCart';
import * as format from '@format';
import { format as formatDate, addMinutes } from 'date-fns';
import DOMPurify from 'isomorphic-dompurify';

import Spacer from '@components/Spacer';
import { Booking } from '@typings/api-models';

import s from './CustomerSchedulingCalendar.module.scss';

type ProductPrice = (Pick<Price, 'sessionDuration' | 'sessionInterval'> &
CartItem & {
  priceDescription?: {
    html?: string | null;
  } | null;
});

type ThisProduct = Pick<Product, 'id'> & {
  prices: ProductPrice[];
  creator?: {
    name?: string | null;
  } | null;
};

type Props = {
  product: ThisProduct;
  selectedDate?: string | null;
  isRescheduling?: boolean;
  booking?: Pick<Booking, 'id' | 'startAt' | 'priceId'>;
  availabilityTimezone: string | null;
  onFinish?: (price: CartItem) => Promise<void>;
  onDateTimeSelect?: (props: { price: CartItem; sessionDuration: number; sessionTime: string }) => Promise<void>;
  isSideBySide?: boolean;
  selectFirstAvailableDate?: boolean;
  hidePriceDescription?: boolean;
  selectedTeamMemberPersonId?: string | null;
  vertical?: boolean;
} & (
  | { isPreview: true; priceId?: never }
  | { isPreview?: false; priceId: string | null }
);

const formatTimeObjects = (
  input:
    | Array<{
        blocks: Array<{ startAt: string }>;
        blockDuration: number;
        schedulingInterval: number;
      }>
    | undefined,
): string => {
  if (!input) return '';

  const timeRanges: string[] = [];
  const startTimes: string[] = [];

  input.forEach(timeObj => {
    if (timeObj.blocks.length === 0) return;

    const formattedBlocks = timeObj.blocks.map(block => new Date(block.startAt));

    let rangeStart = formattedBlocks[0];
    let rangeEnd = addMinutes(rangeStart, timeObj.blockDuration);

    for (let i = 1; i < formattedBlocks.length; i += 1) {
      const currentBlock = formattedBlocks[i];
      const expectedNextBlock = addMinutes(formattedBlocks[i - 1], timeObj.schedulingInterval);

      if (currentBlock.getTime() !== expectedNextBlock.getTime()) {
        const startTime = formatDate(rangeStart, 'h:mm a');
        const endTime = formatDate(rangeEnd, 'h:mm a');
        const timeRange = `${startTime} to ${endTime}`;

        if (!timeRanges.includes(timeRange)) {
          timeRanges.push(timeRange);
          startTimes.push(startTime);
        }

        rangeStart = currentBlock;
      }

      rangeEnd = addMinutes(currentBlock, timeObj.blockDuration);
    }

    const startTime = formatDate(rangeStart, 'h:mm a');
    const endTime = formatDate(rangeEnd, 'h:mm a');
    const timeRange = `<strong>from ${startTime} to ${endTime}</strong>`;

    if (!timeRanges.includes(timeRange) && !startTimes.includes(startTime)) {
      timeRanges.push(timeRange);
      startTimes.push(startTime);
    }
  });

  return timeRanges.join(', ');
};

const parseDateString = (str: string | null): { day: string; month: string; year: string } | null => {
  if (!str) {
    return null;
  }

  const match = str.match(/(\d{4})-(\d{2})-(\d{2})/);
  if (match) {
    return {
      day: match[3],
      month: match[2],
      year: match[1],
    };
  }

  return null;
};

const CustomerSchedulingCalendar: FC<Props> = ({
  product,
  selectedDate: selectedDateInput,
  selectedTeamMemberPersonId,
  booking,
  onFinish,
  onDateTimeSelect,
  isPreview = false,
  priceId,
  availabilityTimezone,
  isSideBySide = false,
  selectFirstAvailableDate = false,
  hidePriceDescription = false,
  vertical = false,
}) => {
  const [selectedDate, setSelectedDate]= useQueryState('selectedDate');
  const [year, setYear] = useState<string | null>(null);
  const [month, setMonth] = useState<string | null>(null);
  const [availabilityData, setAvailabilityData] = useState<Awaited<ReturnType<typeof tsClient.scheduling.getAvailabilityBlocks>> | null>(null);

  const currentRequestRef = useRef<string | null>(null);
  const { getData: getAvailabilityBlocksFn, fetchingState: availabilityFetchingState } = useNoodleApi(tsClient.scheduling.getAvailabilityBlocks);

  const timeRef = useRef<HTMLDivElement>(null);

  const { fetchMonth, fetchYear } = useMemo(() => {
    if (month && year) {
      return {
        fetchMonth: month,
        fetchYear: year,
      };
    }

    const parsed = parseDateString(selectedDate);
    if (parsed) {
      return {
        fetchMonth: parsed.month,
        fetchYear: parsed.year,
      };

    }

    const today = new Date();
    return {
      fetchMonth: today.toLocaleString('en-US', { month: '2-digit' }),
      fetchYear: today.toLocaleString('en-US', { year: 'numeric' }),
    };
  }, [month, year, selectedDate]);

  useEffect(() => {
    const fetchAvailability = async (): Promise<void> => {
      let duration = 0;
      let interval = 0;
      if (priceId) {
        const price = product.prices.find((p) => p.id === priceId);
        duration = price?.sessionDuration || 0;
        interval = price?.sessionInterval || 0;
      } else {
        product.prices
          .forEach(price => {
            if (!duration && price.sessionDuration && price.sessionInterval) {
              duration = price.sessionDuration;
              interval = price.sessionInterval;
            } else if (price.sessionDuration && price.sessionInterval && price.sessionDuration < duration) {
              duration = price.sessionDuration;
              interval = price.sessionInterval;
            }
          });
      }
      if (duration && interval) {
        const thisRequestId = nanoid();
        currentRequestRef.current = thisRequestId;
        const response = await getAvailabilityBlocksFn({
          blockData: [
            { blockDuration: duration, schedulingInterval: interval },
          ],
          month: fetchMonth,
          productId: product.id,
          teamMemberPersonId: selectedTeamMemberPersonId || undefined,
          year: fetchYear,
        });
        if (thisRequestId === currentRequestRef.current) {
          setAvailabilityData(response.data ?? null);
        }
      }
    };
    fetchAvailability();
  }, [getAvailabilityBlocksFn, fetchMonth, fetchYear, product, priceId, selectedTeamMemberPersonId]);

  const isDayBooked = (day: Date): boolean => {
    const dateString = format.datetime.dateString(day);
    return !availabilityData?.days?.find(d => dateString === d.date)?.hasAvailability;
  };

  const isDaySelected = (day: Date): boolean => {
    const dateString = format.datetime.dateString(day);
    return dateString === selectedDate;
  };

  const getPriceForTimeSelector = (thisProduct: ThisProduct): ProductPrice => {
    if (priceId) {
      const chosenPrice = thisProduct.prices.find(p => p.id === priceId);
      if (chosenPrice) {
        return chosenPrice;
      }
    }
    return thisProduct.prices[0];
  };

  const handleDayClick: Parameters<typeof Calendar>[0]['onDayClick'] = (day) => {
    const newSelectedDate = format.datetime.dateString(day);

    if (timeRef.current && !isPreview) {
      setTimeout(() => {
        timeRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 200);
    }

    setSelectedDate(newSelectedDate);
  };

  const handleMonthChange: Parameters<typeof Calendar>[0]['onMonthChange'] = useCallback((newMonth) => {
    setMonth(format.twoDigits(newMonth + 1)); // newMonth=0 is Jan, which is 01 in month
  }, []);

  const handleYearChange: Parameters<typeof Calendar>[0]['onYearChange'] = useCallback((newYear) => {
    setYear(newYear.toFixed(0));
  }, []);

  useEffect(() => {
    if (availabilityData?.days?.length && !selectedDate && selectFirstAvailableDate) {
      const firstAvailableDay = availabilityData.days.find(d => d.hasAvailability);
      if (firstAvailableDay) {
        setSelectedDate(firstAvailableDay.date);
      }
    }
  }, [availabilityData, selectedDate, selectFirstAvailableDate, setSelectedDate]);

  useEffect(() => {
    const parsed = parseDateString(selectedDate);
    if (parsed) {
      setMonth(parsed.month);
      setYear(parsed.year);
    } else if (selectedDate) {
      setSelectedDate(null); // clear badly formatted selectedDate in qs.
    }
  }, [selectedDate, setSelectedDate]);

  useEffect(() => {
    if (selectedDateInput && parseDateString(selectedDateInput)) {
      setSelectedDate(selectedDateInput);
    }
  }, [selectedDateInput, setSelectedDate]);

  const parsedSelectedDataAsNum = (month && year)
    ? {
      month: parseInt(month, 10) - 1, // jan = "01" but 0
      year: parseInt(year, 10),
    }
    : undefined;

  return (
    <>
      {!isSideBySide && <Spacer />}
      <div className={isSideBySide ? s.sideBySideLayout : undefined}
        style={vertical
          ? {
            display: 'flex',
            flexDirection: 'column',
          }
          : undefined
        }
      >
        <Calendar
          isLoading={availabilityFetchingState.isFetching}
          isDayBlocked={isDayBooked}
          isDaySelected={isDaySelected}
          selectedMonthYear={parsedSelectedDataAsNum}
          onDayClick={handleDayClick}
          onMonthChange={handleMonthChange}
          onYearChange={handleYearChange}
        />
        {selectedDate && !isPreview && product.prices.length > 0 && (
          <div className={isSideBySide ? s.timePickerSideBySide : undefined}>
            <div ref={timeRef}>
              {!isSideBySide && <Spacer />}
              <CustomerTimeSelector
                showDayOfWeek={isSideBySide}
                isLoading={availabilityFetchingState.isFetching}
                availability={availabilityData?.days.find(d => d.date === selectedDate)?.times}
                creatorTimezone={availabilityTimezone}
                price={getPriceForTimeSelector(product)}
                booking={booking}
                onFinish={onFinish}
                onDateTimeSelect={onDateTimeSelect}
                selectedDate={selectedDate}
                selectedTeamMemberPersonId={selectedTeamMemberPersonId}
                hidePriceDescription={hidePriceDescription}
              />
            </div>
            {selectedDate && isPreview && availabilityData?.days.find(d => d.date === selectedDate)?.times && (
              <>
                <div className={s.timesPreview}>
                  <h2 className="body-md-bold">
                    {product.creator?.name}&apos;s {format.datetime.withoutTime(`${selectedDate}T00:00:00`)} availability
                  </h2>
                  <p
                    className="body-sm"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        `${product.creator?.name} is available ${formatTimeObjects(availabilityData.days.find(d => d.date === selectedDate)?.times)}`,
                      ),
                    }}
                  />
                  {availabilityTimezone && (
                    <p className='body-sm'>
                      All times in {format.datetime.abbreviatedTimezone({ timezone: availabilityTimezone })}
                    </p>
                  )}
                  <small className="caption">Select an option below to schedule</small>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerSchedulingCalendar;
