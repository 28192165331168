import Modal from '@components/Modal';
import CreateCollectionForm from '@components/CreateCollectionForm/CreateCollectionForm';
import { ReactElement } from 'react';
import s from './CreateCollectionModal.module.scss';

type CreateCollectionModalProps = {
  onClose: () => void;
  collectionId?: string;
  creatorSlug?: string;
  refetchCollection: () => void;
  isAddByteOpen?: boolean;
  title?: string;
};
const CreateCollectionModal = ({
  onClose,
  collectionId,
  creatorSlug,
  refetchCollection,
  isAddByteOpen,
  title,
}: CreateCollectionModalProps): ReactElement => (
  <Modal title={collectionId ? 'Edit Collection' : 'Create Collection'} onClose={onClose} >
    <div className={s.collectionModal}>
      <CreateCollectionForm
        collectionId={collectionId}
        close={onClose}
        creatorSlug={creatorSlug}
        refetchCollection={refetchCollection}
        isAddByteOpen={isAddByteOpen}
        passedTitle={title}
      />
    </div>
  </Modal>
);

export default CreateCollectionModal;
