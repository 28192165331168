import * as ApiModels from '@typings/api-models';
import CheckBox from '@components/FormFields/CheckBox';
import s from './CreateEditBrodcast.module.scss';

type ThisTier = Pick<ApiModels.Tier, 'id' | 'title' | 'isActive'> & {
  prices: Array<Pick<ApiModels.Price, 'id' | 'isActive' | 'price'>>
};
type Props<T> = {
  tier: T;
  tierCanReply: boolean;
  tierCanView: boolean;
  tierCanComment: boolean;
  handleTierChange: (tier: T) => void;
  handleLockChange: (tier: T) => void;
};

const TierPermissionsSelect = <T extends ThisTier>({
  tier,
  tierCanView,
  tierCanComment,
  handleTierChange,
  handleLockChange,
}: Props<T>): React.ReactElement => {
  const isBuyable = Boolean(tier.prices?.find(price => price.isActive));
  const isFreeTier = Boolean(tier.prices?.find(p => p.price === 0));
  return (
    <>
      <CheckBox
        id={tier.id}
        disabled={false}
        name={tier.id}
        values={{ [tier.id]: tierCanView }}
        label={`${tier.title}${!isBuyable ? ` (tier is inactive)` : ''}`}
        hasFixedHeight={false}
        onChange={() => handleTierChange(tier)}
      />
      {isFreeTier && tierCanView && (
        <div className={s['nested-checkbox']}>
          <CheckBox
            id={`locked-comments-${tier.id}`}
            name={`locked-comments-${tier.id}`}
            values={{ [`locked-comments-${tier.id}`]: !tierCanComment }}
            label="Lock comments"
            hasFixedHeight={false}
            onChange={() => handleLockChange(tier)}
          />
          <p className="caption" style={{ color: 'var(--color-gray-75)' }}>
            If you lock the comments, free members will not be able to view the comments or make a reply.
          </p>
        </div>
      )}
    </>
  );
};

export default TierPermissionsSelect;
