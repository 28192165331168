import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import * as ApiModels from '@typings/api-models';
import { CONTEXT_TYPE } from '@typings/api-models';
import TextInput from '@components/FormFields/TextInput';
import Spacer from '@components/Spacer';
import EmailInput from '@components/FormFields/EmailInput';
import Switch from '@components/DesignLibrary/Atoms/Switch';
import Button from '@components/DesignLibrary/Button';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import ProgressIndicator from '@components/ProgressIndicator';
import { getContextValues } from '@/helpers/expressions/filterWorkflowInvoicesByContextExpressions';
import evaluateExpression from '@/helpers/evaluateExpression';
import AddContextSelection from './AddContextSelection';
import s from './InitiateCaseModal.module.scss';
import { ContextData } from './types';

type ThisContext = Pick<ApiModels.WorkflowContext, 'id' | 'type' | 'label' | 'slug' | 'options'> & {
  expression?: ApiModels.Expression | null;
};

type Props = {
  customer: Pick<ApiModels.Person, 'id' | 'name' | 'email'>;
  workflow: Pick<ApiModels.Workflow, 'id'> & {
    contexts: Array<ThisContext>;
  };
  onSubmit: (contextData: ContextData[]) => void;
  initialContext: ContextData[] | null;
  associatedUserWorkflowId?: string;
};

const AddContext: FC<Props> = ({ associatedUserWorkflowId, customer, workflow, onSubmit, initialContext }) => {
  const { getData: getInitialContextData } = useNoodleApi(tsClient.workflows.getPrefilledContextData);

  const [isFetchingContextData, setIsFetchingContextData] = useState(false);
  const [contextData, setContextData] = useState<Array<ContextData | unknown>>(
    initialContext
      || workflow.contexts.map(c => ({
        context: c,
        data: {},
      })),
  );

  const evaluateContextConditions = useCallback((context: ContextData): boolean => {
    const { expression } = context.context;
    if (expression) {
      const variables: Record<string, unknown> = {};
      expression.variables.forEach((variable) => {
        variables[variable.variableName] = getContextValues(contextData as ContextData[]);
      });

      const result = evaluateExpression({
        expression: expression.expression,
        variables,
      }) ?? false;
      return Boolean(result);
    }
    return true;
  }, [contextData]);

  useEffect(() => {
    if (associatedUserWorkflowId && (initialContext?.length || 0) === 0) {
      const getData = async (): Promise<void> => {
        const { data } = await getInitialContextData({
          associatedUserWorkflowId,
          id: workflow.id,
        });
        if (data) {
          setContextData(prev => prev.map((context) => {
            const { slug } = (context as ContextData).context;
            if (slug && data[slug]) {
              return {
                ...(context as ContextData),
                data: {
                  value: data[slug],
                },
              };
            }
            return context;
          }));
        }
        setIsFetchingContextData(false);
      };
      getData();
    } else {
      setIsFetchingContextData(false);
    }
  }, [getInitialContextData, workflow, associatedUserWorkflowId]);

  const setContextDataField = (contextId: string, field: string) => (data: string | boolean | null) => {
    setContextData(prev =>
      prev.map(context => {
        if ((context as ContextData).context.id === contextId) {
          return {
            ...(context as ContextData),
            data: {
              ...(context as ContextData).data,
              [field]: data,
            },
          };
        }
        return context;
      }),
    );
  };

  const isButtonDisabled = (): boolean => {
    const thisContextData = (contextData as ContextData[]).filter(evaluateContextConditions);
    for (let i = 0; i < thisContextData.length; i += 1) {
      if (!thisContextData[i].context.isOptional) {
        if (thisContextData[i].context.type === CONTEXT_TYPE.PERSON) {
          if (
            !(thisContextData[i].data as ApiModels.CreatePersonContextData).isCustomer
            && (!(thisContextData[i].data as ApiModels.CreatePersonContextData).name
              || !(thisContextData[i].data as ApiModels.CreatePersonContextData).email
              || (thisContextData[i].data as { emailError: string | null }).emailError
              || (thisContextData[i].data as { nameError: string | null }).nameError
              || (thisContextData[i].data as { email: string }).email === customer.email)
          ) {
            return true;
          }
        } else if (thisContextData[i].context.type === CONTEXT_TYPE.ADDITIONAL_INFO) {
          if (!(thisContextData[i].data as ApiModels.CreateInfoContextData).value) {
            return true;
          }
        } else if (thisContextData[i].context.type === CONTEXT_TYPE.SELECTION) {
          if (!(thisContextData[i].data as ApiModels.CreateSelectionContextData).slug) {
            return true;
          }
        }
      }
    }
    return false;
  };

  return (
    <div className={s.contexts}>
      {isFetchingContextData
        ? <ProgressIndicator isCentered />
        : (
          <>
            {(contextData as ContextData[]).filter(evaluateContextConditions).map(contextDataItem => (
              <Fragment key={contextDataItem.context.id}>
                <div className={s.context} key={contextDataItem.context.id}>
                  <p className="body-md">{contextDataItem.context.label}{" "}
                    {contextDataItem.context.isOptional && <span style={{ color: 'var(--color-gray-75)' }}>(Optional)</span>}
                  </p>
                  {contextDataItem.context.type === CONTEXT_TYPE.PERSON && (
                    <div>
                      <Switch
                        name={`isCustomer-${contextDataItem.context.id}`}
                        isChecked={(contextDataItem.data as ApiModels.CreatePersonContextData).isCustomer}
                        label={`${customer.name} is the ${contextDataItem.context.label}`}
                        onChange={() =>
                          setContextDataField(
                            contextDataItem.context.id,
                            'isCustomer',
                          )(!((contextDataItem.data as ApiModels.CreatePersonContextData).isCustomer || false))
                        }
                      />
                      {!(contextDataItem.data as ApiModels.CreatePersonContextData).isCustomer && (
                        <>
                          <Spacer size="16px" />
                          <TextInput
                            id={`name-${contextDataItem.context.id}`}
                            value={(contextDataItem.data as ApiModels.CreatePersonContextData).name}
                            onChange={setContextDataField(contextDataItem.context.id, 'name')}
                            label="Name"
                            placeholder="Enter name"
                            autoComplete={'name'}
                            onError={setContextDataField(contextDataItem.context.id, 'nameError')}
                            required
                          />
                          <EmailInput
                            id={`email-${contextDataItem.context.id}`}
                            value={(contextDataItem.data as ApiModels.CreatePersonContextData).email || ''}
                            onChange={setContextDataField(contextDataItem.context.id, 'email')}
                            label="Email"
                            placeholder="Enter email"
                            error={(contextDataItem.data as ApiModels.CreatePersonContextData).email === customer.email ? 'Email must not match customer email' : undefined}
                            onError={setContextDataField(contextDataItem.context.id, 'emailError')}
                            required
                          />
                        </>
                      )}
                    </div>
                  )}
                  {contextDataItem.context.type === CONTEXT_TYPE.ADDITIONAL_INFO && (
                    <div>
                      <TextInput
                        id={`value-${contextDataItem.context.id}`}
                        value={(contextDataItem.data as ApiModels.CreateInfoContextData).value}
                        onChange={setContextDataField(contextDataItem.context.id, 'value')}
                        label={contextDataItem.context.label}
                        placeholder={`Enter ${contextDataItem.context.label}`}
                        required={!contextDataItem.context.isOptional}
                      />
                    </div>
                  )}
                  {contextDataItem.context.type === CONTEXT_TYPE.SELECTION && (
                    <AddContextSelection
                      context={contextDataItem.context}
                      value={(contextDataItem.data as ApiModels.CreateSelectionContextData).slug}
                      onChange={(key) => {
                        const label = contextDataItem.context.options.find((opt) => opt.slug === key)?.label;
                        setContextDataField(contextDataItem.context.id, 'slug') (key);
                        if (label) {
                          setContextDataField(contextDataItem.context.id, 'label') (label);
                        }
                      }
                      }
                    />
                  )}
                </div>
                <Spacer size="16px" />
              </Fragment>
            ))}
            <div className={s.buttonContainer}>
              <Button disabled={isButtonDisabled()} variant="primary" size="md" fullWidth onClick={() => onSubmit(contextData as ContextData[])}>
                Next
              </Button>
            </div>
          </>
        )}
    </div>
  );
};

export default AddContext;
