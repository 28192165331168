import {
  CONTEXT_TYPE,
  CreateInfoContextData,
  CreateNotesContextData,
  CreatePersonContextData,
  CreateSelectionContextData,
  Person,
} from "@/typings/api-models";

type ContextData =
| (CreatePersonContextData & { nameError: string | null; emailError: string | null })
| CreateInfoContextData
| CreateNotesContextData
| CreateSelectionContextData;

type ThisCustomer = Pick<Person, 'id' | 'name' | 'email'> & {
  primaryColour?: { hex: string } | null;
  image?: { url: string } | null;
};

const formatContextData = (data: ContextData, label: string | null, type: CONTEXT_TYPE, customer: ThisCustomer): string => {
  if (type === CONTEXT_TYPE.PERSON) {
    const personData = data as CreatePersonContextData;
    if (personData.isCustomer) {
      return `${customer.name}, ${customer.email}`;
    }
    if (personData.name && personData.email) {
      return `${personData.name}, ${(personData.email)}`;
    }
    return 'Not included (optional)';
  }
  if (type === CONTEXT_TYPE.ADDITIONAL_INFO) {
    const additionalData = data as CreateInfoContextData;
    if (additionalData.value) {
      return additionalData.value;
    }
    return 'Not included (optional)';
  }
  if (type === CONTEXT_TYPE.SELECTION) {
    const selectionData = data as CreateSelectionContextData;
    if (label) {
      return label;
    }
    if (selectionData.slug) {
      return selectionData.slug;
    }
    return 'Not included (optional)';
  }
  return 'N/A';
};

export default formatContextData;
