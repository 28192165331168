import Buttons from '@components/Buttons';
import CustomLink from '@components/CustomLink';
import TabIcon from '@components/DesignLibrary/Atoms/TabIcon';
import List from '@components/Icons/List';
import SvgNoodle from '@components/Icons/Noodle';
import X from '@components/Icons/X';
import UserImage from '@components/UserImage';
import { isAttorney } from '@helpers/businessCategories';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import { useRouter } from 'next/router';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useFeatureFlag } from '@/providers/FeatureFlags';
import { DEPLOYMENT_TIER } from '@/configuration/client';

import s from './DashboardV3Navigation.module.scss';

type TABS =
  | 'account'
  | 'inbox'
  | 'building-blocks'
  | 'home'
  | 'members'
  | 'messaging'
  | 'none'
  | 'services'
  | 'content'
  | 'team'
  | 'transactions'
  | 'scripts'
  | 'bookings'
  | 'workflows';

const PAGES: Array<{
  key: Exclude<TABS, 'team' | 'scripts' | 'none'>;
  to: string;
}> = [
  {
    key: 'home',
    to: getUrl(IDENTIFIERS.DASHBOARD),
  },
  {
    key: 'inbox',
    to: getUrl(IDENTIFIERS.DASHBOARD_INBOX),
  },
  {
    key: 'messaging',
    to: getUrl(IDENTIFIERS.DASHBOARD_CONVERSATIONS),
  },
  {
    key: 'workflows',
    to: getUrl(IDENTIFIERS.DASHBOARD_WORKFLOWS),
  },
  {
    key: 'building-blocks',
    to: getUrl(IDENTIFIERS.DASHBOARD_TERMS_TEMPLATES),
  },
  {
    key: 'services',
    to: getUrl(IDENTIFIERS.DASHBOARD_SERVICES),
  },
  {
    key: 'content',
    to: getUrl(IDENTIFIERS.DASHBOARD_CONTENT),
  },
  {
    key: 'members',
    to: getUrl(IDENTIFIERS.DASHBOARD_MEMBERS),
  },
  {
    key: 'transactions',
    to: getUrl(IDENTIFIERS.DASHBOARD_TRANSACTIONS),
  },
  {
    key: 'bookings',
    to: getUrl(IDENTIFIERS.DASHBOARD_BOOKINGS),
  },
];

const DashboardV3Navigation: FC<{
  avatar: {
    color?: string | null;
    name?: string | null;
    image?: string | null;
  };
  hasLeftNav: boolean;
  setIsSupportModalOpen: (isOpen: boolean) => void;
  currentTab: TABS;
  unreadInboxItemCount?: number;
}> = ({ avatar, currentTab, hasLeftNav, unreadInboxItemCount = 0 }) => {
  const router = useRouter();
  const { creator, isUserModalOpen, setIsUserModalOpen } = useContext(DashboardContext);
  const [isOpen, setOpen] = useState(hasLeftNav);
  const canShowInbox = useFeatureFlag("can-use-inbox");

  useEffect(() => {
    if (!hasLeftNav) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [router.pathname, isUserModalOpen, hasLeftNav]);

  useEffect(() => {
    if (!hasLeftNav && isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [hasLeftNav, isOpen]);

  return (
    <>
      <nav
        className={s.nav}
        {...((hasLeftNav && {
          style: {
            height: 'min(100vh, 100dvh)',
          },
        })
          || (isOpen
            && !hasLeftNav && {
            style: {
              bottom: 0,
              left: 0,
              position: 'fixed',
              right: 0,
              top: 0,
            },
          }))}
      >
        {!hasLeftNav && (
          <div className={s.header}>
            <Buttons
              isWrapper
              onClick={() => setOpen(!isOpen)}
              className={s.menu}
              {...(isOpen && {
                style: {
                  backgroundColor: 'var(--color-primary)',
                  color: 'var(--color-gray-0)',
                },
              })}
            >
              {isOpen ? <X size={20} /> : <List size={20} />}
            </Buttons>
            <div>
              <UserImage size={24} name={avatar.name || null} url={avatar.image} color={avatar.color || null} />
              {avatar.name}
            </div>
            <div />
          </div>
        )}
        {isOpen && (
          <div className={!hasLeftNav ? s.firstLevelMobile : s.firstLevel}>
            {hasLeftNav && (
              <CustomLink to={getUrl(IDENTIFIERS.DASHBOARD)} className={s.logo}>
                <SvgNoodle fill={DEPLOYMENT_TIER === "production" ? "var(--color-noodle)" : "var(--color-warning)"} width={32} height={32} />
              </CustomLink>
            )}
            <div className={!hasLeftNav ? s.tabsMobile : s.tabs}>
              {PAGES.map(page => {
                const isActive = page.key === currentTab;
                const hideContentTab = page.key === 'content' && isAttorney(creator?.businessCategory);
                const hideInboxTab = page.key === 'inbox' && !canShowInbox;
                if (hideContentTab || hideInboxTab) {
                  return null;
                }
                return (
                  <TabIcon
                    showLabel={!hasLeftNav}
                    key={page.key}
                    icon={page.key}
                    href={page.to}
                    selected={isActive}
                    notificationCount={page.key === 'inbox' ? unreadInboxItemCount : undefined}
                  />
                );
              })}
            </div>
            <div className={!hasLeftNav ? s.bottomMobile : s.bottom}>
              {!hasLeftNav
                ? (
                  <TabIcon
                    selected={currentTab === 'account'}
                    showLabel
                    icon="account"
                    onClick={() => setIsUserModalOpen(!isUserModalOpen)}
                  />
                )
                : (
                  <Buttons isWrapper onClick={() => setIsUserModalOpen(!isUserModalOpen)} aria-label="Your Profile">
                    <UserImage url={avatar.image} name={avatar.name} color={avatar.color} size={32} {...currentTab === 'account' && { className: s.accountSelected } || {}} /> {!hasLeftNav && 'Your Account'}
                  </Buttons>
                )}
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default DashboardV3Navigation;
