import MediaPreview from '@components/MediaPreview';
import Buttons from '@components/Buttons';
import Image from '@components/Image';
import ImageIcon from '@components/Icons/Image';
import React, { ReactElement, useRef } from 'react';
import clsx from 'classnames';
import ProgressIndicator from '@components/ProgressIndicator';
import s from './ThumbnailInput.module.scss';

type ThumbnailInputProps = {
  onChange: (file: string) => Promise<void>;
  file: string | null;
  error?: boolean;
  className?: string;
  title?: string;
  icon?: ReactElement;
  accept?: string;
  isUploading?: boolean;
};

const ThumbnailInput = ({ accept = 'image/*', onChange, file, error, className, title, icon, isUploading }: ThumbnailInputProps): ReactElement => {
  const inputFile = useRef<HTMLInputElement>(null);
  return (
    <MediaPreview accept={accept} setPhotoFile={onChange} ref={inputFile} photoFile={file}>
      <Buttons
        isWrapper
        onClick={() => inputFile?.current?.click()}
        className={clsx(s['thumbnail-image'], className)}
        style={{
          border: !file ? '1px dashed #EBEBEB' : '',
          borderColor: error ? 'red' : '#EBEBEB',
          display: file ? 'column' : 'flex',
          flexDirection: file ? 'column' : 'row',
        }}
      >
        {isUploading && <div className={s.progress}><ProgressIndicator /></div>}
        {file && <Image image={file} className={s[`thumbnail-image__photo${(isUploading && '-uploading') || ''}`]} alt="" objectFit="cover" />}
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: file ? 'row' : 'column',
            gap: 8,
            marginTop: file ? 12 : 0,
          }}
        >
          {icon || <ImageIcon color="var(--color-noodle)" size={20} weight="fill" />}
          <p>{title || (file ? 'Update Thumbnail' : 'Add Thumbnail')}</p>
        </div>
      </Buttons>
    </MediaPreview>
  );
};

export default ThumbnailInput;
