import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Plus from '@components/Icons/Plus';
import { HookResponse, PAGE_SIZE, ResourceType } from './common';

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_DOCUMENT_REQUEST_TEMPLATES_EDIT, { id });

const useDocumentRequests = ({
  creatorId,
  search,
  resourceType,
}: {
  creatorId: string | undefined;
  search: string | null;
  resourceType: ResourceType;
}): HookResponse & {
  createDocumentRequestsModalOpen: boolean;
  setCreateDocumentRequestsModalOpen: (v: boolean) => void;
} => {
  const [createDocumentRequestsModalOpen, setCreateDocumentRequestsModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const pathParams = useParams<{ id?: string }>();
  const [items, setItems] = useState<HookResponse['items']>([]);
  const mostRecentRequestRef = useRef<string | null>(null);

  const { getData: getCreatorDocumentRequestsFn } = useNoodleApi(tsClient.documentRequests.getCreatorDocumentRequests);

  const selectedId = pathParams?.id;

  const loadPage = useCallback(async ({ page, search: newSearch }: {search: string | null, page: number }): Promise<void> => {
    if (creatorId && resourceType === 'document-requests') {
      setCurrentPage(page);
      const thisRequestId = nanoid();
      mostRecentRequestRef.current = thisRequestId;

      setIsFetching(true);
      const response = await getCreatorDocumentRequestsFn({
        creatorId,
        page,
        perPage: PAGE_SIZE,
        search: newSearch ?? undefined,
      });

      if (mostRecentRequestRef.current === thisRequestId) {
        if (response.data) {
          setItems(response.data.items.map(doc => ({
            href: getUrl(IDENTIFIERS.DASHBOARD_DOCUMENT_REQUEST_TEMPLATES_EDIT, { id: doc.id }),
            id: doc.id,
            label: doc.title,
          })));
          setNumPages(response.data.numPages);
        }
        setCreateDocumentRequestsModalOpen(false);
        setIsFetching(false);
      }
    } else {
      setItems([]);
    }
  }, [
    creatorId,
    resourceType,
    getCreatorDocumentRequestsFn,
  ]);

  useEffect(() => {
    loadPage({ page: 1, search });
  }, [loadPage, search]);

  const headerActions = [{
    icon: Plus,
    label: 'Create Document Request',
    onClick: () => setCreateDocumentRequestsModalOpen(true),
  }];

  const pagination: HookResponse['pagination'] = {
    numPages,
    onChangePage: (newPage) => {
      loadPage({ page: newPage, search });
    },
    page: currentPage,
  };

  return {
    createDocumentRequestsModalOpen,
    getDetailPagePath,
    headerActions,
    ifNoItemsText: 'No document requests yet',
    isFetchingList: isFetching,
    items,
    pagination,
    reloadList: () => loadPage({ page: 1, search }),
    selectedId,
    setCreateDocumentRequestsModalOpen,
  };
};

export default useDocumentRequests;
