import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';

export type ResourceType = 'conversations' | 'comments' | 'broadcasts' | 'ai-feed';

export type HookResponse = Pick<Parameters<typeof ListAndDetailsSubLayout>[0],
  | 'items'
  | 'itemsCount'
  | 'filters'
  | 'pagination'
  | 'selectedId'
  | 'isFetchingList'
  | 'ifNoItemsText'
  | 'getDetailPagePath'
  | 'onSearch'
> & {
  reloadListView?: () => Promise<void>;
  actions?: Parameters<typeof ListAndDetailsSubLayout>[0]['header']['actions'];
};

export const PAGE_SIZE = 25;
