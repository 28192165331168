import React, {ReactElement} from "react";
import Buttons from "@components/Buttons";
import Trash from "@components/Icons/Trash";
import s from './ByteItem.module.scss';

type Props = {
  title: string;
  stepAmount: number;
  id: string;
  onRemove?: (id: string) => void;
};
const ByteItem = ({title, stepAmount, onRemove, id}: Props): ReactElement => (
  <div className={s.productContainer}>
    <div className={s.contentInfo}>
      <div className={s.contentInfo__title}>{title}</div>
      <div className={s.contentInfo__size}>· {stepAmount} steps</div>
    </div>
    {
      onRemove
        ? <Buttons className={s.remove} onClick={() => onRemove(id)}>
          <Trash weight={'fill'} color={'red'} size={16} />
        </Buttons>
        : null
    }
  </div>
);

export default ByteItem;