import noodleApiClient, { ApiDefinition } from '@noodleApi';

type ThisApi = ApiDefinition['/products/{productId}/team-member-availability']['GET'];

const getTeamMembersWithAvailability = async ({
  productId,
}: ThisApi['params']): Promise<ThisApi['response']> => {
  const response = await noodleApiClient.get(`/products/${productId}/team-member-availability`);
  return response.data;
};

export default getTeamMembersWithAvailability;