import React, { useEffect, useState } from 'react';
import CheckCircle from '@components/Icons/CheckCircle';
import classNames from 'classnames';
import s from './Chips.module.scss';
import UserImage from '../UserImage';

type ChipsProps = {
  data: {
    id: string;
    disabled?: boolean;
    label: string;
    hasCheckmark?: boolean;
    userImage?: {
      color?: string;
      url?: string;
      name?: string;
    };
  }[];
  onItemClick?: (arg0: string | null) => void;
  selectedItem?: string | null;
  className?: string;
  wrap?: boolean;
};

const Chips = ({ data, onItemClick, selectedItem, className, wrap }: ChipsProps): JSX.Element => {
  const [checked, setChecked] = useState<string | null | undefined>(selectedItem);

  useEffect(() => {
    if (selectedItem !== checked) {
      setChecked(selectedItem);
    }
  }, [selectedItem, checked]);

  if (data.length === 0) {
    return <></>;
  }

  return (
    <div className={wrap ? s.wrap : s.scroll}>
      <div className={classNames(s.wrapper, className)}>
        {data.map(item => {
          const handleClick = checked === item.id ? null : item.id;
          return (
            <button
              key={item.id}
              role="switch"
              disabled={item.disabled}
              aria-checked={checked === item.id ? 'true' : 'false'}
              style={(item.hasCheckmark && checked !== item.id) ? { borderColor: 'var(--color-success)' } : undefined}
              onClick={() => {
                setChecked(handleClick);
                onItemClick?.(handleClick);
              }}
            >
              {item.userImage && <UserImage url={item.userImage.url} color={item.userImage.color} name={item.userImage.name} size={24} />}
              <span>{item.label}</span>
              {item.hasCheckmark && <CheckCircle className={s.checkmark} size={16} weight='fill' color={`var(--color-${checked === item.id ? 'gray-0' : 'success'})`} />}
            </button>
          );
        })}
        {!wrap && <div style={{ minWidth: 16 }} />}
      </div>
    </div>
  );
};

export default Chips;
