import { FC, useContext, useEffect, useRef, useState } from 'react';
import SelectConfidoPaymentMethod from '@components/SelectConfidoPaymentMethod';
import ProgressIndicator from '@components/ProgressIndicator';
import StripeElementsProvider from '@providers/StripeElements';
import SelectPaymentMethod, { SelectPaymentMethodRef } from '@components/SelectPaymentMethod';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import * as ApiModels from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Button from '@components/DesignLibrary/Button';
import Spacer from '@components/Spacer';
import { DEFAULT_CURRENCY } from '@typings/common';
import PriceInput from '@components/FormFields/PriceInput';
import CheckBox from '@/components/DesignLibrary/Atoms/CheckBox';
import CustomSelect from '@/components/FormFields/CustomSelect';
import DatePicker from '@/components/FormFields/DatePicker';
import { PaymentPlan } from '@typings/api-models';
import s from './CollectPaymentData.module.scss';
import { FREQUENCY_OPTIONS } from '../PaymentPlanModal';

type Props = {
  invoiceTemplateIndex: number;
  invoiceTemplate: Pick<ApiModels.InvoiceTemplate, 'isDebitCardPaymentEnabled' | 'isCreditCardPaymentEnabled' | 'isPartialPaymentEnabled' | 'isDebitCardPaymentRequiredForCustomer'> & {
    lineItems: Array<Pick<ApiModels.InvoiceTemplateLineItem, 'price' | 'quantity'>>;
  };
  customer: Pick<ApiModels.Person, 'id' | 'name'>;
  onSubmit: (d: {
    paymentMethodId: string | null;
    amount: number;
    index: number;
    paymentPlanFrequency?: PaymentPlan['frequency'];
    nextPaymentDate?: string | null;
    createPaymentPlan?: boolean;
  }) => void;
};

const CollectPaymentData: FC<Props> = ({ customer, invoiceTemplateIndex, invoiceTemplate, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createPaymentPlan, setCreatePaymentPlan] = useState(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [frequency, setFrequency] = useState<ApiModels.PaymentPlan['frequency']>(FREQUENCY_OPTIONS[0].value);
  const [date, setDate] = useState<string | null>(null);
  const invoiceTotal = invoiceTemplate.lineItems.reduce((acc, val) => acc + ((val.price || 0) * val.quantity), 0);
  const [amount, setAmount] = useState(invoiceTotal < 100 ? invoiceTotal : 100);

  const { creator } = useContext(dashboardContext);

  const paymentMethodRef = useRef<SelectPaymentMethodRef>();

  const { data: paymentMethodToken, getData: getConfidoPaymentMethodTokenFn } = useNoodleApi(tsClient.creators.getConfidoPaymentMethodToken);

  useEffect(() => {
    if (creator?.usesConfido) {
      getConfidoPaymentMethodTokenFn({ creatorId: creator.id, forUserId: customer.id });
    }
  }, [creator?.usesConfido, creator?.id, getConfidoPaymentMethodTokenFn, customer]);

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    const paymentMethodId = await paymentMethodRef.current?.getPaymentMethodId?.();
    if (paymentMethodId) {
      if (createPaymentPlan) {
        onSubmit({
          amount,
          createPaymentPlan: true,
          index: invoiceTemplateIndex,
          nextPaymentDate: date,
          paymentMethodId,
          paymentPlanFrequency: frequency,
        });
      } else {
        onSubmit({ amount, index: invoiceTemplateIndex, paymentMethodId });
      }
    }
    setIsSubmitting(false);
  };

  const handleSkip = (): void => {
    onSubmit({ amount: 0, index: invoiceTemplateIndex, paymentMethodId: null });
  };

  return (
    <div className={s.container}>
      {creator
        ? (
          <>
            {creator.usesConfido
              ? (
                <>
                  {paymentMethodToken
                    ? (
                      <>
                        {invoiceTemplate.isPartialPaymentEnabled && (
                          <CheckBox
                            id='createPaymentPlan'
                            isChecked={createPaymentPlan}
                            label='Create payment plan'
                            onChange={setCreatePaymentPlan}
                            size='sm'
                          />
                        )}
                        <Spacer size={16} />
                        <PriceInput
                          value={amount}
                          name='amount'
                          label={createPaymentPlan ? 'First payment amount' : 'Initial payment amount ($1 minimum)'}
                          min={1}
                          max={invoiceTotal}
                          onChange={setAmount}
                          currency={creator.defaultCurrency || DEFAULT_CURRENCY}
                        />
                        {createPaymentPlan && (
                          <>
                            <Spacer size={16} />
                            <CustomSelect label='Frequency' value={frequency} id='frequency' onChange={newFrequency => setFrequency(newFrequency as ApiModels.PaymentPlan['frequency'])} options={FREQUENCY_OPTIONS} />
                            <Spacer size={16} />
                            <DatePicker min={new Date().toISOString().split('T')[0]} max={
                              new Date(new Date().getTime() + 60 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
                            } value={date} onChange={setDate} label='First Payment Date' />
                          </>
                        )}
                        <Spacer size={16} />
                        <SelectConfidoPaymentMethod
                          ref={paymentMethodRef}
                          isDebitCardPaymentEnabled={invoiceTemplate.isDebitCardPaymentEnabled}
                          isDebitCardPaymentRequiredForCustomer={invoiceTemplate.isDebitCardPaymentRequiredForCustomer}
                          isCreditPaymentEnabled={invoiceTemplate.isCreditCardPaymentEnabled}
                          isAchPaymentEnabled={false}
                          onPaymentMethodAvailableChange={setIsSubmitButtonEnabled}
                          confidoPaymentMethodToken={paymentMethodToken}
                          person={customer}
                          creator={creator}
                          selectedPaymentMethodId={null}
                        />
                      </>
                    )
                    : <ProgressIndicator isCentered />}
                </>
              )
              : (
                <StripeElementsProvider creator={creator}>
                  {invoiceTemplate.isPartialPaymentEnabled && (
                    <CheckBox
                      id='createPaymentPlan'
                      isChecked={createPaymentPlan}
                      label='Create payment plan'
                      onChange={setCreatePaymentPlan}
                      size='sm'
                    />
                  )}
                  <Spacer size={16} />
                  <PriceInput
                    value={amount}
                    name='amount'
                    label={createPaymentPlan ? 'First payment amount' : 'Initial payment amount ($1 minimum)'}
                    min={1}
                    max={invoiceTotal}
                    onChange={setAmount}
                    currency={creator.defaultCurrency || DEFAULT_CURRENCY}
                  />
                  {createPaymentPlan && (
                    <>
                      <Spacer size={16} />
                      <CustomSelect label='Frequency' value={frequency} id='frequency' onChange={newFrequency => setFrequency(newFrequency as ApiModels.PaymentPlan['frequency'])} options={FREQUENCY_OPTIONS} />
                      <Spacer size={16} />
                      <DatePicker min={new Date().toISOString().split('T')[0]} max={
                        new Date(new Date().getTime() + 60 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
                      } value={date} onChange={setDate} label='First Payment Date' />
                    </>
                  )}
                  <Spacer size={16} />
                  <SelectPaymentMethod
                    ref={paymentMethodRef}
                    creatorSlug={creator.slug}
                    creatorId={creator.id}
                    isDebitCardPaymentEnabled={invoiceTemplate.isDebitCardPaymentEnabled}
                    isCreditPaymentEnabled={invoiceTemplate.isCreditCardPaymentEnabled}
                    isAchPaymentEnabled={false}
                    setIsButtonEnabled={setIsSubmitButtonEnabled}
                    person={customer}
                    onBehalfOfPersonId={customer.id}
                  />
                </StripeElementsProvider>
              )}
            <Spacer size={16} />
            <Button
              variant='primary'
              size='md'
              fullWidth
              disabled={!isSubmitButtonEnabled || !amount || (createPaymentPlan && !date)}
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              Save
            </Button>
            <Spacer size={16} />
            <Button
              variant='secondary'
              size='md'
              fullWidth
              onClick={handleSkip}
            >
              Skip
            </Button>
          </>
        )
        : <ProgressIndicator isCentered />}
    </div>
  );
};

export default CollectPaymentData;
