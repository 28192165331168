import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import calendar from 'calendar';
import Buttons from '@components/Buttons/Buttons';
import ArrowCircleLeft from '@components/Icons/ArrowCircleLeft';
import ArrowCircleRight from '@components/Icons/ArrowCircleRight';
import ProgressIndicator from '@components/ProgressIndicator';
import s from './Calendar.module.scss';

const cal = new calendar.Calendar();

const DAY_NAMES = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

type Props = {
  dayHasBookings?: (day: Date) => boolean;
  isDayBlocked: (day: Date) => boolean;
  isDaySelected?: (day: Date) => boolean;
  isLoading?: boolean;
  onDayClick: (day: Date) => unknown;
  onMonthChange?: (month: number) => unknown;
  onYearChange?: (year: number) => unknown;
  selectedMonthYear?: {
    month: number; // 0 indexed
    year: number;
  }
};

const Calendar: FC<Props> = ({
  dayHasBookings,
  isDayBlocked,
  isDaySelected,
  isLoading,
  onDayClick,
  onMonthChange,
  onYearChange,
  selectedMonthYear,
}) => {
  const [year, setYear] = useState(selectedMonthYear?.year ?? new Date().getFullYear());
  const [month, setMonth] = useState(selectedMonthYear?.month ?? new Date().getMonth());
  const [isAvailableThisMonth, setIsAvailableThisMonth] = useState(true);

  const weeks = useMemo(() => cal.monthDates(year, month), [year, month]);
  const startOfSelectedMonth = new Date(year, month, 1, 0, 0, 0, 0);
  const startOfNextMonth = useMemo(() => new Date(year, month + 1, 1, 0, 0, 0, 0), [year, month]);

  const incrementMonth = (): void => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const decrementMonth = (): void => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const isDayDisabled = useCallback(
    (day: Date): boolean => {
      const startOfToday = new Date();
      startOfToday.setHours(0, 0, 0, 0);

      if (day.getTime() >= startOfNextMonth.getTime()) {
        return true;
      }

      if (day.getTime() < startOfToday.getTime()) {
        return true;
      }

      return isDayBlocked(day);
    },
    [isDayBlocked, startOfNextMonth],
  );

  const dayHasAnyBookings = (day: Date): boolean => {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);

    if (day.getTime() >= startOfNextMonth.getTime()) {
      return false;
    }

    if (day.getTime() < startOfToday.getTime()) {
      return false;
    }

    if (day.getTime() < startOfSelectedMonth.getTime()) {
      return false;
    }

    return dayHasBookings?.(day) || false;
  };

  useEffect(() => {
    if (selectedMonthYear) {
      setMonth(selectedMonthYear.month);
      setYear(selectedMonthYear.year);
    }
  }, [selectedMonthYear]);

  useEffect(() => {
    const isAvailable = weeks.some(week => week.some(day => !isDayDisabled(day)));
    setIsAvailableThisMonth(isAvailable);
  }, [weeks, isDayDisabled]);

  useEffect(() => {
    onMonthChange?.(month);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  useEffect(() => {
    onYearChange?.(year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year]);

  return (
    <div className={s.calendar}>
      <p className={s.monthLabel}>{isLoading && <ProgressIndicator size={16} />}{startOfSelectedMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}</p>
      {!isAvailableThisMonth && !isLoading && <p className={s.noAvailability}>No availability: View next month</p>}

      <div className={s.month}>
        <div className={s.dayNames}>
          {DAY_NAMES.map(d => (
            <span key={d} className={s.dayName} data-translate="false">
              {d}
            </span>
          ))}
        </div>
        {weeks.map(w => (
          <div key={w[0].toISOString()} className={s.week}>
            {w.map(day => (
              <button
                onClick={() => {
                  onDayClick(day);
                }}
                disabled={isDayDisabled(day)}
                key={day.toISOString()}
                className={classNames(s.day, {
                  [s.isGrayed]: isDayDisabled(day),
                  [s.isSelected]: isDaySelected && isDaySelected(day),
                })}
              >
                <span>{day.getMonth() === month ? day.getDate() : null}</span>
                {dayHasAnyBookings(day) && <div className={s.hasBookings} />}
              </button>
            ))}
          </div>
        ))}
      </div>
      <div className={s.monthControls}>
        <Buttons
          className={s.iconButton}
          disabled={month === new Date().getMonth() && year === new Date().getFullYear()}
          iconBefore={<ArrowCircleLeft size={24} weight="fill" />}
          onClick={decrementMonth}
        >
          Previous month
        </Buttons>
        <Buttons onClick={incrementMonth} className={s.iconButton} iconAfter={<ArrowCircleRight size={24} weight="fill" />}>
          Next month
        </Buttons>
      </div>
    </div>
  );
};

export default Calendar;
