import Modal from '@components/Modal';
import React, { useCallback, useContext, useEffect } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import ProgressIndicator from '@components/ProgressIndicator';
import TFACode from '@/components/TFACode';
import Spacer from '@/components/Spacer';
import s from './TFAModal.module.scss';

const TFAModal = ({ onClose }: { onClose: () => void }): React.ReactElement => {

  const { creator } = useContext(dashboardContext);

  const {
    getData: getAccounts,
    data: accounts,
    fetchingState: { isFetching: isFetchingAccounts },
  } = useNoodleApi(tsClient.integrations.listCreatorAccounts);
  const { getData: getCodeFn } = useNoodleApi(tsClient.integrations.getMFAForIntegrationAccount);

  useEffect(() => {
    if (creator) {
      getAccounts({ creatorId: creator.id, page: 1, perPage: 1000 });
    }
  }, [creator]);

  const getCode = useCallback(async (provider: string) => {
    const data = await getCodeFn({ creatorId: creator?.id || '', provider });
    return data.data;
  }, [creator]);

  return (
    <Modal hideBottomInset title={'Two-Factor Authentication'} onClose={onClose} >
      <div className={s.wrapper}>
        {isFetchingAccounts
          ? <><ProgressIndicator isCentered /><Spacer /></>
          : (
            <>
              <p className={s.tapToCopy}>Click 2FA auth code to copy</p>
              {accounts?.items?.map((account) => (
                <TFACode
                  getCode={getCode}
                  key={account.id}
                  provider={account.provider}
                  topLine={account.provider.toUpperCase()}
                  bottomLine={account.email}
                  className={s.code}
                />
              ))}
            </>
          )}
      </div>
    </Modal>
  );
};

export default TFAModal;
