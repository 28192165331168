import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'next/navigation';
import { nanoid } from 'nanoid';
import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Plus from '@components/Icons/Plus';
import type { FormRequestTemplateTag } from '@typings/api-models';
import { HookResponse, PAGE_SIZE, ResourceType } from './common';

const getDetailPagePath: HookResponse['getDetailPagePath'] = ({ id }) => getUrl(IDENTIFIERS.DASHBOARD_FORM_REQUESTS_EDIT, { id });

const useFormRequest = ({
  creatorId,
  search,
  resourceType,
  tags,
}: {
  creatorId: string | undefined;
  search: string | null;
  resourceType: ResourceType;
  tags?: string[];
}): HookResponse & {
  createFormRequestsModalOpen: boolean;
  setCreateFormRequestsModalOpen: (v: boolean) => void;
  formRequestTemplateTags: FormRequestTemplateTag[];
} => {
  const [createFormRequestsModalOpen, setCreateFormRequestsModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const pathParams = useParams<{ id?: string }>();
  const [items, setItems] = useState<HookResponse['items']>([]);
  const [formRequestTemplateTags, setFormRequestTemplateTags] = useState<FormRequestTemplateTag[]>([]);
  const mostRecentRequestRef = useRef<string | null>(null);

  const { getData: getCreatorFormRequestsFn } = useNoodleApi(tsClient.formRequests.getCreatorFormTemplates);
  const { getData: getCreatorsFormRequestTemplateTagsFn } = useNoodleApi(tsClient.formRequests.getCreatorsFormRequestTemplateTags);
  const selectedId = pathParams?.id;

  const loadPage = useCallback(async ({
    page, search: newSearch, tags: newTags,
  }: { search:string | null, page: number, tags?: string[] }): Promise<void> => {
    if (creatorId && resourceType === 'form-requests') {
      setCurrentPage(page);
      const thisRequestId = nanoid();
      mostRecentRequestRef.current = thisRequestId;

      setIsFetching(true);
      const response = await getCreatorFormRequestsFn({
        creatorId,
        page,
        perPage: PAGE_SIZE,
        search: newSearch ?? undefined,
        tags: newTags && newTags.length > 0 ? newTags.join(',') : undefined,
      });

      if (mostRecentRequestRef.current === thisRequestId) {
        if (response.data) {
          setItems(response.data.items.map(template => ({
            href: getUrl(IDENTIFIERS.DASHBOARD_FORM_REQUESTS_EDIT, { id: template.id }),
            id: template.id,
            label: template.name,
          })));
          setNumPages(response.data.numPages);
        }
        setCreateFormRequestsModalOpen(false);
        setIsFetching(false);
      }
    } else {
      setItems([]);
    }
  }, [
    creatorId,
    resourceType,
    getCreatorFormRequestsFn,
  ]);

  const loadFormRequestTemplateTags = useCallback(async () => {
    if (creatorId) {
      const response = await getCreatorsFormRequestTemplateTagsFn({ creatorId });
      setFormRequestTemplateTags(response.data || []);
    } else {
      setFormRequestTemplateTags([]);
    }
  }, [creatorId, getCreatorsFormRequestTemplateTagsFn]);

  useEffect(() => {
    loadFormRequestTemplateTags();
  }, [loadFormRequestTemplateTags]);

  useEffect(() => {
    loadPage({ page: 1, search, tags });
  }, [loadPage, search, tags]);

  const headerActions = [{
    icon: Plus,
    label: 'Create Form Request',
    onClick: () => setCreateFormRequestsModalOpen(true),
  }];

  const pagination: HookResponse['pagination'] = {
    numPages,
    onChangePage: (newPage) => {
      loadPage({ page: newPage, search });
    },
    page: currentPage,
  };

  return useMemo(() => ({
    createFormRequestsModalOpen,
    formRequestTemplateTags,
    getDetailPagePath,
    headerActions,
    ifNoItemsText: 'No form requests yet',
    isFetchingList: isFetching,
    items,
    pagination,
    reloadList: () => loadPage({ page: 1, search }),
    selectedId,
    setCreateFormRequestsModalOpen,
  }), [
    createFormRequestsModalOpen,
    getDetailPagePath,
    headerActions,
    isFetching,
    items,
    pagination,
    selectedId,
    setCreateFormRequestsModalOpen,
    formRequestTemplateTags,
  ]);
};

export default useFormRequest;
