type Permissions = {
  viewPermissions: string[];
  commentPermissions: string[];
  replyPermissions: string[];
};

type Tier = {
  id: string;
  prices: {
    price: number;
  }[];
};

type Props = {
  currentPermissions: Permissions;
  selectedTier: Tier;
  tiers?: Tier[] | null;
};

const getUpdatedTierPermissions = ({ currentPermissions, selectedTier, tiers }: Props): Permissions => {
  if (currentPermissions.viewPermissions.includes(selectedTier.id)) {
    return {
      ...currentPermissions,
      commentPermissions: currentPermissions.commentPermissions.filter((t) => t !== selectedTier.id),
      replyPermissions: currentPermissions.replyPermissions.filter((t) => t !== selectedTier.id),
      viewPermissions: currentPermissions.viewPermissions.filter((t) => t !== selectedTier.id),
    };
  }
  const tiersToAdd = [selectedTier.id];
  // If adding a free tier, add all exclusive tiers as well
  if (selectedTier.prices?.find((p) => p.price === 0)) {
    const exclusiveTiers = tiers?.filter((t) => t.prices?.find(p => p.price > 0));
    if (exclusiveTiers?.length) {
      tiersToAdd.push(...exclusiveTiers.map((t) => t.id));
    }
  }
  return {
    ...currentPermissions,
    commentPermissions: [...currentPermissions.commentPermissions, ...tiersToAdd],
    replyPermissions: [...currentPermissions.replyPermissions, ...tiersToAdd],
    viewPermissions: [...currentPermissions.viewPermissions, ...tiersToAdd],
  };
};

export default getUpdatedTierPermissions;
