import Modal from '@components/Modal';
import React, {ReactElement, useContext} from 'react';
import { DEFAULT_CURRENCY } from '@typings/common';
import CreateMembershipForm from 'components/CreateMembershipModal/CreateMembershipForm';
import useNoodleApi from "@hooks/useNoodleApi";
import * as tsClient from "@tsClient";
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import {NoodleProductTypes} from "@typings/api-models";
import {CreateMembership} from "@components/CreateMembershipModal/types";
import {Currency, Interval, StripePriceUsageType} from '@typings/graphql-models';
import s from './CreateMembershipModal.module.scss';

const CreateMembershipModal = ({ setIsVisible, title }: { setIsVisible: (isVisible: boolean) => void, title?: string }): ReactElement => {
  const {creator} = useContext(DashboardContext);
  const { getData: createProductFn } = useNoodleApi(tsClient.createProduct, { toastOnError: true });
  const { getData: createTierFn } = useNoodleApi(tsClient.createTier, { toastOnError: true });
  const { getData: createPriceFn } = useNoodleApi(tsClient.createPrice);
  const { getData: updateSubscriptionModelFn } = useNoodleApi(tsClient.updateSubscriptionModel);

  const handleSubmit = async (data: CreateMembership): Promise<void> => {
    if (creator) {
      const productRes = await createProductFn({
        creatorId: creator.id,
        image: data.imageId,
        includesBroadcasts: data.includesBroadcasts,
        productTitle: data.title,
        productType: NoodleProductTypes.Subscription,
      });
      if (productRes.data) {
        const product = productRes.data;
        const productId = product.id;
        const tierRes = await createTierFn({
          description: data.description,
          productId,
          title: data.tierTitle,
        });
        if (tierRes.data) {
          const tierId = tierRes.data.id;
          await Promise.all([data.monthlyPrice, data.yearlyPrice]
            .map((price, index) => (
              createPriceFn({
                priceData: {
                  currency: creator.defaultCurrency ?? Currency.Usd,
                  isActive: true,
                  price: parseFloat(price),
                  recurringInterval: !index ? Interval.Month : Interval.Year,
                  tierId,
                  usageType: StripePriceUsageType.Recurring,
                },
                productId,
              })
            )));
          if (product.subscriptionModel) {
            await updateSubscriptionModelFn({
              id: product.subscriptionModel.id,
              updates: {
                productTypes: {set: data.productTypes},
              },
            });
          }
        }
      }
    }
    setIsVisible(false);
  };
  return (
    <Modal title={'Create Service'} onClose={() => setIsVisible(false)} >
      <div className={s['modal-container']}>
        <CreateMembershipForm onSubmit={handleSubmit} passedTitle={title} currency={creator?.defaultCurrency || DEFAULT_CURRENCY}/>
      </div>
    </Modal>
  );
};

export default CreateMembershipModal;
