import React, { ReactElement, useContext, useEffect, useState } from 'react';
import Buttons from '@components/Buttons';
import useNoodleApi from '@hooks/useNoodleApi';
import uploadLogoUnwrapped from '@helpers/uploadLogo';
import InputField from '@components/FormFields/InputField';
import ThumbnailInput from '@lib/HandbookProduct/HandbookPriceModal/ThumbnailInput/ThumbnailInput';
import CollectionProductsContainer from '@modals/CreateCollectionModal/CollectionProductsContainer/CollectionProductsContainer';
import { createProductType, updateProduct } from '@tsClient';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import getCreatorCollections from '@tsClient/getCreatorCollections';
import updateProductType from '@tsClient/product-type/updateProductType';
import PlusCircle from '@components/Icons/PlusCircle';
import ByteItem from '@components/CreateCollectionForm/ByteItem/ByteItem';
import Divider from '@components/Divider';
import AddByteInput from '@components/CreateCollectionForm/AddByteInput/AddByteInput';
import s from './CreateCollectionForm.module.scss';

type Collection = Awaited<ReturnType<typeof getCreatorCollections>>[0]['productTypes'][0];
type CollectionFormProps = {
  collectionId?: string;
  close: () => void;
  creatorSlug?: string;
  refetchCollection: () => void;
  isAddByteOpen?: boolean;
  passedTitle?: string;
};
type ThisProduct = {
  id: string;
  productTypes: Array<{ id: string }>;
  title: string;
  slug: string;
  stepsAmount: number;
};
const CreateCollectionForm = ({
  collectionId,
  close,
  creatorSlug,
  refetchCollection,
  isAddByteOpen,
  passedTitle,
}: CollectionFormProps): ReactElement => {
  const { creator, collections } = useContext(DashboardContext);

  const { getData: updateProductFn } = useNoodleApi(updateProduct, { toastOnError: true });
  const { getData: uploadLogo } = useNoodleApi(uploadLogoUnwrapped);
  const { getData: createCollectionFn } = useNoodleApi(createProductType);
  const { getData: updateCollectionFn } = useNoodleApi(updateProductType);

  const [collection, setCollection] = useState<Collection>();
  const [photoFile, setPhotoFile] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(passedTitle || '');
  const [imageId, setImageId] = useState('');
  const [error, setError] = useState(false);
  const [addByte, setAddByte] = useState(isAddByteOpen || false);
  const [isUploadingThumbnail, setIsUploadingThumbnail] = useState(false);
  const [selectedBytes, setSelectedBytes] = useState<ThisProduct[]>([]);

  useEffect(() => {
    if (title.length) {
      setError(false);
    }
  }, [title]);
  useEffect(() => {
    if (collections && collectionId) {
      const selectedCollection = collections.find(col => col.id === collectionId);
      setCollection(selectedCollection);
      setTitle(selectedCollection?.title ?? '');
      setPhotoFile(selectedCollection?.thumbnail?.url || '');
      setImageId(selectedCollection?.thumbnail?.id || '');
    }
  }, [collectionId]);

  const updateAvatar = async (newPhotoFile: string): Promise<void> => {
    setPhotoFile(newPhotoFile);
    setIsUploadingThumbnail(true);
    const response = await uploadLogo(newPhotoFile);
    if (response.data) {
      setImageId(response.data);
      setError(false);
      setIsUploadingThumbnail(false);
    }
  };

  const uploadBytesToCollection = async (id?: string): Promise<void> => {
    if (selectedBytes.length && creator && id) {
      await Promise.all(
        selectedBytes.map(byte =>
          updateProductFn({
            creatorSlug: creator.slug,
            productSlug: byte.slug,
            updates: {
              productTypes: { set: [...byte.productTypes, { id }] },
            },
          }),
        ),
      );
    }
  };
  const handleSubmit = async (): Promise<void> => {
    if (title) {
      setError(false);
      if (!creator?.slug && !creatorSlug) return;
      setLoading(true);
      if (collection?.slug) {
        const res = await updateCollectionFn({ creatorSlug: creator?.slug ?? creatorSlug ?? '', imageId, title }, collection?.slug);
        await uploadBytesToCollection(res?.data?.id);
      } else {
        const res = await createCollectionFn({ creatorSlug: creator?.slug ?? creatorSlug ?? '', imageId, title });
        await uploadBytesToCollection(res?.data?.id);
      }
      refetchCollection();
      setLoading(false);
      close();
    } else {
      setError(true);
    }
  };

  const handleByteClick = (prod: ThisProduct): void => {
    const duplicated = selectedBytes.some(p => p.id === prod.id) || collection?.products.some(p => p.id === prod.id);
    if (!duplicated) {
      setSelectedBytes(selectedBytes.concat(prod));
    }
    setAddByte(false);
  };

  const handleByteRemove = (id: string): void => {
    setSelectedBytes(selectedBytes.filter(byte => byte.id !== id));
  };

  if (addByte) {
    return <AddByteInput collection={collection} onClick={handleByteClick} close={() => setAddByte(false)} />;
  }

  return (
    <div className={s['create-collection-container']}>
      <ThumbnailInput isUploading={isUploadingThumbnail} onChange={updateAvatar} file={photoFile} error={error} className={s['margin-bottom']} />
      <InputField
        id="title"
        name="title"
        values={{ title }}
        onChange={e => setTitle(e)}
        label="Collection title"
        placeholder="Title"
        className={error && !title.length ? s.error : undefined}
      />
      <CollectionProductsContainer collection={collection} />
      {selectedBytes.map((prod, i) => (
        <div key={`selected-byte-${i}`}>
          <ByteItem id={prod.id} title={prod.title} stepAmount={prod.stepsAmount} onRemove={handleByteRemove} />
          <Divider dashed />
        </div>
      ))}
      <Buttons isWrapper className={s['add-button']} onClick={() => setAddByte(true)}>
        <PlusCircle weight="fill" color="#006DFF" height={20} width={20} />
        <p>Add byte</p>
      </Buttons>
      <Buttons title="Save" type="submit" isSecondary isFullWidth className={s.button} onClick={handleSubmit} isShimmering={loading}>
        Save
      </Buttons>
    </div>
  );
};

export default CreateCollectionForm;
