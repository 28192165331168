import { FC, useContext } from 'react';
import Modal from '@components/Modal';
import { useIsMobile } from '@hooks';
import Spacer from '@components/Spacer';
import CheckCircle from '@components/Icons/CheckCircle';
import { PWAContext } from '@providers/PWA';
import { NoodleIconWithBadge } from '@lib/Creators/Dashboard/GetNoodleApp';
import InstallNoodleAppButton from './InstallNoodleAppButton';
import Description from './Description';
import s from './GetNoodleAppModal.module.scss';

type Props = {
  setIsVisible: (isOpen: boolean) => void;
};

const CHECK_BOXES = [
  'Instant startup from your phone',
  'One touch access to the dashboard',
  'Easy payment collection',
  'Messaging & commenting',
  'Instant notifications on new messages',
  'Lead notifications',
  'Payment notifications',
];

const GetNoodleAppModal: FC<Props> = ({ setIsVisible }) => {
  const { isReadyToInstall, installType, doInstall, isInApp } = useContext(PWAContext);

  const isMobile = useIsMobile();
  return (
    <Modal
      containerStyle={isMobile ? {} : { width: '80%' }}

      title='Get the Noodle app'
      onClose={() => setIsVisible(false)}

    >
      <Spacer size='24px' />
      <div className={s.bannerContainer}>
        <div />
        <NoodleIconWithBadge />
        <div />
      </div>
      <div className={s.container}>
        <div className={s.centered}>
          <Spacer size='24px' />
          <Description
            isInApp={isInApp}
            installType={installType}
            isReadyToInstall={isReadyToInstall}
            doInstall={doInstall}
          />
        </div>
        <Spacer size='32px' />
        <ul>
          {CHECK_BOXES.map((b) => (
            <li key={b}>
              <CheckCircle weight='fill' color="var(--color-success)" size={16} />
              <div>
                <p>{b}</p>
              </div>
            </li>
          ))}
        </ul>
        <Spacer size='24px' />
        <InstallNoodleAppButton
          isInApp={isInApp}
          installType={installType}
          isReadyToInstall={isReadyToInstall}
          doInstall={doInstall}
        />
      </div>
    </Modal>
  );
};

export default GetNoodleAppModal;
