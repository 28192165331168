import { IDENTIFIERS, getUrl } from '@helpers/urlsHelper';
import * as ApiModels from '@typings/api-models';
import ListAndDetailsSubLayout from '@layouts/ListAndDetailsSubLayout';
import useNoodleApi from '@hooks/useNoodleApi';
import TeamsContext from '@providers/Teams/TeamsContext';
import { nanoid } from 'nanoid';
import { useParams } from 'next/navigation';
import { useContext, useState, useRef, useCallback, useEffect } from 'react';
import * as tsClient from '@tsClient';
import { JobStatus, JobType, useJobContext } from '@providers/Jobs';
import type { HookResponse, ResourceType } from './common';
import { PAGE_SIZE } from './common';

type Comment = Pick<ApiModels.Message, 'id' | 'text'> & {
  owner: {
    primaryColour: { hex: string } | null;
    name: string | null;
    email: string | null;
    image: {
      url: string;
    } | null;
  } | null;
  product: {
    title?: string | null;
  } | null;
  taggedUsers: Array<Pick<ApiModels.Person, 'id' | 'name'>>;
  userWorkflow: {
    product: {
      title?: string | null;
    } | null;
  } | null;
};

type Item = HookResponse['items'][number];

const parseTaggedUsers = ({ message, taggedUsers }: { message: string; taggedUsers: Pick<ApiModels.Person, 'id' | 'name'>[] }): string => {
  if (taggedUsers.length === 0) {
    return message;
  }
  const taggedUserIds: string[] = (message.match(/({{@)(.*?)(?=}})/gm) || []).map(id => id.replace('{{@', ''));
  let replacedMessage = message;
  taggedUsers.forEach(user => {
    replacedMessage = replacedMessage.replace(new RegExp(`{{@${user.id}}}`, 'g'), `@${user.name || user.id}`);
  });
  taggedUserIds.forEach(id => {
    if (!taggedUsers.find(u => u.id === id)) {
      replacedMessage = replacedMessage.replace(new RegExp(`{{@${id}}}`, 'g'), '@{Deleted User}');
    }
  });
  return replacedMessage;
};

const commentToItem = ({ comment }: { comment: Comment }): Item => {
  const href = getUrl(IDENTIFIERS.DASHBOARD_COMMENT_DETAILS, { id: comment.id });
  const name = comment.owner?.name || comment.owner?.email || 'No name';
  const avatar = comment.owner
    ? {
      color: comment.owner?.primaryColour?.hex ?? undefined,
      name,
      url: comment.owner.image?.url ?? undefined,
    }
    : undefined;
  const completeText = parseTaggedUsers({ message: comment.text || '', taggedUsers: comment.taggedUsers });

  return {
    avatar,
    bottomLine: completeText ?? undefined,
    href,
    id: comment.id,
    label: name,
    topLine: comment.product?.title || comment.userWorkflow?.product?.title || undefined,
  };
};

type Filters = 'needs_response';

const getDetailPagePath: Parameters<typeof ListAndDetailsSubLayout>[0]['getDetailPagePath'] = ({ id }) =>
  getUrl(IDENTIFIERS.DASHBOARD_COMMENT_DETAILS, { id });

const useComments = ({ resourceType }: { resourceType: ResourceType }): HookResponse => {
  const { jobs } = useJobContext();
  const completedCommentJobsCount = jobs.filter(j => j.type === JobType.COMMENT_REPLY && j.status === JobStatus.COMPLETED).length;
  const pathParams = useParams<{ id?: string }>();
  const { currentTeamOwner } = useContext(TeamsContext);
  const mostRecentRequestId = useRef<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [itemsCount, setItemsCount] = useState<number | undefined>(undefined);
  const [numNeedsResponse, setNumNeedsResponse] = useState(0);

  const [items, setItems] = useState<Parameters<typeof ListAndDetailsSubLayout>[0]['items']>([]);

  const { getData: getCreatorComments } = useNoodleApi(tsClient.getCreatorComments);

  const [selectedFilters, setSelectedFilters] = useState<Filters[]>([]);

  const creatorId = currentTeamOwner?.id;
  const selectedId = pathParams?.id;

  const loadPage = useCallback(
    async ({ page, filter }: { page: number; filter: Filters[] }): Promise<void> => {
      setCurrentPage(page);
      if (creatorId && resourceType === 'comments') {
        setIsFetching(true);
        const thisRequestId = nanoid();
        mostRecentRequestId.current = thisRequestId;
        const getResponse = await getCreatorComments({
          creatorId,
          filter: filter.includes('needs_response') ? 'needs_response' : 'all',
          page,
          perPage: PAGE_SIZE,
        });
        if (thisRequestId === mostRecentRequestId.current) {
          if (getResponse.data) {
            setItems(getResponse.data.items.map(comment => commentToItem({ comment })));
            setNumPages(getResponse.data.numPages);
          }
          setIsFetching(false);
        }
      } else {
        setItems([]);
      }
    },
    [creatorId, resourceType, getCreatorComments],
  );

  const loadCounts = useCallback(async (): Promise<void> => {
    if (creatorId && resourceType === 'comments') {
      const [allResponse, needsReponseResponse] = await Promise.all([
        await getCreatorComments({
          creatorId,
          filter: 'all',
          page: 1,
          perPage: 1,
        }),
        await getCreatorComments({
          creatorId,
          filter: 'needs_response',
          page: 1,
          perPage: 1,
        }),
      ]);
      setNumNeedsResponse(needsReponseResponse.data?.numItems ?? 0);
      setItemsCount(selectedFilters.includes('needs_response') ? needsReponseResponse.data?.numItems : allResponse.data?.numItems);
    }
  }, [getCreatorComments, resourceType, creatorId, selectedFilters]);

  const reloadListView = async (): Promise<void> => {
    await Promise.all([loadPage({ filter: selectedFilters, page: currentPage }), loadCounts()]);
  };

  const handlePageChange = (newPage: number): void => {
    loadPage({
      filter: selectedFilters,
      page: newPage,
    });
  };

  useEffect(() => {
    if (creatorId) {
      loadPage({
        filter: selectedFilters,
        page: currentPage,
      });
    }
    // only rerun this useEffect when completedCommentJobsCount changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCommentJobsCount]);

  useEffect(() => {
    if (creatorId) {
      loadPage({
        filter: selectedFilters,
        page: 1,
      });
    }
  }, [loadPage, creatorId, selectedFilters]);

  useEffect(() => {
    loadCounts();
  }, [loadCounts]);

  const filters: NonNullable<HookResponse['filters']> = {
    items: [
      {
        isMultiSelect: true,
        items: [
          {
            isChecked: selectedFilters.includes('needs_response'),
            key: 'needs_response',
            label: `Needs Response (${numNeedsResponse})`,
          },
        ],
        label: 'Type',
        onChange: newFilters => setSelectedFilters(newFilters as Filters[]),
      },
    ],
  };

  const pagination = {
    numPages,
    onChangePage: handlePageChange,
    page: currentPage,
  };

  return {
    filters,
    getDetailPagePath,
    ifNoItemsText: 'No Comments',
    isFetchingList: isFetching,
    items,
    itemsCount,
    pagination,
    reloadListView,
    selectedId,
  };
};

export default useComments;
