import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import promiseRetry from 'promise-retry';
import InputField from '@components/FormFields/InputField/InputField';

import InputComposer from '@components/InputComposer';
import Buttons from '@components/Buttons/Buttons';
import { logError } from '@providers/ErrorTracking';
import useNoodleApi from '@hooks/useNoodleApi';
import { postNewMessage, updateMessage } from '@tsClient';
import * as ApiModels from '@typings/api-models';
import { Descendant } from 'slate';
import createRichTextAst from '@helpers/createRichTextAst';
import { deserializeHtml, slateHasText } from '@components/SlateEditor';
import { mixpanelTrack } from '@providers/Mixpanel';
import CheckBox from '@components/FormFields/CheckBox';
import getUpdatedTierPermissions from '@helpers/getUpdatedTierPermissions';
import { useJobContext } from '@providers/Jobs';
import { nanoid } from 'nanoid';
import { JobStatus, JobType } from '@providers/Jobs/Context';
import { NEXT_PUBLIC_FEATURE_DRAFT_BROADCASTS_ENABLED } from '@configuration/client';
import handleBroadcastTags from '@tsClient/handleBroadcastTags';
import removeNullish from '@helpers/removeNullish';
import classNames from 'classnames';
import Header from '@components/DesignLibrary/Header';
import s from './CreateEditBrodcast.module.scss';
import TierPermissionsSelect from './TierPermissionsSelect';

type Values = {
  description: { children: Descendant[] }[] | null;
  media: { id: string; uploadPercentage?: number }[];
  files: (File | string)[];
  messageType: ApiModels.MessageType;
  handbooks: { id: string }[];
  title: string;
  tags?: string[] | undefined;
};

type Permissions = {
  viewPermissions: string[];
  commentPermissions: string[];
  replyPermissions: string[];
};

type Tier = {
  id: string;
  title: string;
  description: string;
  productId?: string | null;
  isActive: boolean;
  prices: {
    id: string;
    isActive?: boolean | null;
    price: number;
  }[];
};

type Props = {
  broadcast?: Pick<ApiModels.Message, 'id' | 'text' | 'title' | 'type'> & {
    viewPermissions: { id: string }[];
    commentPermissions: { id: string }[];
    replyPermissions: { id: string }[];
    medias: {
      id: string;
      name: string | null;
    }[];
    richText?: { html: string } | null;
    handbooks?: {
      id: string;
      name?: string | null;
    }[];
    tags: {
      id: string;
      name: string;
    }[];
  };
  creatorName?: string | null;
  creatorSlug: string;
  productSlug: string;
  onPublish: (broadcast: { id: string } | null) => Promise<unknown>;
  tiers?: Tier[] | null;
  isCommunityPost: boolean;
  isInDashboard?: boolean;
};

const CreateBroadcast = ({
  broadcast,
  creatorName,
  creatorSlug,
  productSlug,
  tiers,
  onPublish,
  isCommunityPost,
  isInDashboard,
}: Props): ReactElement => {
  const { addJob, jobs } = useJobContext();
  const uploadJobsRef = useRef<typeof jobs>([]);
  const completedJobsCount = jobs.filter(j => j.type === JobType.MESSAGE_PUBLISH && j.status === JobStatus.COMPLETED).length;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const jobCorrelationId = useMemo(() => nanoid(), [completedJobsCount]); // new job id when a create finishes
  const uploadJobs = jobs.filter(j => j.correlationId === jobCorrelationId);
  uploadJobsRef.current = uploadJobs;
  const { fetchingState: createFetchingState, getData: postMessageFn } = useNoodleApi(postNewMessage, {
    healthMonitor: { name: 'send-message' },
  });
  const { fetchingState: updateFetchingState, getData: updateMessageFn } = useNoodleApi(updateMessage);
  const { getData: handleBroadcastsTags } = useNoodleApi(handleBroadcastTags);

  const [values, setValues] = useState<Values>({
    description: broadcast?.richText ? null : [createRichTextAst(broadcast?.text || '')],
    files: broadcast?.medias?.map(m => m.id) || [],
    handbooks: broadcast?.handbooks?.map(h => ({ id: h.id })) || [],
    media: broadcast?.medias?.map(m => ({ id: m.id })) || [],
    messageType: broadcast?.type || (isCommunityPost ? ApiModels.MessageType.CommunityPost : ApiModels.MessageType.PaidBroadcast),
    tags: broadcast?.tags.map(tag => tag.name),
    title: broadcast?.title || '',
  });
  const [isPendingCreateJob, setIsPendingCreateJob] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [permissions, setPermissions] = useState<Permissions>({
    commentPermissions: broadcast?.commentPermissions?.map(p => p.id) || [],
    replyPermissions: broadcast?.replyPermissions?.map(p => p.id) || [],
    viewPermissions: broadcast?.viewPermissions?.map(p => p.id) || [],
  });

  const handleSlateChange = (key: string, newValue: { children: Descendant[] }[]): void => {
    setValues(val => ({ ...val, [key]: newValue }));
  };
  const handleInputChange = (key: string, newValue: string): void => {
    setValues(val => ({ ...val, [key]: newValue }));
  };
  const handleFileChange = (files: (File | string)[]): void => {
    setValues(val => ({ ...val, files }));
  };
  const handleMediaChange = (media: { id: string; uploadPercentage?: number }[]): void => {
    setValues(val => ({ ...val, media }));
  };
  const handleHandbookChange = (handbooks: { id: string }[]): void => {
    setValues(val => ({ ...val, handbooks }));
  };
  const publishBroadcast = async (): Promise<void> => {
    setIsPendingCreateJob(true);
    const publishFn = async (addedMedia?: { id: string }[]): Promise<{ id: string } | null> => {
      const handbooks = values.handbooks.map(h => ({ id: h.id }));
      let medias: Array<{ id: string }> | undefined;
      if (addedMedia && addedMedia.length > 0) {
        const existingMedia = values?.media?.map(m => ({ id: m.id })) || [];
        medias = [...existingMedia, ...addedMedia.map(({ id }) => ({ id }))];
      }
      const commentPermissions = permissions.commentPermissions.map(id => ({ id }));
      const replyPermissions = permissions.replyPermissions.map(id => ({ id }));
      const viewPermissions = permissions.viewPermissions.map(id => ({ id }));
      const text = { children: values.description };
      const submitData = {
        commentPermissions,
        creatorSlug,
        handbooks,
        isPrivatePost: isPrivate,
        medias,
        messageType: values.messageType,
        productSlug,
        replyPermissions,
        sendAsCreator: !isCommunityPost,
        text: text as ApiModels.SlateNode,
        title: values.title,
        viewPermissions,
      };

      const mixpanelData = {
        hasDescription: slateHasText(values.description || []),
        messageType: values.messageType,
        numFiles: values.files.length,
        numHandbooks: handbooks.length,
        numMedia: medias === undefined ? 0 : medias.length,
      };

      mixpanelTrack('(Creator) Pressed Publish Button', {
        broadcastDescription: values.description,
        broadcastTitle: values.title,
      });

      if (broadcast?.id) {
        mixpanelTrack('(Creator) Edited Broadcast', {
          id: broadcast.id,
          ...mixpanelData,
        });

        const { error, data } = await updateMessageFn({
          messageId: broadcast?.id,
          ...submitData,
        });

        await handleBroadcastsTags({
          creatorSlug,
          messageId: broadcast.id,
          productSlug,
          tags: [],
        });

        if (error) {
          throw error;
        }
        setTimeout(() => onPublish(data ?? null), 500);
        return data?.id ? { id: data.id } : null;
      }

      mixpanelTrack('(Creator) Published Broadcast', mixpanelData);
      const { error, data } = await postMessageFn(submitData);
      if (error) {
        throw error;
      }
      if (data) {
        await handleBroadcastsTags({
          creatorSlug,
          messageId: data.id,
          productSlug,
          tags: [],
        });
        setTimeout(() => onPublish(data ?? null), 500);
      }
      return data?.id ? { id: data?.id } : null;
    };

    if (!NEXT_PUBLIC_FEATURE_DRAFT_BROADCASTS_ENABLED) {
      addJob({
        dependsOn: uploadJobs,
        function: publishFn,
        title: values.title,
        type: JobType.MESSAGE_PUBLISH,
      });
    } else {
      try {
        await promiseRetry(
          async retry => {
            try {
              // Grab from uploadJobsRef.current because uploadJobs isn't the latest (cuz closure)
              const uploadMediaIds = uploadJobsRef.current
                .map(job => (job.uploadMedia?.id ? { id: job.uploadMedia?.id } : null))
                .filter(removeNullish);
              if (uploadJobsRef.current.length === uploadMediaIds.length) {
                const broadcastRes = await publishFn(uploadMediaIds);
                if (!broadcastRes?.id) {
                  addJob({
                    dependsOn: uploadJobs,
                    function: publishFn,
                    title: values.title,
                    type: JobType.MESSAGE_PUBLISH,
                  });
                } else {
                  await handleBroadcastsTags({
                    creatorSlug,
                    messageId: broadcastRes?.id,
                    productSlug,
                    tags: [],
                  });
                }
              } else {
                throw new Error('Some upload jobs missing the media.id');
              }
            } catch (retryableError) {
              retry(retryableError);
            }
          },
          {
            factor: 1,
            minTimeout: 1500,
            retries: 20,
          },
        );
      } catch (error) {
        logError(error, { tags: { flow: 'chat' } });
      }
    }
  };

  const handleTierChange = (selectedTier: Parameters<typeof getUpdatedTierPermissions>[0]['selectedTier']): void => {
    const newPermissions = getUpdatedTierPermissions({
      currentPermissions: permissions,
      selectedTier,
      tiers,
    });
    setPermissions(newPermissions);
  };

  const handleLockChange = (tier: Pick<Tier, 'id'>): void => {
    const tierId = tier.id;
    if (permissions.commentPermissions.includes(tierId)) {
      setPermissions({
        ...permissions,
        commentPermissions: permissions.commentPermissions.filter(t => t !== tierId),
        replyPermissions: permissions.replyPermissions.filter(t => t !== tierId),
      });
    } else {
      setPermissions({
        ...permissions,
        commentPermissions: [...permissions.commentPermissions, tierId],
        replyPermissions: [...permissions.replyPermissions, tierId],
      });
    }
  };

  useEffect(() => {
    if (broadcast?.richText?.html) {
      const document = new DOMParser().parseFromString(broadcast.richText.html, 'text/html');
      setValues(oldValues => ({
        ...oldValues,
        description: deserializeHtml(document.body) as { children: Descendant[] }[],
      }));
    }
  }, [broadcast?.richText]);

  const hasSomeContent = slateHasText(values.description || [])
    || jobs.filter(j => j.type === JobType.MESSAGE_MEDIA_UPLOAD && j.correlationId === jobCorrelationId).length
    || values.handbooks.length > 0;

  const isSendDisabled = !values.title || (!isCommunityPost && permissions.viewPermissions.length === 0) || !hasSomeContent
    || !uploadJobs.every(job => job.uploadMedia?.id) || uploadJobs.some(j => j.status === JobStatus.FAILED);

  const hasPendingCreateJob = Boolean(jobs.find(j => j.type === JobType.MESSAGE_PUBLISH && j.status !== JobStatus.COMPLETED));

  return (
    <>
      {!broadcast && isInDashboard && <Header title="Create Broadcast" />}
      <div className={classNames(s.wrapper, !broadcast && isInDashboard && s.padding)}>
        <InputField
          id="broadcast_title"
          name="title"
          label="Title"
          placeholder={isCommunityPost ? 'Post Title' : 'Broadcast Title'}
          values={values}
          onChange={handleInputChange.bind(null, 'title')}
          hasFixedHeight={false}
        />
        {(broadcast ? values?.description : true) && (
          <div>
            <InputComposer
              isAsync
              rows={3}
              isRichText
              showAddVideo
              isLoomEnabled={!isCommunityPost}
              showAddHandbook={!isCommunityPost}
              isFetching={false}
              showProTips={false}
              label="Description"
              showSendButton={false}
              placeholder="Description"
              initialText={values.description}
              onChangeFiles={handleFileChange}
              onChangeMedia={handleMediaChange}
              jobCorrelationId={jobCorrelationId}
              onChangeHandbooks={handleHandbookChange}
              onChangeText={handleSlateChange.bind(null, 'description')}
              initialMedia={broadcast?.medias?.[0] ? [broadcast?.medias?.[0]] : []}
              referenceId={broadcast ? broadcast.id : productSlug}
              referenceType={broadcast ? 'edit-broadcast' : 'create-broadcast'}
            />
          </div>
        )}
        {tiers && tiers?.length > 0 && !isCommunityPost && (
          <div className={s['permissions-container']}>
            <p className="body-md-bold">Which tiers should have access to this broadcast:</p>
            {tiers.map(tier => (
              <TierPermissionsSelect
                key={tier.id}
                tier={tier}
                tierCanView={permissions.viewPermissions.includes(tier.id)}
                tierCanComment={permissions.commentPermissions.includes(tier.id)}
                tierCanReply={permissions.replyPermissions.includes(tier.id)}
                handleLockChange={handleLockChange}
                handleTierChange={handleTierChange}
              />
            ))}
            <p className={s['pro-tip']}>
              <strong>Pro Tip:</strong> Lock the comments on a free broadcast if you want to give free members a preview in the main message, but add
            more content in the comments exclusively for paid members.
            </p>
          </div>
        )}
        {values.messageType === ApiModels.MessageType.FreeBroadcast && (
          <p className={s['free-broadcast-text']}>All members of your community will be able to view and comment on this broadcast.</p>
        )}
        {isCommunityPost && creatorName && (
          <div className={s['wrapper-toggle']}>
            <p className={classNames(s['wrapper-toggle-text'], 'caption')}>{`Private (for ${creatorName}'s eyes only)`}</p>
            <div className={s['wrapper-toggle-switch']}>
              <CheckBox
                id={'isPrivatePost'}
                isSwitch
                hasFixedHeight={false}
                disabled={false}
                onChange={() => setIsPrivate(!isPrivate)}
                values={{ isPrivate }}
                name="isPrivate"
                rules={{ isRequired: true }}
              />
            </div>
          </div>
        )}
        <Buttons
          isShimmering={createFetchingState.isFetching || updateFetchingState.isFetching || hasPendingCreateJob || isPendingCreateJob}
          isFullWidth
          isSecondary
          disabled={isSendDisabled}
          onClick={publishBroadcast}
        >
          {broadcast ? "Update" : `Send ${isCommunityPost ? 'Post' : 'Broadcast'}`}
        </Buttons>
      </div>
    </>
  );
};

export default CreateBroadcast;
