import { useEffect } from 'react';
import { InstallType } from '@helpers/pwa';
import Buttons from '@components/Buttons';
import { mixpanelTrack } from '@providers/Mixpanel';

type Props = {
  doInstall: () => void | Promise<void>;
  isReadyToInstall: boolean;
  isInApp: boolean;
  installType: InstallType;
};

const InstallNoodleAppButton: React.FC<Props> = ({ doInstall, installType, isInApp }) => {
  useEffect(() => {
    if (!isInApp) {
      mixpanelTrack('InstallNoodleApp - Saw Install Button', {
        installType,
        isInApp,
      });
    }
  }, [installType, isInApp]);

  const handleNativeInstall = (): void => {
    mixpanelTrack('InstallNoodleApp - Installed', {
      installType,
      isInApp,
      method: 'native',
    });
    doInstall();
  };

  if (isInApp) {
    return null;
  }

  if (installType === 'native') {
    return (
      <Buttons isSecondary isFullWidth onClick={handleNativeInstall}>
        Install the Noodle app
      </Buttons>
    );
  }

  return null;
};

export default InstallNoodleAppButton;
