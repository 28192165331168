import ArrowCircleLeft from '@components/Icons/ArrowCircleLeft';
import XCircle from '@components/Icons/XCircle';
import Modal from '@components/Modal';
import hasNoodleProductType from '@helpers/hasNoodleProductType';
import { useIsMobile } from '@hooks';
import * as ApiModels from '@typings/api-models';
import { Maybe, NoodleProductTypes } from '@typings/graphql-models';
import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { CartItem } from '@providers/ShoppingCart';
import useNoodleApi from '@/hooks/useNoodleApi';
import getTeamMembersWithAvailability from '@/tsClient/scheduling/getTeamMembersWithAvailability';
import Chips from '@/components/Chips';
import s from './InitiateBookingModal.module.scss';
import Review from './Review';
import SelectCustomer from './SelectCustomer';
import SelectConsultation from './SelectConsultation';
import AssignTimeSlot from './AssignTimeSlot';

type Customer = Pick<ApiModels.Person, 'id' | 'name' | 'email'>;

type ProductPrice = (Pick<ApiModels.Price, 'sessionDuration' | 'sessionInterval'> &
  CartItem & {
    priceDescription?: {
      html?: string | null;
    } | null;
  });

type Product = Pick<ApiModels.Product, 'id' | 'title' | 'isActive'> & {
  productTypes: Array<Pick<ApiModels.ProductType, 'noodleProductType' | 'id' | 'title'>>;
  prices: Array<Pick<ApiModels.Price,
    'price' | 'id' | 'sessionDuration' | 'sessionInterval' |"id" |
    "currency" | "numBookings" | "price" | "priceSubDescription" |
    "priceTitle" | "recurringInterval" | "sessionDuration" | "sessionInterval" | "sessionTime"
  > & ProductPrice>;
  creator?: {
    name?: string | null;
  } | null;
};
type Price = Product['prices'][0];

type Products = Array<Product> | null;

type TimeSlotProduct = {
  price: Price, sessionDuration: number, sessionTime: string
};

type Props = {
  creatorSlug: string;
  creatorTimezone: Maybe<string>;
  onClose: () => void;
  products: Products;
};

type PAGES = 'SelectConsultation' | 'SelectCustomer' | 'Review' | 'AssignTimeSlot';

const InitiateBookingModal: FC<Props> = ({ creatorSlug, onClose, products: unfilteredProducts, creatorTimezone }) => {
  const onlyFreePrices = (product: Product): Product => {
    const originalPrices = product.prices;
    const freePrices = originalPrices.filter((price) => price.price === 0);

    return {...product, prices: freePrices};
  };

  const isBookable = (product: Product): Product => {
    const originalPrices = product.prices;
    const bookablePrices = originalPrices.filter((price) => price.sessionDuration && price.sessionInterval);

    return {...product, prices: bookablePrices };
  };

  const freeConsultationProducts = useMemo(
    () =>
      unfilteredProducts
        ?.filter(product => hasNoodleProductType(product, NoodleProductTypes.Consultation))
        .map(product => onlyFreePrices(product))
        .map(product => isBookable(product))
        .filter(product => product.isActive)
        .filter(product => product.prices.length),
    [unfilteredProducts],
  );

  const fullProducts = freeConsultationProducts || [];
  const isMobile = useIsMobile();

  const modalBodyRef = useRef<HTMLDivElement>(null);

  const [currentPage, setCurrentPage] = useState<PAGES>('SelectConsultation');
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [_, setIsCreatingNewCustomer] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [selectedTimeSlotProduct, setSelectedTimeSlotProduct] = useState<TimeSlotProduct | null>(null);
  const [selectedTeamMemberPersonId, setSelectedTeamMemberPersonId] = useState<string | null>(null);

  const { getData: getTeamMembersWithAvailabilityFn, data: teamMembers } = useNoodleApi(getTeamMembersWithAvailability);

  useEffect(() => {
    modalBodyRef.current?.scrollTo?.(0, 0);
  }, [currentPage]);

  useEffect(() => {
    if (currentPage === 'SelectConsultation') {
      setCustomer(null);
      setProduct(null);
    }
  }, [currentPage]);

  useEffect(() => {
    if (product && product.id) {
      getTeamMembersWithAvailabilityFn({ productId: product.id });
    }
  }, [product, getTeamMembersWithAvailabilityFn]);

  useEffect(() => {
    if (teamMembers && teamMembers.length > 0) {
      setSelectedTeamMemberPersonId(teamMembers[0].id);
    }
  }, [teamMembers, setSelectedTeamMemberPersonId]);

  const handleConsultationSelect = (workflowId: string, productId: string): void => {
    const selectedConsultation = fullProducts.find(_product => _product.id === productId);

    if (selectedConsultation) {
      setProduct(selectedConsultation);
    }

    setCurrentPage('SelectCustomer');
  };

  const handleCustomerSelect = (newCustomer: Pick<ApiModels.Person, 'id' | 'name' | 'email'>): void => {
    setCustomer(newCustomer);
    setCurrentPage('AssignTimeSlot');
  };

  const handleClose = (): void => {
    if (currentPage === 'SelectConsultation') {
      onClose();
    } else if (currentPage === 'SelectCustomer') {
      setCurrentPage('SelectConsultation');
    } else if (currentPage === 'Review') {
      setCurrentPage('AssignTimeSlot');
    } else if (currentPage === 'AssignTimeSlot') {
      setCurrentPage('SelectCustomer');
    }
  };

  const getTitle = (): string => {
    if (currentPage === 'SelectCustomer') {
      return 'Select customer';
    }
    if (currentPage === 'AssignTimeSlot') {
      return 'Select date and time';
    }
    if (currentPage === 'Review') {
      return 'Review';
    }
    return 'Initiate Booking';
  };

  const getIcon = (): ReactElement => {
    if (currentPage === 'SelectConsultation') {
      return <XCircle weight="fill" size={24} color="var(--color-gray-100)" />;
    }
    return <ArrowCircleLeft weight="fill" size={24} color="var(--color-gray-100)" />;
  };

  const onDateTimeSelect = (
    selected: TimeSlotProduct): Promise<void> => {
    // Do this so we can get the price obj too
    setSelectedTimeSlotProduct(selected);
    return new Promise(() => {});
  };

  return (
    <Modal
      containerStyle={isMobile ? {} : { width: '80%' }}
      title={getTitle()}
      closeIcon={getIcon()}
      onClose={handleClose}
      ref={modalBodyRef}
    >
      <div className={s.container}>
        {currentPage === 'SelectConsultation' && (
          <SelectConsultation
            isFetching={!fullProducts}
            fullProducts={fullProducts}
            onConsultationSelect={handleConsultationSelect}
          />
        )}
        {currentPage === 'SelectCustomer' && (
          <SelectCustomer
            selectedCustomer={customer}
            setIsCreatingNewCustomer={setIsCreatingNewCustomer}
            creatorSlug={creatorSlug}
            onCustomerSelect={handleCustomerSelect}
          />
        )}
        {currentPage === 'AssignTimeSlot' && product && (
          <div>
            {teamMembers && (
              <div className={s.timeSelector}>
                <Chips
                  data={teamMembers.map((tm) => ({
                    id: tm.id,
                    label: tm.name || tm.email || tm.id,
                    userImage: {
                      color: tm.primaryColour?.hex,
                      name: tm.name || undefined,
                      url: tm.image?.url,
                    },
                  }))}
                  wrap
                  selectedItem={selectedTeamMemberPersonId}
                  onItemClick={(id) => id && setSelectedTeamMemberPersonId(id)}
                />
              </div>
            )}
            <AssignTimeSlot
              creatorTimezone={creatorTimezone}
              priceId={product?.prices?.[0]?.id}
              product={product}
              onDateTimeSelect={onDateTimeSelect}
              onClickCta={() => {
                setCurrentPage('Review');
              }}
              disabled={!selectedTimeSlotProduct}
              selectedTeamMemberPersonId={selectedTeamMemberPersonId ?? undefined}
            />
          </div>
        )}

        {currentPage === 'Review' && customer && product && selectedTimeSlotProduct && selectedTeamMemberPersonId && (
          <Review
            customer={customer}
            product={product}
            onClose={onClose}
            selectedTimeSlotProduct={selectedTimeSlotProduct}
            selectedTeamMemberPersonId={selectedTeamMemberPersonId}
            creatorTimezone={creatorTimezone}
          />
        )}
      </div>
    </Modal>
  );
};

export default InitiateBookingModal;
