import React, { ReactElement, useEffect, useState } from 'react';
import CheckBox from '@components/FormFields/CheckBox';
import Divider from '@components/Divider';
import { Collections } from '@components/CreateMembershipModal/types';
import s from './TiersContent.module.scss';

type TierProps = {
  broadcasts: boolean;
  setBroadcasts: (value: boolean) => void;
  selected: Collections;
  setCollections: (data: Collections) => void;
};
const MainSelect = ({
  value,
  setValue,
  title,
  description,
  id,
  name,
  additionalTitleInfo,
}: {
  value: Record<string, string | boolean>;
  setValue: () => void;
  title: string;
  description: string;
  id: string;
  name: string;
  additionalTitleInfo?: string;
}): ReactElement => (
  <div className={s['tiers-content-container__checkbox']}>
    <CheckBox
      id={id}
      name={name}
      values={value}
      onChange={setValue}
      disabled={false}
      color={'#006DFF'}
      hasFixedHeight={false}
      noFlex
      label={
        <div>
          <p className={s['tiers-content-container__checkbox__title']}>
            {title}
            {additionalTitleInfo && <span>{` (${additionalTitleInfo})`}</span>}
          </p>
          <p className={s['tiers-content-container__checkbox__description']}>{description}</p>
        </div>
      }
    />
  </div>
);

const TiersContent = ({ selected, setCollections, broadcasts, setBroadcasts }: TierProps): ReactElement => {
  const [allBytes, setAllBytes] = useState(false);

  const handleAllBytesClicked = (all: boolean): void => {
    if (all) {
      setCollections(selected.map(byte => ({ ...byte, selected: true })));
    } else {
      setCollections(selected.map(byte => ({ ...byte, selected: false })));
    }
    setAllBytes(all);
  };

  useEffect(() => {
    if (selected.length > 0) {
      if (selected.filter(e => !e.selected).length === 0) {
        setAllBytes(true);
      } else {
        setAllBytes(false);
      }
    }
  }, [selected]);

  const handleByteChange = (id: string): void => {
    setCollections(
      selected.map(byte => {
        if (byte.id === id) {
          return { ...byte, selected: !byte.selected };
        }
        return { ...byte };
      }),
    );
  };

  return (
    <div className={s['tiers-content-container']}>
      <p className={s['tiers-content-container__what-is']}>What Noodle content is included?</p>
      <MainSelect
        id={'broadcasts'}
        name={'broadcasts'}
        value={{ broadcasts }}
        setValue={() => setBroadcasts(!broadcasts)}
        title={'Broadcasts'}
        description="Access to your Broadcasts, where you can allow comments and interactions."
      />
      <Divider className={s['tiers-content-container__divider']} />
      <MainSelect
        id={'allBytes'}
        name={'allBytes'}
        value={{ allBytes }}
        setValue={() => handleAllBytesClicked(!allBytes)}
        title={'All Byte Collections'}
        additionalTitleInfo={selected.length.toString()}
        description="Access to select Bytes that you create."
      />
      <div style={{ maxHeight: '200px', overflowY: 'scroll', paddingLeft: '25px' }}>
        {selected.map(byte => (
          <div key={byte.id} style={{ alignItems: 'center', display: 'flex' }}>
            <CheckBox
              id={byte.id}
              name={byte.id}
              values={{ [byte.id]: byte.selected }}
              onChange={() => handleByteChange(byte.id)}
              hasFixedHeight={false}
              noFlex
              label={
                <p style={{ color: 'var(--color-gray-100)', fontSize: '15px', margin: 0 }}>
                  {byte.title}
                  <span style={{ color: 'var(--color-gray-75)', fontWeight: 300 }}>{` (${byte.products?.length})`}</span>
                </p>
              }
            />
          </div>
        ))}
      </div>
      <Divider className={s['tiers-content-container__divider']} />
    </div>
  );
};
export default TiersContent;
