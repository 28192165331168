import { FC } from 'react';
import Spacer from '@components/Spacer';
import { pickProductIcon } from '@components/ProductIcon';
import pluralize from 'pluralize';
import Buttons from '@components/Buttons/Buttons';
import * as ApiModels from '@typings/api-models';
import ProgressIndicator from '@components/ProgressIndicator';
import CollapsibleArea from '@components/CollapsibleArea';
import s from './InitiateCaseModal.module.scss';

type Props = {
  fullProducts: Array<
    Pick<ApiModels.Product, 'id' | 'title' | 'initiateCaseRank'> & {
      productTypes: Array<Pick<ApiModels.ProductType, 'noodleProductType'>>;
      workflows: Array<Pick<ApiModels.Workflow, 'id' | 'name'>>;
    }
  >;
  onWorkflowSelect: (workflowId: string) => void;
  isFetching: boolean;
};

const SelectWorkflow: FC<Props> = ({ isFetching, fullProducts, onWorkflowSelect }) => (
  <div className={s.workflowSelect}>
    {isFetching
      ? (
        <ProgressIndicator isCentered />
      )
      : (
        <>
          <p className="caption" style={{ color: 'var(--color-gray-75)' }}>
          Select product
          </p>
          <Spacer size="12px" />
          <div className={s.products}>
            {fullProducts.length === 0 && <p className="body-sm">You have no workflows that are available to initiate.</p>}
            {fullProducts.sort((a, b) => (a.initiateCaseRank ?? 999) - (b.initiateCaseRank ?? 999)).map(product => {
              const Icon = pickProductIcon(product.productTypes?.[0]?.noodleProductType);
              return (
                <CollapsibleArea
                  title={product.title as string}
                  description={pluralize('workflow', product.workflows.length, true)}
                  key={product.id}
                  Icon={Icon ? { Component: Icon, color: 'var(--color-noodle)' } : undefined}
                  className={s.details}
                >
                  <Spacer size="16px" />
                  <div className={s.workflows}>
                    {product.workflows.map(workflow => (
                      <Buttons key={workflow.id} isWrapper onClick={() => onWorkflowSelect(workflow.id)}>
                        <div className={s.workflow}>
                          <p className="caption">{workflow.name}</p>
                          <p className="caption-bold" style={{ color: 'var(--color-noodle)' }}>
                          Initiate
                          </p>
                        </div>
                      </Buttons>
                    ))}
                  </div>
                </CollapsibleArea>
              );
            })}
          </div>
        </>
      )}
  </div>
);

export default SelectWorkflow;
