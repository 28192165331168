import React, { useState } from 'react';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import X from '@components/Icons/X';
import Buttons from '@components/Buttons';
import { m } from 'framer-motion';
import s from './CropImage.module.scss';

type Props = {
  image: string;
  setIsShowImageCrop: (isShow: boolean) => void;
  setPhotoFile?: (photoFile: string) => void;
  setFile?: (file: File) => void;
};

const CropImage: React.FC<Props> = ({ image, setIsShowImageCrop, setPhotoFile, setFile }) => {
  const [cropper, setCropper] = useState<Cropper>();

  const saveCropData = async (): Promise<void> => {
    if (typeof cropper !== "undefined" && cropper.getCroppedCanvas()) {
      const cropData = cropper.getCroppedCanvas().toDataURL();
      const base64Response = await fetch(cropData);
      const blob = await base64Response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const currTime = new Date().toISOString();
      const file = new File([blob], `${currTime}-profile-photo`, { type: blob.type });
      setIsShowImageCrop(false);
      if (setPhotoFile) {
        setPhotoFile(blobUrl);
      }
      if (setFile) {
        setFile(file);
      }
    } else {
      setIsShowImageCrop(false);
      throw new Error('could not save photo');
    }
  };

  return (
    <>
      <m.div
        className={s['overlay-bg']}
        initial={{ backgroundColor: '#ffffff' }}
        animate={{ backgroundColor: '#00000050' }}
        exit={{ backgroundColor: '#ffffff' }}
        transition={{ duration: 0.1 }}
      />
      <div className={s['image-crop']}>
        <div className={s['image-crop__cancel']}>
          <b className={s['image-crop__title']}>Crop your photo</b>
          <button
            className={s['image-crop__cancel-btn']}
            onClick={() => setIsShowImageCrop(false)}
          >
            <X
              size={17}
              weight={'fill'}
              color={'var(--color-gray-0)'}
            />
          </button>
        </div>
        <Cropper
          style={{
            maxHeight:'50vh',
            maxWidth: '400px',
            objectFit: 'cover',
            width: '100%',
          }}
          initialAspectRatio={1}
          src={image}
          viewMode={1}
          aspectRatio={1}
          background={false}
          responsive={true}
          autoCropArea={1}
          zoomable={false}
          checkOrientation={true}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={false} />
        <Buttons
          className={s['image-crop__save']}
          isSecondary
          isFullWidth
          isLarge
          disabled={typeof cropper === "undefined" || Boolean(cropper.getCroppedCanvas())}
          onClick={saveCropData}
        >
          Save
        </Buttons>
      </div>
    </>
  );
};

export default CropImage;
