import { FC, useMemo, useRef, useState } from 'react';
import * as ApiModels from '@typings/api-models';
import { CartItem } from '@providers/ShoppingCart';
import Buttons from '@components/Buttons/Buttons';
import Spacer from '@components/Spacer';
import DOMPurify from 'isomorphic-dompurify';
import ProgressIndicator from '@components/ProgressIndicator';
import useNoodleApi from '@hooks/useNoodleApi';
import { rescheduleBooking } from '@tsClient';
import { useToast } from '@hooks';
import { ToastTypeVariants } from '@context/ToastContext';
import getTimezoneKey from '@helpers/getTimezoneKey';
import { Booking } from '@typings/api-models';
import * as format from '@format';
import Globe from '@components/Icons/Globe';
import s from './CustomerTimeSelector.module.scss';

type ThisPrice = Pick<ApiModels.Price, 'sessionDuration' | 'sessionInterval'> &
CartItem & {
  priceDescription?: {
    html?: string | null;
  } | null;
};

type ThisAvailability = {
  blockDuration: number;
  schedulingInterval: number;
  blocks: Array<{
    startAt: string;
  }>;
};

type Props = {
  price: ThisPrice;
  creatorTimezone: string | null;
  isLoading: boolean;
  availability?: ThisAvailability[];
  booking?: Pick<Booking, 'id' | 'startAt' | 'priceId'>;
  onFinish?: (price: CartItem) => Promise<void>;
  onDateTimeSelect?: (props: { price: CartItem, sessionDuration: number, sessionTime: string }) => Promise<void>;
  showDayOfWeek?: boolean;
  selectedDate?: string | null;
  selectedTeamMemberPersonId?: string | null;
  hidePriceDescription?: boolean;
};

const CustomerTimeSelector: FC<Props> = ({
  availability,
  creatorTimezone,
  isLoading,
  price,
  booking,
  onFinish,
  onDateTimeSelect,
  showDayOfWeek = false,
  selectedDate,
  selectedTeamMemberPersonId,
  hidePriceDescription = false,
}) => {
  const addToast = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);

  const ctaRef = useRef<HTMLDivElement>(null);

  const { getData: rescheduleBookingFn } = useNoodleApi(rescheduleBooking);
  const availableTimes = useMemo(() => {
    const blocks = availability?.filter(
      a => a.blockDuration === price?.sessionDuration && a.schedulingInterval === price?.sessionInterval,
    );
    const times = blocks
      ?.map(b => b.blocks)
      .reduce((acc, val) => {
        const arr = [...acc];
        const existingTimes = arr.map(b => b.startAt);
        val.forEach(it => {
          if (!existingTimes.includes(it.startAt)) {
            arr.push(it);
          }
        });
        return arr;
      }, [])
      .sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime());
    return times;
  }, [price, availability]);

  const handleBookingClick = async (): Promise<void> => {
    setIsSubmitting(true);
    if (price && selectedTime) {
      if (booking) {
        const { error } = await rescheduleBookingFn({
          bookingId: booking.id,
          productId: price.product.id,
          // Need to send the time in the creators TZ, so sending the time without the "Z"/timezone, the BE adjusts this based on the creators timezone
          startAt: selectedTime,
          teamMemberPersonId: selectedTeamMemberPersonId ?? undefined,
        });
        if (error) {
          setIsSubmitting(false);
          addToast(
            ToastTypeVariants.ERROR,
            `Failed to ${booking.startAt ? 're' : ''}schedule booking. Please contact support at support@noodle.shop.`,
          );
        } else {
          addToast(ToastTypeVariants.SUCCESS, `Your booking has been ${booking.startAt ? 're' : ''}scheduled!`);
          onFinish?.(price);
        }
      }
    }
  };

  return (
    <div className={s.container}>
      {price?.priceDescription?.html && !hidePriceDescription && (
        <>
          <div className={s.priceDescription} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(price?.priceDescription?.html || '') }} />
          <Spacer size="16px" />
        </>
      )}
      {showDayOfWeek && selectedDate
        ? (
          <div className={s.dayOfWeek}>
            <span className='body-md-bold'>{format.datetime.dayOfWeek(`${selectedDate}T00:00:00`)}</span>
            <span className='caption' style={{ color: 'var(--color-gray-75)' }}>{format.datetime.withoutTime(`${selectedDate}T00:00:00`)}</span>
            <Spacer size='20px' />
            <div className={s.timezone}>
              <Globe weight='fill' fill='var(--color-gray-75)' size={16} />
              <span className='caption' style={{ color: 'var(--color-gray-75)' }}>{creatorTimezone ? format.datetime.abbreviatedTimezone({ timezone: creatorTimezone }) : null}</span>
            </div>
            <Spacer size='16px' />
          </div>
        )
        : (
          <>
            <span className="body-md-bold">{(onFinish || onDateTimeSelect) ? 'Select Time' : 'Available Times'}</span>
            {creatorTimezone && (
              <span className="body-sm">{` (All times in ${getTimezoneKey(creatorTimezone)})`}</span>
            )}
            <Spacer size="16px" />
          </>
        )}
      {isLoading
        ? (
          <ProgressIndicator isCentered />
        )
        : (
          <>
            <div className={s.availableTimes} data-translate="false">
              {availableTimes?.map(t => (
                <Buttons
                  key={t.startAt}
                  onClick={() => {
                    setSelectedTime(t.startAt);
                    if (onDateTimeSelect && price && price.sessionDuration) {
                      onDateTimeSelect({ price, sessionDuration: price?.sessionDuration, sessionTime: t.startAt });
                    }
                    setTimeout(() => {
                      ctaRef.current?.scrollIntoView({ behavior: 'smooth' });
                    }, 200);
                  }}
                  isSecondary
                  disabled={(!onFinish && !onDateTimeSelect)}
                  className={t.startAt === selectedTime ? s.sessionButton : s.inactiveButton}
                >
                  <span className="body-sm">{`${new Date(t.startAt).toLocaleString('en-US', {
                    hour: 'numeric',
                    hour12: true,
                    minute: 'numeric',
                  })}`}</span>
                  <br />
                </Buttons>
              ))}
            </div>
            <div ref={ctaRef}>
              {onFinish && (
                <>
                  <Spacer size="24px" />
                  <Buttons
                    isFetching={isSubmitting}
                    onClick={handleBookingClick}
                    disabled={!selectedTime}
                    isSecondary
                    className={s.sessionButton}
                  >
                    <span className="body-md-bold">
                      {booking
                        ? (
                          <>{booking.startAt ? 'Reschedule' : 'Schedule'}</>
                        )
                        : (
                          <>Schedule Booking</>
                        )}
                    </span>
                    <br />
                  </Buttons>
                </>
              )}
            </div>
          </>
        )}
    </div>
  );
};

export default CustomerTimeSelector;
