import { ReactElement } from 'react';
import Modal from '@components/Modal';
import WidgetSnippetForm from '@components/WidgetSnippetForm';
import s from './WidgetSnippetModal.module.scss';

type Props = Parameters<typeof WidgetSnippetForm>[0] & {
  onClose: () => void;
};

const WidgetSnippetModal = ({ onClose, creatorSlug, creatorColor, creatorImage }: Props): ReactElement => (
  <Modal
    hideBottomInset

    title={'Add Noodle AI to my website'}
    onClose={onClose}

  >
    <div className={s.aiChatPage}>
      <WidgetSnippetForm creatorSlug={creatorSlug} creatorColor={creatorColor} creatorImage={creatorImage} />
    </div>
  </Modal>
);

export default WidgetSnippetModal;
