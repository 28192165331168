import React, { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import Modal from '@components/Modal';
import { useIsMobile } from '@hooks';
import * as ApiModels from '@typings/api-models';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import XCircle from '@components/Icons/XCircle';
import ArrowCircleLeft from '@components/Icons/ArrowCircleLeft';
import AssignOwners from '@modals/InitiateCaseModal/AssignOwners';
import { getCreatorTeam } from '@tsClient/creators';
import PageContext from '@components/DesignLibrary/PageContext';
import Spacer from '@components/Spacer';
import CollectPaymentData from '@modals/InitiateCaseModal/CollectPaymentData';
import { PaymentPlan } from '@typings/api-models';
import { ThisWorkflowInvoice } from '@/helpers/expressions/filterWorkflowInvoicesByContextExpressions';
import * as expressionHelpers from '@helpers/expressions';
import { useFeatureFlag } from '@providers/FeatureFlags';
import SelectWorkflow from './SelectWorkflow';
import SelectCustomer from './SelectCustomer';
import AddContext from './AddContext';
import Review from './Review';
import CreateInvoice, { InvoiceData } from './CreateInvoice';
import s from './InitiateCaseModal.module.scss';
import { ContextData } from './types';

type Workflow = Parameters<typeof AddContext>[0]['workflow'] &
  Parameters<typeof Review>[0]['workflow'] & {
    contexts: unknown[];
    workflowInvoices: Array<ThisWorkflowInvoice>;
    referenceId: ApiModels.Workflow['referenceId'];
  };

type Customer = Pick<ApiModels.Person, 'id' | 'name' | 'email'>;
type Product = Pick<ApiModels.Product, 'id' | 'title'>;

type Props = {
  associatedUserWorkflowId?: string;
  creatorId: string;
  creatorSlug: string;
  onClose: () => void;
  customer?: Customer;
  products: Array<
    Pick<ApiModels.Product, 'id' | 'title' | 'isActive'> & {
      productTypes: Array<Pick<ApiModels.ProductType, 'noodleProductType'>>;
    }
  > | null;
};

type PAGES = 'SelectWorkflow' | 'SelectCustomer' | 'AssignOwners' | 'AddContext' | 'CreateInvoice' | 'CollectPaymentMethod' | 'Review';

const InitiateCaseModal: FC<Props> = ({ associatedUserWorkflowId, creatorId, creatorSlug, onClose, customer: initialCustomer, products }) => {
  const isMobile = useIsMobile();

  const modalBodyRef = useRef<HTMLDivElement>(null);

  const [currentPage, setCurrentPage] = useState<PAGES>('SelectWorkflow');
  const [workflow, setWorkflow] = useState<Workflow | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(initialCustomer || null);
  const [isCreatingNewCustomer, setIsCreatingNewCustomer] = useState(false);
  const [contextData, setContextData] = useState<ContextData[] | null>(null);
  const [product, setProduct] = useState<Product | null>(null);
  const [invoiceTemplateIndex, setInvoiceTemplateIndex] = useState(0);
  const [invoiceData, setInvoiceData] = useState<(InvoiceData)[]>([]);
  const [ownerIds, setOwnerIds] = useState<string[]>([]);

  const shouldCollectPrePayment = useFeatureFlag('invoice-pre-payment');

  const {
    data: workflows,
    getData: getWorkflows,
    fetchingState: { isFetching: isFetchingWorkflows },
  } = useNoodleApi(tsClient.workflows.getCreatorWorkflowsSimple);

  const { getData: getTeamMembersFn, data: teamMembers } = useNoodleApi(getCreatorTeam);

  useEffect(() => {
    getTeamMembersFn({ creatorId, page: 1, perPage: 1000 });
  }, [getTeamMembersFn, creatorId]);

  useEffect(() => {
    modalBodyRef.current?.scrollTo?.(0, 0);
  }, [currentPage, invoiceTemplateIndex]);

  useEffect(() => {
    getWorkflows({ creatorId, isActive: true, page: 1, perPage: 1000 });
  }, [creatorId, getWorkflows]);

  useEffect(() => {
    if (initialCustomer) {
      setCustomer(initialCustomer);
    }
  }, [initialCustomer]);

  useEffect(() => {
    if (currentPage === 'SelectWorkflow') {
      setWorkflow(null);
      setCustomer(initialCustomer || null);
      setContextData(null);
      setProduct(null);
      setInvoiceData([]);
      setOwnerIds([]);
    }
  }, [currentPage]);

  const handleWorkflowSelect = (workflowId: string): void => {
    const selectedWorkflow = workflows?.items?.find?.(w => w.id === workflowId);
    if (selectedWorkflow) {
      const selectedProduct = products?.find(p => p.id === selectedWorkflow.referenceId);
      if (selectedProduct) {
        setProduct(selectedProduct);
      }
      setWorkflow(selectedWorkflow ?? null);
      if (!initialCustomer) {
        setCurrentPage('SelectCustomer');
      } else if ((selectedWorkflow?.contexts?.length || 0) > 0) {
        setCurrentPage('AddContext');
      } else if ((selectedWorkflow?.workflowInvoices?.length || 0) > 0) {
        setCurrentPage('CreateInvoice');
      } else {
        setCurrentPage('AssignOwners');
      }
      setInvoiceData(
        selectedWorkflow.workflowInvoices.map(workflowInvoice => ({
          ...workflowInvoice.invoiceTemplate,
          expressionId: workflowInvoice.expression?.id,
          id: nanoid(),
          paymentMethodId: null,
          templateId: workflowInvoice.invoiceTemplate.id,
        })),
      );
    }
  };

  const handleOwnerSelect = (newOwners: string[]): void => {
    setOwnerIds(newOwners);
    setCurrentPage('Review');
  };

  const handleCustomerSelect = (newCustomer: Pick<ApiModels.Person, 'id' | 'name' | 'email'>): void => {
    setCustomer(newCustomer);
    if ((workflow?.contexts?.length || 0) > 0) {
      setCurrentPage('AddContext');
    } else if ((workflow?.workflowInvoices?.length || 0) > 0) {
      setCurrentPage('CreateInvoice');
    } else {
      setCurrentPage('AssignOwners');
    }
  };

  const handleAddContext = (newContextData: ContextData[]): void => {
    setContextData(newContextData);
    if (workflow?.workflowInvoices && (workflow?.workflowInvoices?.length || 0) > 0) {
      const filteredInvoices = expressionHelpers.filterWorkflowInvoicesByContextExpressions({
        contextData: newContextData,
        workflowInvoices: workflow.workflowInvoices,
      });
      setInvoiceData(
        filteredInvoices.map(filteredInvoice => ({
          ...filteredInvoice.invoiceTemplate,
          expressionId: filteredInvoice.expression?.id,
          id: nanoid(),
          paymentMethodId: null,
          templateId: filteredInvoice.invoiceTemplate.id,
        })),
      );
      setCurrentPage('CreateInvoice');
    } else {
      setCurrentPage('AssignOwners');
    }
  };

  const handleAddInvoiceTemplate = ({ data, index }: { data: InvoiceData; index: number }): void => {
    setInvoiceData(prev => prev.map((d, idx) => (idx === index ? data : d)));
    if (data.isPartialPaymentEnabled && (data.isCreditCardPaymentEnabled || data.isDebitCardPaymentEnabled) && shouldCollectPrePayment) {
      setCurrentPage('CollectPaymentMethod');
    } else if (index === invoiceData.length - 1) {
      setCurrentPage('AssignOwners');
    } else {
      setInvoiceTemplateIndex(prev => prev + 1);
    }
  };

  const handleAddInvoicePaymentData = ({
    amount,
    createPaymentPlan,
    paymentMethodId,
    index,
    nextPaymentDate,
    paymentPlanFrequency,
  }: {
    amount: number;
    paymentMethodId: string | null;
    index: number;
    paymentPlanFrequency?: PaymentPlan['frequency'];
    nextPaymentDate?: string | null;
    createPaymentPlan?: boolean;
  }): void => {
    setInvoiceData(prev => prev.map((d, idx) => (idx === index
      ? {
        ...d,
        createPaymentPlan,
        initialPaymentAmount: amount,
        nextPaymentDate,
        paymentMethodId,
        paymentPlanFrequency,
      }
      : d)));
    if (index === invoiceData.length - 1) {
      setCurrentPage('AssignOwners');
    } else {
      setInvoiceTemplateIndex(prev => prev + 1);
      setCurrentPage('CreateInvoice');
    }
  };

  const handleClose = (): void => {
    if (currentPage === 'SelectWorkflow') {
      onClose();
    } else if (currentPage === 'SelectCustomer') {
      setCurrentPage('SelectWorkflow');
    } else if (currentPage === 'AddContext') {
      if (!initialCustomer) {
        setCurrentPage('SelectCustomer');
      } else {
        setCurrentPage('SelectWorkflow');
      }
    } else if (currentPage === 'CreateInvoice') {
      if (invoiceTemplateIndex === 0) {
        if (contextData) {
          setCurrentPage('AddContext');
        } else if (!initialCustomer) {
          setCurrentPage('SelectCustomer');
        } else {
          setCurrentPage('SelectWorkflow');
        }
      } else {
        setInvoiceTemplateIndex(prev => prev - 1);
      }
    } else if (currentPage === 'CollectPaymentMethod') {
      setCurrentPage('CreateInvoice');
    } else if (currentPage === 'AssignOwners') {
      if (contextData) {
        setCurrentPage('AddContext');
      } else if (!initialCustomer) {
        setCurrentPage('SelectCustomer');
      } else {
        setCurrentPage('SelectWorkflow');
      }
    } else if (currentPage === 'Review') {
      setCurrentPage('AssignOwners');
    }
  };

  const getTitle = (): string => {
    if (currentPage === 'SelectCustomer') {
      return isCreatingNewCustomer ? 'Create new contact' : 'Who is your main point of contact?';
    }
    if (currentPage === 'AddContext') {
      return 'Add Context to this Case';
    }
    if (currentPage === 'Review') {
      return 'Review';
    }
    if (currentPage === 'AssignOwners') {
      return 'Assign team members';
    }
    if (currentPage === 'CollectPaymentMethod') {
      return 'Collect Invoice Payment Information';
    }
    return 'Initiate Case';
  };

  const getIcon = (): ReactElement => {
    if (currentPage === 'SelectWorkflow') {
      return <XCircle weight="fill" size={24} color="var(--color-gray-100)" />;
    }
    return <ArrowCircleLeft weight="fill" size={24} color="var(--color-gray-100)" />;
  };

  const fullProducts = products
    ?.map(p => ({
      ...p,
      workflows:
        workflows?.items?.filter(
          w => w.referenceId === p.id && w.steps.find(step => step.eventName === ApiModels.WORKFLOW_TRIGGERS.INITIATE_CTA),
        ) || [],
    }))
    ?.filter(p => p.workflows.length > 0 && p.isActive) || [];

  return (
    <Modal
      containerStyle={isMobile ? {} : { width: '80%' }}
      title={getTitle()}
      closeIcon={getIcon()}
      onClose={handleClose}
      ref={modalBodyRef}
      shouldCloseOnOutsideClick={false}
    >
      <div className={s.container}>
        {currentPage !== 'SelectWorkflow' && workflow && (
          <>
            <Spacer />
            <PageContext
              items={[
                {
                  id: ownerIds.join(',') || undefined,
                  label: 'Team Members',
                  value: ownerIds.map(ownerId => teamMembers?.items?.find(member => member.id === ownerId)?.name || ownerId).join(', '),
                },
                ...(Array.isArray(contextData) ? contextData : [])
                  .map(contextItem => {
                    const { context, data } = contextItem;
                    let value: string | undefined;
                    if (context.type === 'additional-info' && 'value' in data) {
                      value = data.value;
                    } else if (context.type === 'person' && 'name' in data) {
                      value = data.name;
                    } else if (context.type === 'selection' && 'slug' in data) {
                      value = data.slug;
                    }
                    return value
                      ? {
                        id: context.id,
                        label: context.label,
                        value,
                      }
                      : null;
                  }),
                {
                  id: customer?.id,
                  label: 'Contact',
                  value: customer?.name,
                },
                {
                  id: workflow.id,
                  label: 'Workflow',
                  value: workflow.name,
                },
                {
                  id: workflow.referenceId,
                  label: 'Service',
                  value: fullProducts.find(p => p.id === workflow.referenceId)?.title,
                },
              ]
                .filter((item): item is { id: string; label: string; value: string } => Boolean(item?.id) && item?.value !== undefined)}
            />
          </>
        )}
        {currentPage === 'SelectWorkflow' && (
          <SelectWorkflow isFetching={isFetchingWorkflows || !products} fullProducts={fullProducts} onWorkflowSelect={handleWorkflowSelect} />
        )}
        {currentPage === 'SelectCustomer' && (
          <SelectCustomer
            selectedCustomer={customer}
            setIsCreatingNewCustomer={setIsCreatingNewCustomer}
            creatorSlug={creatorSlug}
            onCustomerSelect={handleCustomerSelect}
          />
        )}
        {currentPage === 'AddContext' && customer && workflow && (
          <AddContext
            customer={customer}
            workflow={workflow}
            onSubmit={handleAddContext}
            initialContext={contextData}
            associatedUserWorkflowId={associatedUserWorkflowId}
          />
        )}
        {currentPage === 'CreateInvoice' && workflow && (
          <CreateInvoice invoiceData={invoiceData} invoiceTemplateIndex={invoiceTemplateIndex} onSubmit={handleAddInvoiceTemplate} />
        )}
        {currentPage === 'CollectPaymentMethod' && workflow && customer && (
          <CollectPaymentData
            customer={customer}
            invoiceTemplate={invoiceData[invoiceTemplateIndex]}
            invoiceTemplateIndex={invoiceTemplateIndex}
            onSubmit={handleAddInvoicePaymentData}
          />
        )}
        {currentPage === 'AssignOwners' && <AssignOwners ownerIds={ownerIds} onOwnerSelect={handleOwnerSelect} teamMembers={teamMembers?.items} />}
        {currentPage === 'Review' && customer && workflow && product && (
          <Review
            customer={customer}
            workflow={workflow}
            context={contextData || []}
            product={product}
            invoiceData={invoiceData}
            ownerIds={ownerIds}
            onClose={onClose}
            teamMembers={teamMembers?.items}
            associatedUserWorkflowId={associatedUserWorkflowId}
          />
        )}
      </div>
    </Modal>
  );
};

export default InitiateCaseModal;
