const TIMEZONE_TO_SHORT: Record<string, string> = {
  'Africa/Cairo': 'EET',
  'America/Anchorage': 'ASKT',
  'America/Chicago': 'CST',
  'America/Denver': 'MST',
  'America/Los_Angeles': 'PST',
  'America/New_York': 'EST',
  'Asia/Jakarta': 'WIB',
  'Asia/Tokyo': 'JST',
  'Austrailia/Brisbane': 'AEST',
  'Australia/Melbourne': 'AEDT',
  'Europe/Brussels': 'CET',
  'Europe/London': 'GMT',
  'Europe/Moscow': 'MSK',
  'Pacific/Honolulu': 'HST',
};

export default TIMEZONE_TO_SHORT;